<template>
    <component
        :is="tag"
        :class="{ block: !isNotDone }"
        @click="goToSource"
    >
        <span class="w-100 cursor-pointer">{{ value.name }}</span>

        <div
            v-if="isNotDone && !isError"
            class="w-100 block"
        >
            <ProgressBar
                show
                :percent="value.progress"
            />

            <!-- <button class="btn py-0"><i class="fd-close text-danger"></i></button> -->
            <!-- TODO: show canceled and open page on click -->
        </div>
        <i
            v-else-if="isError"
            v-b-tooltip.hover="error"
            class="btn py-0 fd-warning text-danger"
        ></i>
        <i
            v-else
            class="btn py-0 fd-check text-primary"
        ></i>
    </component>
</template>

<script>
import ProgressBar from '@components/ProgressBar/ProgressBar.vue';

export default {
    name: 'FileItem',

    components: {
        ProgressBar,
    },

    props: {
        value: {
            type: Object,
            required: true,
            validator(value) {
                // TODO:
                return !!value;
            },
        },
        tag: {
            type: String,
            default: 'div',
        },
    },

    computed: {
        isNotDone() {
            return this.value.progress < 100;
        },
        isError() {
            return !!this.value.error;
        },
        error() {
            return this.value.error;
        },
    },

    methods: {
        goToSource() {
            if (this.value.link) {
                this.$router.push(this.value.link);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
