import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=fc25e466&scoped=true"
import script from "./Loader.vue?vue&type=script&lang=js"
export * from "./Loader.vue?vue&type=script&lang=js"
import style0 from "./Loader.vue?vue&type=style&index=0&id=fc25e466&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc25e466",
  null
  
)

export default component.exports