import { defineStore } from 'pinia';

interface LoaderState {
    countActiveLoaders: number;
}

export const useLoaderStore = defineStore({
    id: 'loader',
    state: (): LoaderState => ({
        countActiveLoaders: 0,
    }),
    getters: {
        isLoading: (state) => state.countActiveLoaders > 0,
    },

    actions: {
        setLoading(isLoading: boolean) {
            if (isLoading) {
                this.countActiveLoaders >= 0 ? this.countActiveLoaders++ : (this.countActiveLoaders = 1);
            } else {
                this.countActiveLoaders > 0 ? this.countActiveLoaders-- : (this.countActiveLoaders = 0);
            }
        },
    },
});

export default useLoaderStore;
