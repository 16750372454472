<template>
    <div class="offset-md-3 col-md-6 mt-5">
        <ChangePassword />

        <div class="row border-bottom my-4"></div>

        <ChangeName />

        <div class="row border-bottom my-4"></div>

        <ChangeLanguage />
    </div>
</template>

<script>
import ChangeLanguage from './ChangeLanguage';
import ChangeName from './ChangeName';
import ChangePassword from './ChangePassword';

export default {
    components: {
        ChangePassword,
        ChangeName,
        ChangeLanguage,
    },

    data() {
        return {};
    },
};
</script>
