const PlanSetup = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/PlanSetup');
const TitlePage = () => import('@views/Plan/TitlePage');
const Plan = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan');
const GeneralInfo = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/GeneralInfo');
const WorkGroups = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/WorkGroups');
const CustomPage = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/CustomPage');
const Location = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/Location');
const Drawings = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/Drawings');
const ProductionProcesses = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/ProductionProcesses/index');
const RawMaterials = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/RawMaterials');
const TechnologicalSchema = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/TechnologicalSchema');
const ReadyToEatFood = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/ReadyToEatFood');
const ProductionCapacity = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/ProductionCapacity');
const Analyses = () => import(/* webpackChunkName: "plan-module" */ '@views/Plan/Analyses');
const HazardsSummaryList = () => import('@views/Plan/HazardsSummaryList');
const HazardMatrix = () => import('@views/Plan/HazardMatrix');
const HazardAnalysesOverview = () => import('@views/Plan/HazardOverviewTables/HazardAnalysesOverview');
const CCPTableView = () => import('@views/Plan/HazardOverviewTables/CCPTableView');
const FspProducts = () => import('@views/Plan/FspProducts');

export default [
    {
        path: '',
        name: 'plan',
        component: Plan,
        children: [
            {
                name: 'plan.setup',
                path: 'setup',
                component: PlanSetup,
            },
            {
                name: 'plan.title-page',
                path: 'title-page',
                component: TitlePage,
            },
            {
                name: 'plan.general-info',
                path: 'general-info',
                component: GeneralInfo,
            },
            {
                name: 'plan.location',
                path: 'location',
                component: Location,
                children: [
                    {
                        path: 'create',
                        name: 'plan.location.create',
                        component: () => import('./panels/CreateLocation.vue'),
                    },
                ],
            },
            {
                name: 'plan.plans',
                path: 'plans',
                component: Drawings,
                children: [
                    {
                        path: 'create',
                        name: 'plan.plans.create',
                        component: () => import('./panels/CreateDrawings.vue'),
                    },
                ],
            },
            {
                name: 'plan.work-groups',
                path: 'work-groups',
                component: WorkGroups,
            },
            {
                name: 'plan.production-processes',
                path: 'production-processes',
                component: ProductionProcesses,
                children: [
                    {
                        path: ':id',
                        name: 'plan.production-processes.details',
                        component: () => import('./panels/ProductionProcessesDetails.vue'),
                    },
                    {
                        path: 'new',
                        name: 'plan.production-processes.new',
                        component: () => import('./panels/ProductionProcessesDetails.vue'),
                    },
                    {
                        path: ':id/instruction/:instructionId',
                        name: 'plan.production-processes.instructions.details',
                        component: () => import('./panels/ProductionProcessesSafetyInstructions.vue'),
                    },
                    {
                        path: ':id/instruction/new',
                        name: 'plan.production-processes.instructions.new',
                        component: () => import('./panels/ProductionProcessesSafetyInstructions.vue'),
                    },
                ],
            },
            {
                name: 'plan.raw-materials',
                path: 'raw-materials',
                component: RawMaterials,
            },
            {
                name: 'plan.technological-schema',
                path: 'technological-schema',
                component: TechnologicalSchema,
                children: [
                    {
                        path: 'create',
                        name: 'plan.technological-schema.create',
                        component: () => import('./panels/CreateTechnologicalSchema.vue'),
                    },
                    {
                        path: 'edit',
                        name: 'plan.technological-schema.edit',
                        component: () => import('./panels/EditTechnologicalSchema.vue'),
                    },
                ],
            },
            {
                name: 'plan.ready-to-eat-food',
                path: 'ready-to-eat-food',
                component: ReadyToEatFood,
            },
            {
                name: 'plan.production-capacity',
                path: 'production-capacity',
                component: ProductionCapacity,
            },
            {
                name: 'plan.analyses',
                path: 'analyses',
                component: Analyses,
            },
            {
                name: 'plan.hazards-summary-list',
                path: 'hazard-list',
                component: HazardsSummaryList,
            },
            {
                name: 'plan.hazard-matrix',
                path: 'hazard-matrix',
                component: HazardMatrix,
            },
            {
                name: 'plan.hazard-analyses-overview',
                path: 'hazard-analyses-overview',
                component: HazardAnalysesOverview,
            },
            {
                name: 'plan.ccp-list',
                path: 'ccp-list',
                component: CCPTableView,
            },
            {
                name: 'plan.fsp-products',
                path: 'products/:fspProductGroupId',
                component: FspProducts,
            },
            {
                name: 'plan.custom',
                path: ':custom',
                component: CustomPage,
                children: [
                    {
                        path: 'details',
                        name: 'plan.custom.process.details',
                        component: () => import('./panels/ProductionProcessesDetails.vue'),
                    },
                    {
                        path: 'instructions/edit/:instructionId',
                        name: 'plan.custom.process.details.instructions.edit',
                        component: () => import('./panels/ProductionProcessesSafetyInstructions.vue'),
                    },
                    {
                        path: 'instructions/new',
                        name: 'plan.custom.process.details.instructions.new',
                        component: () => import('./panels/ProductionProcessesSafetyInstructions.vue'),
                    },
                ],
            },
        ],
    },
];
