import CompanyCreationView from '@views/SetUp/Company/CompanyCreationView';
import CompanyInformation from '@views/SetUp/Company/CompanyInformation';

export default {
    path: 'companies',
    component: () => import(/* webpackChunkName: "locations-module" */ './Index.vue'),
    name: 'companies',
    redirect: {
        name: 'companies.current',
    },
    children: [
        {
            path: 'new',
            components: { base: CompanyCreationView },
            meta: {
                hideNav: false,
                isCreateNew: true,
            },
            name: 'companies.new',
        },
        {
            path: '',
            components: { createSettings: CompanyInformation },
            name: 'companies.current',
        },
    ],
};
