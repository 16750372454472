<template>
    <BaseModal
        ref="modal"
        :size="step === endStep ? 'md' : 'lg'"
        modal-class="baseModal review-changes-modal"
        footer-class="modalFooter"
        hide-header
        centered
        no-close
    >
        <template #default>
            <div v-if="step === 1">
                <div class="row space-related-out-b">
                    <div class="col-12 col-md-6">
                        <img
                            src="/assets/img/no_specialist.png"
                            alt="No specialist"
                        />
                    </div>
                    <div class="col-12 col-md-6 d-flex">
                        <div class="my-auto text-center text-md-left">
                            <div class="fs-2 font-weight-500 space-related-out-b">
                                {{ $t('Please review updates in standard content') }}
                            </div>
                            <div
                                v-html="
                                    $t(
                                        'We have made improvements to our system that affect your created HACCP plan. Please review the planned changes.'
                                    )
                                "
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="row space-related-out-t">
                    <div class="col text-center text-md-right">
                        <button
                            class="btn btn-outline-primary space-related-out-r"
                            @click="hide"
                        >
                            {{ $t('Skip for today') }}
                        </button>
                        <button
                            class="btn btn-primary"
                            @click="nextStep"
                        >
                            {{ $t('next') }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-else-if="step === endStep">
                <div class="space-related-out-b font-size-lg font-weight-500">{{ $t('All changes reviewed') }}</div>
                <div class="space-related-out-y">{{ $t('Thank you for your attention!') }}</div>
                <button
                    class="btn btn-primary"
                    @click="hide"
                >
                    {{ $t('close') }}
                </button>
            </div>
            <div
                v-else
                class="text-left"
            >
                <div class="space-related-out-b font-size-lg font-weight-500">{{ $t(current.title) }}</div>
                <div class="row">
                    <div class="col-6 right d-flex align-items-center justify-content-center">
                        <BaseRadio
                            id="2"
                            v-model="chooseDecisionType"
                            name="chose-version"
                            :val="REJECTED"
                            :label="$t('Keep current version')"
                            inline
                        />
                    </div>
                    <div class="col-6 left d-flex">
                        <BaseRadio
                            id="1"
                            v-model="chooseDecisionType"
                            :label="$t('Accept changes')"
                            inline
                            name="chose-version"
                            :val="APPROVED"
                        />
                    </div>
                </div>
                <div
                    v-for="(proposal, index) in currentKeys"
                    :key="proposal"
                    class="row"
                    :class="{ 'font-weight-500': proposal === 'hazard' }"
                >
                    <div class="col-6 right space-related-in-t">
                        <template v-if="isNew">
                            <div
                                v-if="index === 0"
                                class="font-weight-400"
                            >
                                {{ $t('This section is newly added') }}
                            </div>
                        </template>
                        <span
                            v-for="(section, sectionIndex) in current[proposal].current"
                            v-else
                            :key="sectionIndex"
                        >
                            <span v-if="sectionIndex === 0 && proposal !== 'hazard'">{{ $t(proposal) }}</span>
                            <span v-html="section"></span>
                        </span>
                    </div>
                    <div class="col-6 left space-related-in-t">
                        <span
                            v-for="(section, sectionIndex) in current[proposal].new"
                            :key="sectionIndex"
                        >
                            <span v-if="sectionIndex === 0 && proposal !== 'hazard'">{{ $t(proposal) }}</span>
                            <span v-html="section"></span>
                        </span>
                    </div>
                </div>
                <div class="space-related-out-t d-flex justify-content-between">
                    <button
                        class="btn btn-outline-primary space-related-out-r"
                        @click="previousStep"
                    >
                        {{ $t('Previous') }}
                    </button>
                    <button
                        class="btn btn-primary"
                        @click="nextStep"
                    >
                        {{ $t('next') }}
                    </button>
                </div>
            </div>

            <slot></slot>
        </template>

        <template #modal-footer>
            <span> </span>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@components/BaseModal';
import BaseRadio from '@components/BaseRadio';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';

const haccpKeys = ['hazard', 'do', 'check', 'act'];

export default {
    name: 'ReviewChangeProposalsModal',

    components: { BaseModal, BaseRadio },

    data() {
        return {
            APPROVED: 1,
            REJECTED: 2,
            step: 1,
            chooseDecisionType: 1,
            decisions: [],
            proposals: [],
        };
    },

    computed: {
        ...mapGetters(['selectedPlaceId']),
        current() {
            const removeFirstParagraph = (el) => {
                if (
                    el[0] === '<' &&
                    el[1] === 'p' &&
                    el[2] === '>' &&
                    el[-4] === '<' &&
                    el[-3] === '/' &&
                    el[-2] === 'p' &&
                    el[-1] === '>'
                ) {
                    return el.slice(3, el.length - 4);
                }

                return el;
            };

            const proposals = cloneDeep(this.proposals[this.step - 2]);
            if (proposals) {
                haccpKeys.forEach((key) => {
                    if (proposals[key]) {
                        proposals[key] = {
                            proposal_id: proposals[key][0]?.proposal_id,
                            current: proposals[key]?.map((el) => removeFirstParagraph(el.current)),
                            new: proposals?.[key]?.map((el) => removeFirstParagraph(el.new)),
                        };
                    }
                });
            }
            return proposals;
        },
        currentKeys() {
            const availableKeys = Object.keys(this.current);
            return haccpKeys.filter((key) => availableKeys.includes(key));
        },
        currentProposalIds() {
            const keys = Object.keys(this.current).filter((key) => this.current[key]?.proposal_id);
            return keys.map((key) => this.current[key].proposal_id);
        },
        shown() {
            return this.$refs.modal.shown;
        },
        isNew() {
            return this.current?.is_new;
        },
        endStep() {
            return this.proposals.length + 2;
        },
    },

    watch: {
        current() {
            this.chooseDecisionType = 1;
        },
    },

    methods: {
        previousStep() {
            this.decisions.pop();
            this.step--;
        },
        nextStep() {
            if (this.step !== 1) {
                this.decisions.push([
                    ...this.currentProposalIds.map((id) => ({
                        proposal_id: id,
                        change_decision_type_id: this.chooseDecisionType,
                    })),
                ]);
            }

            // Send proposals to API
            if (this.step === this.endStep - 1) {
                window.sessionStorage.setItem(`${this.selectedPlaceId}-has-checked-for-proposals`, true);
                this.$store.dispatch('plan/custom-content/sendProposals', {
                    decisions: this.decisions.reduce((acc, current) => acc.concat(current), []),
                });
            }

            this.step++;
        },
        hide() {
            window.sessionStorage.setItem(`${this.selectedPlaceId}-has-checked-for-proposals`, true);
            this.$refs.modal.hide();
        },
        show(data) {
            this.step = 1;
            this.chooseDecisionType = this.APPROVED;
            this.proposals = data;
            this.$refs.modal.show();
        },
    },
};
</script>

<style lang="scss">
.review-changes-modal .modal-content {
    border-radius: 16px;

    footer {
        display: none;
    }
}
</style>

<style lang="scss" scoped>
.left {
    border-left: 1px solid $gray-400;
}

.right {
    border-right: 1px solid $gray-400;
}
</style>
