<template>
    <div>
        <InfoBox id="setup.place.intro_text">
            <span v-html="$t('setup.place.intro_text')"></span>
        </InfoBox>

        <PlaceForm
            :value="selectedPlace"
            @submit="update"
        />
    </div>
</template>

<script>
import InfoBox from '@components/InfoBox/InfoBox';
import { LOG_ERROR } from '@store/modules/common/constants';
import { ADD_PLACE_INFO } from '@store/modules/company/place/constants';
import { mapGetters } from 'vuex';

import PlaceForm from './PlaceForm';

export default {
    components: {
        InfoBox,
        PlaceForm,
    },

    computed: {
        ...mapGetters(['selectedPlaceId', 'selectedPlace']),
    },

    methods: {
        async update({ values }) {
            const data = {
                location_type: values.locationType?.id,
                placeData: {
                    name: values.name,
                    address: values.address,
                    contact_person: values.representativePerson,
                    person_email: values.email,
                    notification_email: values.notificationEmail,
                    person_phone: values.phone,
                    country_id: values.country?.id,
                    state_id: values.state?.id,
                    preferred_language: values.preferredLanguage?.key,
                    business_activity_id: values.businessActivity?.id,
                    location_type: values.locationType?.id,
                    number_of_employees: values.numberOfEmployees,
                    timezone: values.timezone,
                },
            };
            this.$store.dispatch('plan/getProductGroups', {
                businessActivityId: values.businessActivity?.id,
                placeId: this.selectedPlaceId,
                refresh: true,
            });

            try {
                await this.$store.dispatch(ADD_PLACE_INFO, { placeId: this.selectedPlaceId, data });
            } catch (e) {
                this.$store.dispatch(LOG_ERROR, `Place edit ADD_PLACE_INFO error: ${e}`);
            }
        },
    },
};
</script>
