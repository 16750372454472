import { cloneDeep } from 'lodash';
import Vue from 'vue';

import routes from '@routes';

import http from '@http';
import companyService from '@services/company.service';
import companyTokenService from '@services/companyToken.service';

import { FETCH_COUNTRIES } from '@store/modules/common/constants';
import { INIT_USER_PROFILE } from '@store/modules/profile/constants';
import { CustomUnit } from '@store/traceability/types';

import {
    ADD_COMPANY_TOKEN,
    ADD_NEW_COMPANY,
    CREATE_COMPANY,
    DELETE_COMPANY,
    DELETE_COMPANY_TOKEN,
    DELETE_PLACE,
    FETCH_COMPANIES,
    FETCH_COMPANIES_HARD,
    FETCH_COMPANY_TOKENS,
    INIT_COMPANIES,
    PUSH_NEW_PLACE,
    RESTORE_COMPANY,
    RESTORE_COMPANY_AND_PLACE,
    SET_COMPANIES,
    SET_COMPANY_TOKENS,
    SET_SELECTED_COMPANY,
    SET_SELECTED_COMPANY_PERIODS,
    SET_SELECTED_COMPANY_RECURRING_PAYMENTS,
    START_COMPANY_CREATION,
    START_INIT_COMPANIES,
    STORE_COMPANY_AND_PLACE,
    UPDATE_COMPANY,
} from './constants';
import place from './place';
import { SET_PLACES, SET_SELECTED_PLACE } from './place/constants';

const modules = {
    place,
};

const state = {
    isCompaniesInited: true,

    companies: {},
    selected_company_id: +localStorage.getItem('selected_company_id') || null,
    selected_place_id: +localStorage.getItem('selected_place_id') || null,

    cache: {
        selectedCompanyId: null,
    },
    prevCompanyId: null,
    prevPlaceId: null,
    selectedCompanyPeriods: [],
};

const mutations = {
    [SET_SELECTED_COMPANY_RECURRING_PAYMENTS](state, value) {
        state.companies[state.selected_company_id].company.recurring_payments_enabled = value;
        if (!value) {
            state.companies[state.selected_company_id].company.card_number_suffix = null;
        }
    },
    [SET_SELECTED_COMPANY_PERIODS](state, value) {
        state.selectedCompanyPeriods = value;
    },
    [START_INIT_COMPANIES](state) {
        state.isCompaniesInited = false;
    },
    [INIT_COMPANIES](state) {
        state.isCompaniesInited = true;
    },
    [SET_COMPANIES](state, companies) {
        state.companies = {};

        companies.forEach((item) => {
            Vue.set(state.companies, item.company.id, item);
        });
    },
    [SET_SELECTED_COMPANY](state, companyId) {
        if (Number.isFinite(companyId)) {
            state.cache.selectedCompanyId = null;
        } else {
            state.cache.selectedCompanyId = state.selected_company_id;
        }

        state.selected_company_id = Number.isFinite(companyId) ? companyId : null;

        if (state.selected_company_id) {
            localStorage.setItem('selected_company_id', state.selected_company_id);
        } else {
            localStorage.removeItem('selected_company_id');
        }
    },
    [RESTORE_COMPANY](state) {
        state.selected_company_id = state.cache.selectedCompanyId;
        state.cache.selectedCompanyId = null;
    },
    [ADD_NEW_COMPANY](state, company) {
        const data = {
            company: company,
            places: [],
            role: 'owner',
        };
        Vue.set(state.companies, company.id, data);
    },
    [UPDATE_COMPANY](state, company) {
        state.companies[company.id].company = company;
    },
    [PUSH_NEW_PLACE](state, { companyId, place }) {
        if (place && state.companies[companyId]) {
            state.companies[companyId].places.push(place);
        }
    },
    [DELETE_PLACE](state, { companyId, placeId }) {
        Vue.delete(state.companies[companyId], placeId);
    },
    [SET_COMPANY_TOKENS](state, { companyId, tokens }) {
        if (tokens && state.companies[companyId]) {
            Vue.set(state.companies[companyId], 'tokens', tokens);
        }
    },
    [ADD_COMPANY_TOKEN](state, { companyId, token }) {
        if (token && state.companies[companyId]) {
            state.companies[companyId].tokens.push(token);
        }
    },
    [DELETE_COMPANY_TOKEN](state, { companyId, tokenId }) {
        if (tokenId && state.companies[companyId]) {
            const index = state.companies[companyId].tokens.findIndex((obj) => obj.id === tokenId);
            Vue.delete(state.companies[companyId].tokens, index);
        }
    },
    [STORE_COMPANY_AND_PLACE](state, { companyId, placeId }) {
        state.prevCompanyId = companyId;
        state.prevPlaceId = placeId;
    },
};

const actions = {
    [SET_SELECTED_COMPANY_PERIODS]({ getters, commit }) {
        if (getters.selectedCompanyId) {
            http.get(`/api/company/${getters.selectedCompanyId}/subscription/periods`).then(({ data }) => {
                commit(SET_SELECTED_COMPANY_PERIODS, data);
            });
        }
    },
    async [INIT_COMPANIES]({ commit, dispatch, getters }) {
        commit(START_INIT_COMPANIES);
        await dispatch(FETCH_COUNTRIES);
        return dispatch(FETCH_COMPANIES)
            .then(
                (res) => {
                    // Prevent wrong company id in localStorage
                    if (getters.selectedCompany) {
                        dispatch(SET_PLACES, getters.selectedCompany.places);
                    } else {
                        if (res.length) {
                            dispatch(SET_SELECTED_COMPANY, res[0].company.id);
                        }
                    }

                    return res;
                },
                (err) => {
                    return err;
                }
            )
            .finally(() => commit(INIT_COMPANIES));
    },

    [DELETE_COMPANY]({ dispatch, state }, companyId) {
        if (state?.companies[companyId]) {
            return companyService.delete(companyId).then(() => {
                Vue.delete(state.companies, companyId);
                if (Object.keys(state.companies).length) {
                    const firstCompanyId = state.companies[Object.keys(state.companies)[0]].company.id;
                    dispatch(SET_SELECTED_COMPANY, firstCompanyId);
                } else {
                    state.selected_company_id = null;
                    state.cache.selectedCompanyId = null;
                    dispatch(SET_SELECTED_PLACE, null);
                    localStorage.setItem('selected_company_id', null);
                    routes.push({
                        name: 'create.company',
                        params: {
                            isCreateNew: false,
                        },
                    });
                }
            });
        }
    },

    [FETCH_COMPANIES_HARD]({ commit }) {
        return companyService.getCompanies(true).then(
            (res) => {
                commit(SET_COMPANIES, res);

                return res;
            },
            (err) => {
                return err;
            }
        );
    },

    [FETCH_COMPANIES]({ commit, getters }) {
        if (getters.companies.length) {
            return getters.companies;
        }

        return companyService.getCompanies().then(
            (res) => {
                commit(SET_COMPANIES, res);

                return res;
            },
            (err) => {
                return err;
            }
        );
    },

    async [SET_SELECTED_COMPANY]({ commit, dispatch, getters }, companyId) {
        commit(SET_SELECTED_COMPANY, companyId);

        const { places } = getters.selectedCompany;

        if (!getters['shared-access/isSharedAccess']) {
            dispatch(INIT_USER_PROFILE, getters.selectedCompany);
        }

        return dispatch(SET_SELECTED_PLACE, null).then(() => {
            return dispatch(SET_PLACES, places);
        });
    },

    [CREATE_COMPANY]({ commit }, { data }) {
        return companyService.postCompany(data).then((company) => {
            commit(ADD_NEW_COMPANY, company);

            return company;
        });
    },

    [UPDATE_COMPANY]({ commit }, { companyId, data }) {
        return companyService.updateCompany(companyId, data).then((res) => {
            commit(UPDATE_COMPANY, res.data);
            return res;
        });
    },

    [START_COMPANY_CREATION]({ commit, getters }) {
        if (!getters.isCompanyCreationInProgres && !getters.isPlaceCreationInProgress) {
            commit(STORE_COMPANY_AND_PLACE, { companyId: getters.selectedCompanyId, placeId: getters.selectedPlaceId });
            commit(SET_SELECTED_COMPANY, null);
            commit(SET_SELECTED_PLACE, null);
        }
    },
    [ADD_COMPANY_TOKEN]({ commit, getters }, data) {
        return companyTokenService.postCompanyToken(getters.selectedCompanyId, data).then((res) => {
            commit(ADD_COMPANY_TOKEN, {
                companyId: getters.selectedCompanyId,
                token: res.data,
            });
            return res;
        });
    },
    [FETCH_COMPANY_TOKENS]({ commit, getters }) {
        return companyTokenService.getCompanyToken(getters.selectedCompanyId).then((res) => {
            commit(SET_COMPANY_TOKENS, {
                companyId: getters.selectedCompanyId,
                tokens: res.data,
            });
            return res;
        });
    },
    [DELETE_COMPANY_TOKEN]({ commit, getters }, tokenId) {
        return companyTokenService.deleteCompanyToken(tokenId).then((res) => {
            commit(DELETE_COMPANY_TOKEN, {
                companyId: getters.selectedCompanyId,
                tokenId,
            });
            return res;
        });
    },
    [RESTORE_COMPANY_AND_PLACE]({ dispatch, getters }) {
        dispatch(SET_SELECTED_COMPANY, getters.prevCompanyAndPlace.companyId);
        dispatch(SET_SELECTED_PLACE, getters.prevCompanyAndPlace.placeId);
    },
    getSelectedCompanySubscription({ getters, commit }) {
        if (!getters.selectedCompanyId) {
            return null;
        }
        return http.get(`/api/company/${getters.selectedCompanyId}/subscription`).then(({ data }) => {
            const selectedCompany = cloneDeep(getters.selectedCompany)?.company;
            selectedCompany.subscription = data;
            commit('UPDATE_COMPANY', selectedCompany);
            return data;
        });
    },
};

const getters = {
    isCompaniesInited(state) {
        return state?.isCompaniesInited;
    },
    isCompanyCreationInProgres: (state, getters) => {
        return !getters.isCompanyExists;
    },
    isSignupInProgress() {
        return window.location?.href?.includes('auth');
    },

    companies(state) {
        return Object.keys(state?.companies)
            .map((key) => state.companies[key])
            .filter((company) => company.company.name);
    },

    currentCompanyPlaceUserGroups(state, getters) {
        const currentCompany = state?.companies[state.selected_company_id];
        const currentPlace = currentCompany?.places?.find((place) => place.id === getters.selectedPlaceId);

        return currentPlace?.user_groups;
    },

    companiesPlaces(_, getters) {
        let places = [];
        getters.companies.forEach(
            (company) => (places = [...places, ...company.places.filter((place) => place.name && place.id)])
        );
        return places;
    },
    companiesWithPlaces(_, getters) {
        let places = [];
        getters.companies.forEach((company) =>
            places.push({
                id: company.company.id,
                name: company.company.name,
                places: [...company.places.filter((place) => place.name && place.id)],
            })
        );
        return places;
    },

    selectedCompanyId: (state) => {
        if (state?.selected_company_id !== null && Number.isFinite(state?.selected_company_id)) {
            return state.selected_company_id;
        }

        return null;
    },

    isCompanyExists: (state) => {
        return !!state?.selected_company_id;
    },

    selectedCompany: (state, getters) => {
        if (getters.selectedCompanyId) {
            return state.companies[getters.selectedCompanyId];
        }

        return null;
    },

    companyOnboardingProgress: (state, getters) => {
        if (!getters.selectedCompanyId) {
            return {};
        }

        return state.companies[getters.selectedCompanyId].company?.onboarding_progress?.reduce(
            (result, item) => ({ ...result, ...item }),
            {}
        );
    },

    selectedCompanyDateFormat: (state, getters) => {
        if (!getters['classifiers/dateFormats'].length) {
            return 'DD.MM.yyyy';
        }

        return (
            getters['classifiers/dateFormats']?.find(
                (df) => df.id === getters.selectedCompany?.company?.['date_format_id']
            )?.format || getters['classifiers/dateFormats'][0]
        );
    },
    selectedCompanyDateTimeFormat: (state, getters) => {
        return `${getters.selectedCompanyDateFormat} HH:mm`;
    },
    selectedCompanyWeightSystemBaseUnit: (state, getters) => {
        return getters.selectedCompany?.company?.weight_system?.units?.find(
            (unit) => unit.is_measurement_system_base_unit
        );
    },
    selectedCompanyWeightSystem: (state, getters) => {
        return getters.selectedCompany?.company.weight_system;
    },
    selectedCompanyWeightSystemWithoutPieces: (state, getters) => {
        const system = getters.selectedCompany?.company?.weight_system;
        return {
            ...system,
            units: system.units
                .filter((unit) => unit.name.toLowerCase() !== 'portion')
                .map((unit) => CustomUnit.defaultUnitFromJSON(unit)),
        };
    },
    prevCompanyAndPlace: (state) => {
        return { companyId: state?.prevCompanyId, placeId: state?.prevPlaceId };
    },
    selectedCompanyPeriods: (state) => {
        return state?.selectedCompanyPeriods;
    },
    selectedCompanyTemp: (state, getters) => {
        let temp = null;
        if (getters.selectedCompanyId) {
            const selectedCompany = state.companies[getters.selectedCompanyId];
            if (selectedCompany?.company?.temperature_scale) {
                temp = selectedCompany.company.temperature_scale;
            }
        }
        return temp;
    },
    selectedCompanyTempShort: (state, getters) => {
        return getters.selectedCompanyTemp?.unit || '°C';
    },
    getAllCompanyProductionUnits: (state, getters) => {
        let units = [];
        if (getters.selectedCompanyId) {
            const selectedCompany = state.companies[getters.selectedCompanyId];
            units = units.concat(selectedCompany?.company?.weight_system?.units || []);
            units = units.concat(selectedCompany?.company?.volume_system?.units || []);
        }
        return units;
    },
    getCompanyProductionUnitsWithoutPieces: (state, getters) => {
        const portionIndexes = [3, 12, 17];
        return getters.getAllCompanyProductionUnits.filter((e) => !portionIndexes.includes(e.id));
    },
};

export default {
    modules,
    state,
    mutations,
    actions,
    getters,
};
