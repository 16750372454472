/**
 * MUTATIONS
 */
export const SET_DATA = 'overview/SET_DATA';
export const RESET_OVERVIEW = 'overview/RESET_OVERVIEW';

/**
 * ACTIONS
 */
export const INIT_OVERVIEW = 'overview/INIT_OVERVIEW';
