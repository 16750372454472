import http from '@http';

import { download } from '@services/helper.service.js';
import AppService from '@services/app.service';

export class FilesService {
    static get(id) {
        return http.get(`/api/files/${id}`, { responseType: 'blob' });
    }

    static initFile({ file, onPaper, metadata, payload }) {
        return http.post('/api/files', {
            id: file.id,
            filename: file.file.name,
            on_paper: onPaper ? 1 : 0,
            metadata,
            ...payload,
        });
    }

    static upload(file, onUploadProgress) {
        const data = new FormData();
        data.append('id', file.id);
        data.append('file', file.file);

        AppService.blockTab();
        return http
            .put(`/api/files/${file.id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress,
            })
            .finally((e) => {
                AppService.unblockTab();

                return e;
            });
    }

    static delete(id) {
        return http.delete(`/api/files/${id}`);
    }

    static download(name, id) {
        return http.get(`/api/files/${id}`, { responseType: 'blob' }).then((res) => {
            download(name, res.data, res.data.type);
        });
    }
}
