import { ActionTree, GetterTree, MutationTree } from 'vuex';

import http from '@http';

import { ISharedItem, SharedItem } from './types';

interface SharedAccessState {
    items: any[];
    isSharedAccess: boolean;
    isExpire: boolean;
    token: string;
}

function state(): SharedAccessState {
    return {
        items: [],
        isSharedAccess: false,
        isExpire: false,
        token: '',
    };
}

const getters: GetterTree<SharedAccessState, any> = {
    items(state) {
        return state?.items ?? [];
    },
    isSharedAccess(state) {
        return state?.isSharedAccess ?? false;
    },
    isExpire(state) {
        return state?.isExpire ?? false;
    },
    token: (state) => state?.token ?? '',
};

const mutations: MutationTree<SharedAccessState> = {
    setSharedAccessState(state, token: string) {
        state.isSharedAccess = !!token;
        state.token = token;
    },
    setItems(state, payload: ISharedItem[]) {
        state.items = payload;
    },
    addItem(state, payload: ISharedItem) {
        state.items.push(payload);
    },
    setItem(state, payload: ISharedItem) {
        const index = state.items.findIndex((i) => i.token === payload.token);

        state.items[index] = payload;
    },
    deleteItem(state, token: string) {
        state.items = state.items.filter((i) => i.token !== token);
    },
    expired(state) {
        state.isExpire = true;
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
        http.setDefaultStrategy();
    },
};

const actions: ActionTree<SharedAccessState, any> = {
    onPlaceChange: {
        root: true,
        handler({ commit, rootGetters }) {
            if (!rootGetters['shared-access/isSharedAccess']) {
                commit('reset');
            }
        },
    },
    async getAll({ commit, getters, rootGetters }) {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/shared-accesses`);
            commit('setItems', data.map(SharedItem.fromJSON));

            return getters.items;
        } catch (e) {
            console.error(e);
        }
    },
    async get({ getters, rootGetters }, token): Promise<SharedItem | null> {
        try {
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/shared-accesses/${token || getters.token}`
            );

            return SharedItem.fromJSON(data);
        } catch (e) {
            console.error(e);

            return null;
        }
    },
    async create({ commit, rootGetters }, payload: SharedItem) {
        try {
            const { data } = await http.post(
                `/api/places/${rootGetters.selectedPlaceId}/shared-accesses`,
                payload.toJSON()
            );

            commit('addItem', SharedItem.fromJSON(data[0]));
            return data;
        } catch (e) {
            console.error(e);
        }
    },
    async cancelAccess({ commit, rootGetters }, token: string) {
        try {
            await http.delete(`/api/places/${rootGetters.selectedPlaceId}/shared-accesses/${token}`);

            commit('deleteItem', token);
        } catch (e) {
            console.error(e);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
