import http from '@http';

class UserManagementService {
    deletePermission(userId, placeId, companyId) {
        const form = {};
        if (placeId) {
            form.place_id = placeId;
        } else {
            form.company_id = companyId;
        }

        return http.delete(`/api/permission/${userId}`, { data: form }).then((res) => res.data);
    }

    getPermissions() {
        return http.get('/api/permissions').then((res) => res.data);
    }

    togglePermission(permission, email, username, placeId, companyId, sendEmail) {
        const arr = username?.split(' ') || [];
        let first_name = '';
        let last_name = '';
        if (arr.length > 1) {
            first_name = arr.slice(0, arr.length - 1).join(' ');
            last_name = arr[arr.length - 1];
        } else {
            first_name = arr.join(' ');
        }

        const form = {
            first_name,
            last_name,
            role: permission,
            email: email,
            sendEmail: sendEmail,
        };
        if (companyId) {
            form.company_id = companyId;
        } else {
            form.place_id = placeId;
        }
        return http.post('/api/invitation', form);
    }

    checkToken(token) {
        return http.get(`/api/account/check_valid_token?password_token=${token}`).then((res) => {
            if (res.status === 200) {
                return res.data;
            }
            return false;
        });
    }
}

export default new UserManagementService();
