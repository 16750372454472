<template>
    <div class="skeleton-table">
        <div class="skeleton-row w-100"><div class="skeleton-cell w-100"></div></div>
        <div
            v-for="row in rows"
            :key="row"
            class="skeleton-row"
        >
            <div
                v-for="col in columns"
                :key="col"
                class="skeleton-cell"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonTable',

    props: {
        rows: {
            type: Number,
            default: 5,
        },
        columns: {
            type: Number,
            default: 5,
        },
    },
};
</script>

<style scoped>
.skeleton-table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: 20px 0;
}

.skeleton-row {
    display: flex;
    gap: 10px;
}

.skeleton-cell {
    flex: 1;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f5f5f5;
    }
    100% {
        background-color: #e0e0e0;
    }
}
</style>
