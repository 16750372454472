<template>
    <div class="bg-light h-100">
        <Header
            logo-only
            class="position-absolute"
        />

        <div
            class="container d-flex align-items-center justify-content-center flex-column flex-grow-1 pb-5 text-center"
        >
            <h2 class="mb-3 font-weight-normal">{{ $t('Thank you for signing up') }}!</h2>

            <h1 class="mb-5">{{ $t('We are setting up your dashboard') }}</h1>

            <img
                src="https://translations.fooddocs.ee/app/fooddocs_setting_up.webp"
                alt="FoodDocs setting up"
            />
        </div>
    </div>
</template>

<script>
import Header from '@views/Auth/views/Register/components/Header';

export default {
    components: {
        Header,
    },
};
</script>

<style lang="scss" scoped>
img {
    max-width: 90%;
    @include media-breakpoint-up(sm) {
        max-width: 60%;
    }
}
</style>
