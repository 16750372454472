export default class MobileAppService {
    static emitTokenCallback() {
        if (window.MobileAuthCallback && window.MobileAuthCallback.postMessage) {
            const token = localStorage.getItem('access_token');

            window.MobileAuthCallback.postMessage(token);
        }

        // TODO deprecation, remove after new mobile version is released
        if (window.MobileJSCallbackHandler && window.MobileJSCallbackHandler.postMessage) {
            const token = localStorage.getItem('access_token');

            window.MobileJSCallbackHandler.postMessage(JSON.stringify({ access_token: token || null }));
        }
    }

    static toToRouteWithTocken(route) {
        if (window.MobileRedirectCallback && window.MobileRedirectCallback.postMessage) {
            window.MobileRedirectCallback.postMessage(route);
        }

        // TODO deprecation, remove after new mobile version is released
        if (window.MobileJSCallbackHandler && window.MobileJSCallbackHandler.postMessage) {
            const token = localStorage.getItem('access_token');

            window.MobileJSCallbackHandler.postMessage(
                JSON.stringify({
                    access_token: token || null,
                    route: route || null,
                })
            );
        }
    }

    static get isMobileView() {
        return !!(window.MobileAuthCallback && window.MobileAuthCallback.postMessage);
    }
}
