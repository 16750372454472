import { storageService } from '@services/storage.service.js';
import { INIT_STORAGES, SET_DATA } from '@store/modules/storage/constants';

const state = {
    data: [],
};

const mutations = {
    [SET_DATA](state, data) {
        state.data = data;
    },
};

const actions = {
    async [INIT_STORAGES]({ commit, getters }) {
        if (getters.storagesList.length) {
            return getters.storagesList;
        } else {
            return await storageService.getAll().then((res) => {
                commit(SET_DATA, res);

                return res;
            });
        }
    },
};

const getters = {
    storagesList(state) {
        return state.data.length ? state.data : [];
    },
    getStorageById: (state, getters) => (id) => getters.storagesList.find((i) => i.id === id),
};

export default {
    state,
    mutations,
    actions,
    getters,
};
