import store from '@store';

const FREE_TRIAL_ID = 1;
const TIMES = 10;

export default async (to, from, next) => {
    if (from.fullPath === '/' && Object.keys(to.query).includes('paymentSuccess')) {
        for (let i = 0; i < TIMES; i++) {
            const company = await store.dispatch('getSelectedCompanySubscription');

            if (company.price_package_id != null && company.price_package_id !== FREE_TRIAL_ID) {
                break;
            }
        }
    }

    const subscription = store.getters.selectedCompany?.company?.subscription;

    // it just sends empty ?paymentSuccess; otherwise is undefined
    if (
        subscription?.stripe_subscription_id ||
        subscription?.last_paid_amt ||
        to.query.paymentSuccess === null ||
        (to.params.fromPlan && !subscription.trial_days_left) ||
        to.params.fromBilling
    ) {
        next();
    } else {
        next({ name: 'billing.choose-payment' });
    }
};
