const Documents = () => import(/* webpackChunkName: "documents-module" */ '@views/Documents');
const DocumentView = () => import(/* webpackChunkName: "documents-module" */ '@views/Documents/DocumentsView');
const SharedAccesses = () => import(/* webpackChunkName: "documents-module" */ '@views/Documents/SharedAccesses');
const DocumentSharing = () =>
    import(/* webpackChunkName: "documents-module" */ '@views/Documents/panels/DocumentSharing');
const ManageFolders = () => import(/* webpackChunkName: "documents-module" */ '@views/Documents/ManageFolders');

export default [
    {
        path: '/documents',
        name: 'documents',
        redirect: { name: 'share-accesses' },
        component: Documents,
        children: [
            {
                path: 'manage-folders',
                name: 'manage-folders',
                component: ManageFolders,
            },
            {
                name: 'docs.custom',
                path: ':folder',
                component: DocumentView,
                children: [
                    {
                        path: ':subfolder',
                        name: 'docs.custom.sub',
                        component: DocumentView,
                    },
                ],
            },
            {
                path: 'share-accesses',
                name: 'share-accesses',
                component: SharedAccesses,
                children: [
                    {
                        path: 'new',
                        name: 'share-accesses-new',
                        component: DocumentSharing,
                    },
                    {
                        path: ':token',
                        name: 'share-accesses-item',
                        component: DocumentSharing,
                    },
                ],
            },
        ],
    },
];
