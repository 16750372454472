<template>
    <div class="d-inline-flex">
        <slot
            name="button"
            :click="show"
        ></slot>

        <BaseModal
            ref="modal"
            modal-class="baseModal"
            hide-footer
            hide-header
            :order="order"
            centered
        >
            <template #default>
                <slot
                    name="modal"
                    :close="hide"
                    :submit="submit"
                >
                    <div>
                        <div class="row space-related-in-b">
                            <div class="col text-center">
                                <h1>{{ $t('Confirm delete?') }}</h1>
                                <div class="mt-2 space-related-out-t">{{ $t('This action cannot be undone') }}</div>
                            </div>
                        </div>

                        <div class="space-related-out-t d-flex align-items-center justify-content-center">
                            <button
                                class="btn btn-outline-primary mx-1"
                                @click="hide"
                            >
                                {{ $t('Cancel') }}
                            </button>
                            <button
                                class="btn btn-primary mx-1"
                                @click="submit"
                            >
                                {{ $t('delete') }}
                            </button>
                        </div>
                    </div>
                </slot>
            </template>
        </BaseModal>
    </div>
</template>

<script>
export default {
    name: 'DoubleConfirmationButton',

    props: {
        noConfirm: Boolean,
        order: {
            type: Number,
            default: 1,
        },
    },

    emits: ['submit'],

    methods: {
        submit() {
            this.$emit('submit');
            this.hide();
        },
        hide() {
            this.$refs.modal.hide();
        },
        show() {
            if (this.noConfirm) {
                this.confirmDiscardChanges();
            } else {
                this.$refs.modal.show();
            }
        },
    },
};
</script>
