import { v1 as uuidv1 } from 'uuid';

import { FilesService } from '@services/files-v2.service';

function state() {
    return {
        filesUploading: {},
    };
}

const mutations = {
    set(state, payload) {
        state.filesUploading[payload.id] = payload;
    },
    updateProgress(state, payload) {
        if (!state.filesUploading[payload.id]) {
            state.filesUploading[payload.id] = {
                id: payload.id,
                name: payload.name,
                progress: payload.progress,
                link: payload.link,
                error: null,
                done: false,
            };
        }

        if (payload.error) {
            state.filesUploading[payload.id].error = payload.error;
        } else if (payload.done) {
            state.filesUploading[payload.id].done = payload.done;
            state.filesUploading[payload.id].progress = payload.progress;
        } else {
            state.filesUploading[payload.id].progress = payload.progress;
        }
    },
    clearItem(state, id) {
        delete state.filesUploading[id];
    },
    clear(state) {
        Object.keys(state.filesUploading).forEach((key) => {
            if (state.filesUploading[key] && state.filesUploading[key].done) {
                delete state.filesUploading[key];
            }
        });
    },
};

const actions = {
    upload({ commit }, { files, location, onPaper, metadata, payload = null }) {
        if (!files?.length) {
            return [];
        }

        const toUpload = Array.prototype.map.call(files, (file) => ({
            id: uuidv1(),
            file,
        }));

        try {
            return Promise.all(
                toUpload.map((file) => {
                    return FilesService.initFile({ file, metadata, onPaper, payload }).then(async () => {
                        await FilesService.upload(file, (e) => {
                            const progress = 10 + parseInt((e.loaded * 90) / e.total);

                            commit('updateProgress', {
                                id: file.id,
                                name: file.file?.name,
                                progress,
                                link: location,
                            });

                            if (progress === 100) {
                                commit('updateProgress', {
                                    id: file.id,
                                    name: file.file?.name,
                                    progress,
                                    link: location,
                                    done: true,
                                });
                            }
                        })
                            .then(() => {
                                commit('updateProgress', {
                                    id: file.id,
                                    name: file.file?.name,
                                    progress: 100,
                                    link: location,
                                    done: true,
                                });

                                setTimeout(() => {
                                    commit('clearItem', file.id);
                                }, 500);
                            })
                            .catch((e) => {
                                commit('updateProgress', {
                                    id: file.id,
                                    name: file.file?.name,
                                    error: e?.message,
                                    link: location,
                                    done: true,
                                });
                            });

                        return file;
                    });
                })
            );
        } catch (e) {
            console.error(e);
            throw e;
        }
    },
};

const getters = {
    filesUploading: (state) => Object.keys(state.filesUploading).map((key) => state.filesUploading[key]),
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
