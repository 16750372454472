import { vm } from '@main';
import Vue from 'vue';

import routes from '@routes';

import http from '@http';
import companyService from '@services/company.service';

import { DateValue } from '@common/types';

import {
    DELETE_PLACE as COMPANY_DELETE_PLACE,
    PUSH_NEW_PLACE as PUSH_NEW_PLACE_TO_COMPANY,
    STORE_COMPANY_AND_PLACE,
} from '@store/modules/company/constants';
import { INIT_PERMISSIONS } from '@store/modules/permission/constants';

import { ROLES } from '@common/constants';
import placeService from '@common/services/place.service';

import { RESET_AUDITS } from '@views/Audits/store/constants';

import {
    ADD_PLACE_INFO,
    CREATE_PLACE,
    DELETE_PLACE,
    REFRESH_LAST_VISIT,
    RENAME_VTA_DOC,
    RESTORE_PLACE,
    SET_PLACES,
    SET_SELECTED_PLACE,
    START_PLACE_CREATION,
    UPDATE_PLACE,
    UPDATE_PLACE_RELATED_DATA,
} from './constants';

const state = {
    isPlaceLoaded: false,
    selected_place_id: +localStorage.getItem('selected_place_id') || null,
    places: {},
    files: {
        placeId: null,
        // HACCP plan
        flow_diagram: [],
        location_plan: [],
        water_and_sewage_plan: [],
        premises_plan: [],
        equipment_plan: [],
        moving_routes: [],
        waste_tare_pest_control_plan: [],
        // Audit
        audit: [],
    },
    // folders: [],
    cache: {
        selectedPlaceId: null,
    },
    placeHasNoMobileEntries: false,
};

const mutations = {
    setPlaceLoadedState(state, bool) {
        state.isPlaceLoaded = bool;
    },
    placeHasNoMobileEntries(state, bool) {
        state.placeHasNoMobileEntries = bool;
    },
    [RENAME_VTA_DOC]: (state, data) => {
        const files = state.places?.[state.selected_place_id]?.files?.[data.type];
        if (files) {
            const index = files.findIndex((f) => f.id === data.id);
            if (index !== -1) {
                files[index].filename = data.filename;
            }
        }
    },
    [SET_PLACES](state, data) {
        state.places = {};

        data.forEach((item) => {
            Vue.set(state.places, item.id, item);
        });
    },
    [SET_SELECTED_PLACE](state, placeId) {
        if (Number.isFinite(placeId)) {
            state.cache.selectedPlaceId = null;
        } else {
            state.cache.selectedPlaceId = state.selected_place_id;
        }

        state.selected_place_id = Number.isFinite(placeId) ? placeId : null;
        localStorage.setItem('selected_place_id', state.selected_place_id);
    },

    [CREATE_PLACE](state, data) {
        Vue.set(state.places, data.id, data);
    },

    [UPDATE_PLACE](state, data) {
        state.places[data.id] = data;
    },

    [RESTORE_PLACE](state) {
        state.selected_place_id = state.cache.selectedPlaceId;
        state.cache.selectedPlaceId = null;
    },
    changeFile(state, { type, id, change }) {
        let currentFile = state.files[type].find((t) => t.id === id);
        Object.keys(change).forEach((c) => {
            Vue.set(currentFile, c, change[c]);
        });
    },
    removeFile(state, { type, id }) {
        const files = state.files[type];
        const index = files.findIndex((file) => file.id === id);
        if (index !== -1) {
            files.splice(index, 1);
        }
    },
    // todo FD-7854 seems to only be used in old monitoring
    addFile(state, { type, fileMeta, onPaper, metadata }) {
        if (!state.files[type]) {
            Vue.set(state.files, type, []);
        }

        state.files[type].push({
            id: fileMeta.id,
            name: fileMeta.file.name,
            on_paper: onPaper ? 1 : 0,
            metadata,
            updated_at: new DateValue(),
        });
    },
    updatePlaceOnboardingStatus(state, data) {
        Vue.set(state.places[getters.selectedPlaceId], 'onboarding_progress', { ...data });
    },
    updateFiles(state, { type, data }) {
        Vue.set(state.files, 'placeId', state.selected_place_id);
        Vue.set(state.files, type, data);
    },
    updateFolders(state, { data }) {
        state.folders = data;
    },
};

const actions = {
    hasMobileEntries({ getters, commit }) {
        if (!getters?.selectedPlaceId) {
            return false;
        }
        return http.get(`/api/places/${getters.selectedPlaceId}/activity-log/has-mobile-entries`).then(({ data }) => {
            commit('placeHasNoMobileEntries', !data);
        });
    },
    changeFile({ commit }, { type, id, change }) {
        http.put(`/api/files/${id}`, change).then(() => {
            commit('changeFile', { type, id, change });
        });
    },
    removeFileFromPlace({ commit, dispatch }, { type, id }) {
        return http.delete(`/api/files/${id}`).then(() => {
            commit('removeFile', { type, id });
            dispatch('plan/getSelectedPlanStatuses');
        });
    },
    async attachFileToPlace({ getters, commit, dispatch }, { type, fileMeta, onPaper, metadata }) {
        return http
            .put(`/api/places/${getters.selectedPlaceId}/files/${fileMeta.id}`, null, { params: { type } })
            .then(() => {
                commit('addFile', { type, fileMeta, onPaper, metadata });
                dispatch('plan/getSelectedPlanStatuses');
            });
    },
    async getSelectedPlaceFilesByType({ getters, commit }, type) {
        if (!getters?.selectedPlaceId) {
            return;
        }
        if (typeof type === 'object' && type.length) {
            await Promise.all(
                type.map((t) => {
                    return http
                        .get(`/api/places/${getters.selectedPlaceId}/files`, { params: { type: t } })
                        .then(({ data }) => {
                            commit('updateFiles', { type: t, data });
                        })
                        .catch((error) => {
                            // Need to be checked because tests do not pass vm object
                            if (vm?.$toastr) {
                                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
                            }
                        });
                })
            );
        } else {
            await http
                .get(`/api/places/${getters.selectedPlaceId}/files`, { params: { type } })
                .then(({ data }) => {
                    commit('updateFiles', { type, data });
                })
                .catch((error) => {
                    // Need to be checked because tests do not pass vm object
                    if (vm?.$toastr) {
                        vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
                    }
                });
        }
    },
    async [SET_PLACES]({ commit, dispatch, getters }, places) {
        commit(SET_PLACES, places);

        if (places.length) {
            if (getters.selectedPlaceId) {
                await dispatch(SET_SELECTED_PLACE, getters.selectedPlaceId);
            } else {
                await dispatch(SET_SELECTED_PLACE, places[0].id);
            }
        } else {
            await dispatch(START_PLACE_CREATION);
        }
    },

    [DELETE_PLACE]({ dispatch, getters, state, commit }, placeId) {
        if (state.places[placeId]) {
            return placeService.delete(placeId).then(() => {
                Vue.delete(state.places, placeId);
                commit(COMPANY_DELETE_PLACE, { companyId: getters.selectedCompanyId, placeId });
                if (Object.keys(state.places).length) {
                    const firstPlaceId = state.places[Object.keys(state.places)[0]].id;
                    dispatch(SET_SELECTED_PLACE, firstPlaceId);
                } else {
                    state.selected_place_id = null;
                    state.cache.selectedPlaceId = null;
                    localStorage.setItem('selected_place_id', null);
                    routes
                        .push({
                            name: 'create.place',
                            params: {
                                company: getters.selectedCompany ? getters.selectedCompany.company : {},
                            },
                        })
                        .catch();
                }
            });
        }
    },

    async [SET_SELECTED_PLACE]({ commit, dispatch, getters }, placeId) {
        commit('setPlaceLoadedState', false);
        const isSamePlace = getters.selectedPlaceId === placeId;
        commit(SET_SELECTED_PLACE, placeId);

        if (placeId) {
            dispatch(UPDATE_PLACE_RELATED_DATA, placeId);

            if (!isSamePlace) {
                dispatch('onPlaceChange');
            }

            // Need it for plan module
            dispatch('getSelectedPlaceFilesByType', [
                'flow_diagram',
                'location_plan',
                'water_and_sewage_plan',
                'premises_plan',
                'equipment_plan',
                'moving_routes',
                'waste_tare_pest_control_plan',
            ]);

            try {
                const data = await companyService.getFullPlaceData(placeId);
                commit(UPDATE_PLACE, data);
                commit('setPlaceLoadedState', true);

                return placeId;
            } catch (e) {
                console.error(e);
            }
        }
    },

    [UPDATE_PLACE_RELATED_DATA]({ dispatch }, placeId) {
        dispatch(RESET_AUDITS);
        dispatch(REFRESH_LAST_VISIT, placeId);
    },

    [CREATE_PLACE]({ commit, getters, dispatch }, { companyId, data }) {
        return placeService
            .postPlace(companyId, data)
            .then(async (res) => {
                await dispatch('getSelectedCompanySubscription');
                commit(CREATE_PLACE, res);
                commit(PUSH_NEW_PLACE_TO_COMPANY, { companyId, place: res });
                commit(SET_SELECTED_PLACE, res.id);
                commit('plan/updateSelectedPlanId', res.food_safety_plan.id);

                dispatch(INIT_PERMISSIONS);

                return res;
            })
            .finally(() => {
                if (
                    getters.selectedCompany &&
                    getters.selectedCompany.company &&
                    getters.places &&
                    getters.places.length > 1
                ) {
                    const trialDays = vm.$store.getters['global/isTrialOngoing'];
                    if (!(trialDays || trialDays === 0)) {
                        vm.$toastr.i(vm.$t('Next billing date changed due to adding a new food handling place'));
                    }
                }
            });
    },

    [UPDATE_PLACE]({ dispatch }, { placeId, data, redirect = true, setSeletedPlace = true }) {
        placeService
            .updatePlace(placeId, data)
            .then((res) => {
                if (setSeletedPlace) {
                    dispatch(SET_SELECTED_PLACE, res.id);
                }
                if (redirect) {
                    // TODO: Dummy stuff let's delete it
                    routes.push('/create/place-settings').catch();
                }
            })
            .catch((error) => {
                // Need to be checked because tests do not pass vm object
                if (vm?.$toastr) {
                    vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
                }
            })
            .finally(() => {
                dispatch('plan/getSelectedPlanStatuses');
            });
    },

    [ADD_PLACE_INFO]({ commit }, { placeId, data }) {
        return placeService.updatePlace(placeId, data.placeData).then((res) => {
            commit(UPDATE_PLACE, res);
        });
    },

    [START_PLACE_CREATION]({ commit, getters }) {
        if (!getters.isCompanyCreationInProgres) {
            commit(STORE_COMPANY_AND_PLACE, {
                companyId: getters.selectedCompanyId,
                placeId: getters.selectedPlaceId,
            });
            commit(SET_SELECTED_PLACE, null);
        }
    },

    [REFRESH_LAST_VISIT]({ commit, getters }, placeId) {
        if (!placeId) {
            console.error('Place ID is missing: ', placeId);
            return;
        }
        const role =
            (getters.selectedCompany && getters.selectedCompany.company && getters.selectedCompany.company.role) ||
            null;
        const username = (getters.userProfile && getters.userProfile.username) || null;
        if ((role !== ROLES.MEMBER && role !== ROLES.OWNER) || (username && username.includes('@fooddocs'))) {
            return;
        }

        return placeService.refreshLastVisit(placeId).then((data) => {
            if (data) {
                const place = Object.assign({}, getters.selectedPlace);
                place.last_visit = data.last_visit;
                commit(UPDATE_PLACE, place);
            }
            return data;
        });
    },
};

const getters = {
    isPlaceLoaded: (state) => state.isPlaceLoaded,
    placeHasNoMobileEntries: (state) => {
        return state.placeHasNoMobileEntries;
    },
    isPlaceCreationInProgress: (state, getters) => {
        return !getters.isPlaceExists;
    },
    places: (state) => {
        return Object.keys(state.places)
            .map((key) => state.places[key])
            .filter((place) => place.name);
    },
    placesIds: (state, getters) => {
        return getters.places.map((place) => place.id);
    },

    selectedPlaceId: (state, getters) => {
        const isPlaceIdExists = state.selected_place_id !== null && Number.isFinite(state.selected_place_id);

        if (getters.selectedCompanyId && isPlaceIdExists) {
            return state.selected_place_id;
        }

        return null;
    },

    isPlaceExists: (state) => {
        return !!state.selected_place_id;
    },

    confirmedBy(state, getters) {
        return getters.selectedPlace?.plan_confirmed_by;
    },
    confirmedAt(state, getters) {
        if (getters.selectedPlace?.plan_confirmed_at) {
            return DateValue.fromRequestDateTime(getters.selectedPlace?.plan_confirmed_at).dateFormat;
        }

        return null;
    },
    selectedPlace: (state, getters) => {
        if (getters.selectedPlaceId) {
            return state.places[getters.selectedPlaceId] || {};
        }

        return {};
    },
    placeOnboardingProgress: (state, getters) => {
        if (!getters.selectedPlaceId) {
            return {};
        }
        return state.places[getters.selectedPlaceId].onboarding_progress?.reduce(
            (result, item) => ({ ...result, ...item }),
            {}
        );
    },
    isProductBasedPlan(state, getters) {
        return getters.selectedPlace.use_product_based_plan;
    },

    placeCountryId: (state, getters) => {
        return getters.selectedPlace && getters.selectedPlace.country_id;
    },

    isNextChangeWillAffectPricing(state, getters) {
        const company = getters.permissions?.find((company) => getters.selectedCompanyId === company.id);
        const place = company?.places?.find((place) => getters.selectedPlaceId === place.id);
        const amount = place?.users?.filter((user) => user.role !== 'readonly')?.length;

        return amount === 1;
    },
    isAbleToCreatePlace(state, getters) {
        const company = getters.permissions?.find((company) => getters.selectedCompanyId === company.id);
        const place = company?.places?.find((place) => getters.selectedPlaceId === place.id);
        const amount = place?.users?.filter((user) => user.role !== 'readonly')?.length || 0;

        const isPaymentDay =
            getters.selectedCompany?.company?.next_payment_date &&
            DateValue.fromRequestDateTime(getters.selectedCompany.company.next_payment_date).isBefore(new DateValue());

        return amount < 2 || !isPaymentDay;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
