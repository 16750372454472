import { Frequency } from '@common/types/Frequency';

export class TaskSubtype {
    public id?: number;
    public name: string;
    public orderNumber: number;
    public isDefault: boolean;
    public frequencies: Frequency[];

    constructor(data?: any) {
        this.id = data?.id;
        this.name = data.name;
        this.orderNumber = data.order_number;
        this.isDefault = data.is_default;
        this.frequencies = data.frequencies;
    }

    static fromJSON(json: any) {
        return new TaskSubtype({
            id: json.id,
            name: json.name,
            orderNumber: json.order_number,
            isDefault: json.is_default,
            frequencies: json.frequencies.map(Frequency.fromJSON),
        });
    }

    toJSON() {
        const json = {
            name: this.name,
            order_number: this.orderNumber,
            is_default: this.isDefault,
            frequencies: this.frequencies?.map((f) => f.toJSON()),
        };

        this.id ? (json['id'] = this.id) : null;

        return json;
    }
}
