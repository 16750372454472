<template>
    <BaseBadge
        :value="$t('new')"
        class="new-badge"
    />
</template>

<script>
import BaseBadge from '@components/BaseBadge';

export default {
    name: 'BadgeNew',

    components: {
        BaseBadge,
    },
};
</script>

<style lang="scss" scoped>
.new-badge {
    padding: 0.2em 0.8em;
    background-color: $light-green;
}
</style>
