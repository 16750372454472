import http from '@http';

import { Worker } from '@common/types/Worker';

function state() {
    return {
        filterQuery: '',
        items: {},
        allCompanyRoles: {},
    };
}

const getters = {
    filterQuery: (state) => state.filterQuery,
    getRole: (state) => (id) => state.items[id],
    roles: (state) => Object.keys(state.items).map((id) => state.items[id]),
    allCompanyRoles: (state) => state.allCompanyRoles,
};

const mutations = {
    setAllCompanyRolesPlace(state, payload) {
        state.allCompanyRoles[payload.place.id] = {
            items: payload.items,
            place: payload.place,
        };
    },
    add(state, payload) {
        state.items[payload.id] = payload;
    },
    set(state, payload) {
        state.items[payload.id] = payload;
    },
    delete(state, id) {
        delete state.items[id];
    },
    setFilterQuery(state, query) {
        state.filterQuery = query;
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
    setWorkers(state, { id, workers }) {
        state.items[id].workers = workers;
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getAllCompanyRoles({ commit, rootGetters, getters }) {
        try {
            let requests = [];
            rootGetters.selectedCompany.places.forEach((place) => {
                requests.push(
                    http.get(`/api/user-groups/${place.id}`).then(({ data }) => {
                        commit('setAllCompanyRolesPlace', { place: place, items: data.map((role) => fromJSON(role)) });
                    })
                );
            });
            await Promise.all(requests);

            return getters.allCompanyRoles;
        } catch (error) {
            console.error(error);
        }
    },
    async getAll({ commit, rootGetters, getters }) {
        try {
            if (!getters.roles.length) {
                const response = await http.get(`/api/user-groups/${rootGetters.selectedPlaceId}`);
                commit('reset');
                response.data.forEach((role) => {
                    commit('add', fromJSON(role));
                });
            }

            return getters.roles;
        } catch (error) {
            console.error(error);
        }
    },
    async getItem({ commit }, id) {
        try {
            const response = await http.get(`/api/user-groups/${id}/workers`);
            commit('setWorkers', { id, workers: response.data?.map((worker) => Worker.fromJSON(worker)) });
        } catch (error) {
            console.error(error);
        }
    },
    async create({ commit, rootGetters }, payload) {
        const response = await http.post(`/api/user-groups/${rootGetters.selectedPlaceId}/store`, toJSON(payload));
        commit('set', fromJSON(response.data));
        return response;
    },
    async update({ commit }, payload) {
        const response = await http.put(`/api/user-groups/${payload.id}/update`, toJSON(payload));
        commit('set', fromJSON(response.data));
        return response;
    },
    async delete({ commit }, id) {
        try {
            await http.delete(`/api/user-groups/${id}/delete`);
            commit('delete', id);
        } catch (error) {
            console.error(error);
        }
    },
    async toggleActivation({ commit, getters }, id) {
        try {
            await http.put(`/api/user-groups/${id}/toggle-state`);
            const role = getters.getRole(id);
            commit('set', {
                ...role,
                isDeactivated: !role.isDeactivated,
            });
        } catch (error) {
            console.error(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

function fromJSON(json) {
    return {
        id: json.id,
        name: json.name,
        email: json.email,
        placeId: json.place_id,
        personsAmount: json.workers_count || 0,
        isDefault: !!json.is_default,
        isDeactivated: !!json.is_disabled,
        workers: json.workers?.map((worker) => worker.id) || [],
    };
}

function toJSON(data) {
    return {
        id: data.id,
        name: data.name,
        is_disabled: data.isDeactivated,
        email: data.email,
    };
}
