import http from '@http';

class UserGroupService {
    groups(placeId) {
        return http.get(`/api/user-groups/${placeId}`).then((res) => res.data);
    }

    store(data) {
        return http.post(`/api/user-groups/${data.placeId}/store`, data).then((res) => res.data);
    }

    update(groupId, data) {
        return http.put(`/api/user-groups/${groupId}/update`, data).then((res) => res.data);
    }

    delete(groupId) {
        return http.delete(`/api/user-groups/${groupId}/delete`).then((res) => res.data);
    }

    toggleState(groupId) {
        return http.put(`/api/user-groups/${groupId}/toggle-state`).then((res) => res.data);
    }
}

export default new UserGroupService();
