// Mutations
export const UPDATE_PLACE_FORM = 'UPDATE_PLACE_FORM';
export const RESET_PLACE_FORM = 'RESET_PLACE_FORM';
export const RESET_COMPANY_FORM = 'RESET_COMPANY_FORM';
export const SET_USERNAME_EXISTS = 'SET_USERNAME_EXISTS';
export const SET_VALID_COUPON = 'SET_VALID_COUPON';

export const SET_SESSION_ID = 'register/SET_SESSION_ID';

// Actions
export const MAKE_COMPANY_AND_PLACE = 'MAKE_COMPANY_AND_PLACE';
export const MAKE_COMPANY = 'MAKE_COMPANY';
export const MAKE_PLACE = 'MAKE_PLACE';
export const GENERATE_SESSION_ID = 'register/GENERATE_SESSION_ID';

export const FORM_STATES = {
    about: 'about',
    company: 'company',
    verification: 'verification',
};
export const FORM_STATES_ORDER = [FORM_STATES.about, FORM_STATES.company, FORM_STATES.verification];
