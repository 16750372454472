<template>
    <BaseModal
        ref="priceModal"
        size="md"
        modal-class="baseModal"
        footer-class="modalFooter"
        hide-header
        centered
        @submit="submit"
        @cancel="cancel"
    >
        <template #default>
            <h1>{{ $t('affect_pricing_title') }}</h1>
            <div>
                <span
                    >{{ $t('Adding a business unit will increase your') }}
                    {{
                        quantityChange.recurring_interval === 'year'
                            ? $t('yearly payment by')
                            : $t('monthly payment by')
                    }}
                    {{ quantityChange.recurring_amount_change }}
                    <span class="text-uppercase">{{ quantityChange.currency }}</span> {{ $t('VAT') }}.
                </span>
                <strong
                    >{{ $t('Please note you will be charged') }} {{ quantityChange.amount_charged_now }}
                    {{ $t(quantityChange.currency).toUpperCase() }}
                    {{ $t('VAT today for the period up to your next billing date') }}.</strong
                >
            </div>
        </template>

        <template #modal-footer="{ cancel, submit }">
            <span>
                <div class="text-center d-flex align-items-center justify-content-center">
                    <button
                        class="btn btn-outline-primary"
                        @click="cancel"
                    >
                        {{ $t('Cancel') }}
                    </button>
                    <button
                        class="btn btn-primary"
                        @click="submit"
                    >
                        {{ $t('create') }}
                    </button>
                </div>
            </span>
        </template>
    </BaseModal>
</template>

<script>
import { mapActions } from 'pinia';
import { mapGetters } from 'vuex';

import { useLoaderStore } from '@store/pinia-utils/loader';

import BaseModal from '@components/BaseModal';

export default {
    name: 'AffectPricingNotificationModal',

    components: {
        BaseModal,
    },

    emits: ['confirm', 'cancel'],

    computed: {
        ...mapGetters({
            quantityChange: 'billing/quantityChange',
        }),
    },

    methods: {
        ...mapActions(useLoaderStore, ['setLoading']),
        submit() {
            this.$emit('confirm');
            this.$refs.priceModal.hide();
        },
        cancel() {
            this.$emit('cancel');
            this.$refs.priceModal.hide();
        },
        async show() {
            this.setLoading(true);
            await this.$store.dispatch('billing/getQuantityChangePreview', 1);
            this.$refs.priceModal.show();
            this.setLoading(false);
        },
    },
};
</script>
