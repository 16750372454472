var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-md-5 col-md-7 text-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t('changing_password')))])]),_vm._v(" "),_c('BaseForm',{staticClass:"col-12",on:{"submit":_vm.changePassword}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-5 col-form-label text-md-right text-sm-left",attrs:{"for":'oldPassword'}},[_vm._v("\n                "+_vm._s(_vm.$t('old_password')))]),_vm._v(" "),_c('div',{staticClass:"col-md-7"},[_c('BaseInput',{attrs:{"id":"oldPassword","type":"password","rules":"required|min:4"},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n                        "+_vm._s(_vm.$t('Password must be at least 4 characters long'))+"\n                    ")]},proxy:true}]),model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-5 col-form-label text-md-right text-sm-left",attrs:{"for":'newPassword'}},[_vm._v("\n                "+_vm._s(_vm.$t('new_password')))]),_vm._v(" "),_c('div',{staticClass:"col-md-7"},[_c('BaseInput',{attrs:{"id":"newPassword","name":"newPassword","type":"password","rules":{
                        required: true,
                        min: 4,
                        regex: /^[^{}()<>&]{4,40}$/,
                    }},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n                        "+_vm._s(_vm.$t('Password must be at least four letters long and must not contain following characters: {}()<>&'))+"\n                    ")]},proxy:true}]),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newPassword"}})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-5 col-form-label text-md-right text-sm-left",attrs:{"for":'confirmPassword'}},[_vm._v("\n                "+_vm._s(_vm.$t('repeat_new_password')))]),_vm._v(" "),_c('div',{staticClass:"col-md-7"},[_c('BaseInput',{attrs:{"id":"confirmPassword","type":"password","rules":{
                        required: true,
                        min: 4,
                        regex: /^[^{}()<>&]{4,40}$/,
                        confirmed: 'newPassword',
                    }},scopedSlots:_vm._u([{key:"error",fn:function(){return [(_vm.confirmPassword !== _vm.newPassword)?_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t('Passwords did not match'))+"\n                        ")]):_c('div',[_vm._v("\n                            "+_vm._s(_vm.$t('Password must be at least four letters long and must not contain following characters: {}()<>&'))+"\n                        ")])]},proxy:true}]),model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"confirmPassword"}})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"offset-md-5 col-md-7"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v("\n                    "+_vm._s(_vm.$t('save'))+"\n                ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }