import { vm } from '@main';
import cloneDeep from 'lodash/cloneDeep';

import http from '@http';
import { retry } from '@services/helper.service';
import { download } from '@services/helper.service.js';

import { UPDATE_PLACE } from '@store/modules/company/place/constants';

import { preventMultiple } from '@common/services/http/http.service';

import { planMixin } from '@views/Plan/mixins/plan.mixin';

import analyses from './modules/analyses';
import customContent from './modules/custom-content';
import fspProductGroup from './modules/fspProductGroup';
import generalInfo from './modules/general-info';
import hazard from './modules/hazard';
import sections from './modules/sections';
import workGroup from './modules/workGroup';

const NOT_PROCESSED = 39;
const BOUGHT_IN_PRODUCTS = 78;
const RANGE_OPTION_OTHER_ID = 7;

// TODO: Split plan store into submodules

const state = {
    isEditMode: false,
    editModeActive: false,
    sideMenuOpen: false,

    selectedPlanDetails: {},
    planGroups: [],
    sideMenuObj: { placeId: null, sideMenu: [], fspProductGroups: [] },
    productionProcesses: {
        active: [],
        inactive: [],
    },
    selectedProductionProcess: {
        obj: null,
        changes: [],
    },
    productionProcessesObj: {
        placeId: null,
        productionProcesses: [],
    },
    instructions: [],
    productGroupsObj: {
        placeId: null,
        productGroups: [],
    },
    foodGroupCategoriesObj: {
        placeId: null,
        foodGroupCategories: [],
    },
    isPlanChanged: true,
    planLanguages: [],
    hazardList: [],
    hazard_estimations: {},
    hazardDetails: {},
    processDetails: {},
    audits: [],
    taskSubtypes: [],
    technologyDiagrams: [],
    statuses: {},
    technologyDiagramName: {
        old: '',
        new: '',
    },
    showForeverDigitalModal: false,

    foodResaleCapacityRanges: [],
    foodProductionCapacityRanges: [],
    foodCapacityDefaults: [],
    foodCapacityPlaceId: null,
    planState: 0,
    productionProcessProfiles: [],
};

const mutations = {
    updatePlanState(state) {
        state.planState++;
    },
    setFoodResaleCapacityRanges(state, ranges) {
        state.foodResaleCapacityRanges = ranges;
    },
    setFoodProductionCapacityRanges(state, ranges) {
        state.foodProductionCapacityRanges = ranges;
    },
    setFoodCapacityDefaults(state, defaults, placeId) {
        state.foodCapacityDefaults = defaults;
        state.foodCapacityPlaceId = placeId;
    },
    initProductionProcesses(state, { active, inactive }) {
        state.productionProcesses = { active, inactive };
    },
    editProductionProcesses(state, { active, inactive }) {
        if (active) {
            state.productionProcesses.active = active;
        }
        if (inactive) {
            state.productionProcesses.inactive = inactive;
        }
    },
    editActiveProductionProcess(state, process) {
        const index = state.productionProcesses.active.findIndex((p) => p.id === +process.id);
        const currentProcess = state.productionProcesses.active[index];
        currentProcess.name = process.name;

        if (process.isDisabled || process.is_disabled) {
            currentProcess.isDisabled = true;
            currentProcess.selected = false;
            currentProcess.originallySelected = false;
            currentProcess.sub_processes.forEach((sub) => {
                sub.selected = false;
            });
            state.productionProcesses.active.splice(index, 1);
            state.productionProcesses.inactive.push(currentProcess);
        }
    },
    addActiveProductionProcess(state, process) {
        state.productionProcesses.active.push({
            id: process.id,
            name: process.name,
            order: process.order,
            selected: true,
            originallySelected: true,
            food_handling_place_id: process.food_handling_place_id,
            original_production_process_id: process.original_production_process_id,
            fsp_product_group_id: process.fsp_product_group_id,
            sub_processes: process?.sub_processes || [],
        });

        if (process.original_production_process_id) {
            state.productionProcesses.active = state.productionProcesses.active.filter(
                (p) => p.id !== process.original_production_process_id
            );
        }
    },
    addInactiveProductionProcess(state, process) {
        if (process) {
            state.productionProcesses.inactive.push({
                id: process.id,
                name: process.name,
                selected: false,
                originallySelected: false,
                food_handling_place_id: process.food_handling_place_id,
                original_production_process_id: process.original_production_process_id,
                sub_processes: process.sub_processes || [],
            });
        }
    },
    setEditModeActive(state, value) {
        state.editModeActive = value;
    },
    setEditMode(state, value) {
        state.isEditMode = value;
    },
    setSideMenuOpen(state, value) {
        state.sideMenuOpen = value;
    },
    showForeverDigitalModal(state, bool) {
        state.showForeverDigitalModal = bool;
    },
    setStatuses(state, data) {
        state.statuses = data;
    },
    setTechnologyDiagramName(state, data) {
        state.technologyDiagramName = data;
    },
    setTechnologyDiagrams(state, data) {
        state.technologyDiagrams = data;
    },
    setAudits(state, data) {
        state.audits = data;
    },
    setTaskSubtypes(state, data) {
        state.taskSubtypes = data;
    },
    setProcessDetails(state, data) {
        state.processDetails = data;
    },
    appendProcessDetails(state, data) {
        Object.keys(data).forEach((key) => {
            state.processDetails[key] = data[key];
        });
    },
    removeProcessDetailsHazard(state, id) {
        if (state?.processDetails?.hazards) {
            state.processDetails.hazards = state.processDetails.hazards.filter((h) => h.id !== id);
        }
    },
    appendProcessDetailsHazards(state, data) {
        if (state?.processDetails?.hazards) {
            const index = state.processDetails.hazards.findIndex((hazard) => hazard.id === data?.id);
            if (index === -1) {
                state.processDetails.hazards.push(data);
            } else {
                state.processDetails.hazards[index] = data;
            }
        } else {
            state.processDetails = {
                ...state.processDetails,
                hazards: [data],
            };
        }
    },
    updateHazardEstimations(state, data) {
        state.hazard_estimations = data;
    },
    updateHazardDetails(state, data) {
        state.hazardDetails = data;
    },
    resetProductionProcessInstructions(state) {
        state.instructions = [];
    },
    updateProductionProcessInstruction(state, data) {
        const index = state.instructions.findIndex((instruction) => instruction.id === data.id);
        if (index === -1) {
            const originalIndex = state.instructions.findIndex(
                (instruction) => instruction.id === data.original_fsp_section_group_id
            );
            if (originalIndex === -1) {
                state.instructions.push(data);
            } else {
                state.instructions[originalIndex] = data;
            }
        } else {
            state.instructions[index] = data;
        }
    },
    updateSelectedPlanDetails(state, data) {
        state.selectedPlanDetails = {
            general_data_once_filled:
                (state.selectedPlanDetails.general_data_once_filled === undefined && data.general_data_once_filled) ||
                state.selectedPlanDetails.general_data_once_filled,
            ...data.plan,
            sections: data.sections || state.selectedPlanDetails?.sections || [],
        };
    },
    updateSelectedPlanGeneralDataOnceFilled(state, data) {
        state.selectedPlanDetails.general_data_once_filled = data;
    },
    updateSelectedPlanId(state, data) {
        state.selectedPlanDetails.id = data;
    },
    setPlanIds(state, data) {
        state.planLanguages = data;
    },
    setPlanGroups(state, data) {
        state.planGroups = data;
    },
    setProductionProcessInstructions(state, data) {
        state.instructions = data.map((instruction) => cloneDeep(instruction));
    },
    updatePlanGroups(state, planGroups) {
        if (planGroups.length && planGroups[0].id) {
            const index = state.planGroups.findIndex((data) => data.id === planGroups[0].id);
            if (index === -1) {
                state.planGroups.push(planGroups[0]);
            } else {
                state.planGroups[index] = planGroups[0];
            }
        }
    },
    setSideMenu(state, data) {
        state.sideMenuObj = data;
    },
    setProductionProcesses(state, data) {
        state.productionProcessesObj = data;
    },
    setProductGroups(state, data) {
        state.productGroupsObj = data;
    },
    setFoodGroups(state, data) {
        state.foodGroupCategoriesObj = data;
    },
    updateChanged(state, data) {
        state.isPlanChanged = data;
    },
    editCustomProcesses(state, { process, getters, commit }) {
        let processes = cloneDeep(getters.productionProcessesObj);
        const index = processes.productionProcesses.findIndex((data) => data.id === process.id);
        if (index !== -1) {
            processes.productionProcesses[index] = process;
        }
        commit('setProductionProcesses', processes);
    },
    updateProductionProcesses(state, { newProcess, getters, commit }) {
        let processes = cloneDeep(getters.productionProcessesObj);
        processes.productionProcesses.push(newProcess);
        if (newProcess.original_production_process_id) {
            processes.productionProcesses = processes.productionProcesses.filter(
                (pp) => pp.id !== newProcess.original_production_process_id
            );
        }
        commit('setProductionProcesses', processes);
    },
    deleteCustomAnalysis(state, { id, rootGetters }) {
        const placeData = cloneDeep(rootGetters.selectedPlace);
        placeData.custom_analyses = placeData.custom_analyses.filter((ca) => ca.id !== parseInt(id));
        this.commit(UPDATE_PLACE, placeData, { root: true });
    },
    deleteCustomProcess(state, id) {
        state.productionProcesses.active = state.productionProcesses.active.filter((p) => +p.id !== +id);
    },
    toggleProcess(state, process) {
        let currentProcess;
        let selected;
        if (process.originallySelected) {
            currentProcess = state.productionProcesses.active.find((p) => p.id === process.id);
            selected = !state.productionProcesses.active.find((p) => p.id === process.id).selected;
        } else {
            currentProcess = state.productionProcesses.inactive.find((p) => p.id === process.id);
            selected = !state.productionProcesses.inactive.find((p) => p.id === process.id).selected;
        }
        currentProcess.selected = selected;
        if (!selected && currentProcess?.sub_processes?.length) {
            currentProcess.sub_processes = currentProcess.sub_processes.map((sp) => ({ ...sp, selected: false }));
        }
    },
    toggleSubProcess(state, { process, subProcess }) {
        let currentProcess;
        if (process.originallySelected) {
            currentProcess = state.productionProcesses.active.find((p) => p.id === process.id);
        } else {
            currentProcess = state.productionProcesses.inactive.find((p) => p.id === process.id);
        }

        const sub = currentProcess.sub_processes.find((sp) => sp.id === subProcess.id);

        sub.selected = !sub.selected;

        if (sub.selected) {
            currentProcess.selected = true;
        }
    },
    setProductionProcessProfiles(state, payload) {
        state.productionProcessProfiles = payload;
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ dispatch }) {
            state.isEditMode = false;
            dispatch('init');
        },
    },
    async getFoodCapacityDefaults({ commit, getters, rootGetters }) {
        if (state.foodCapacityPlaceId !== rootGetters.selectedPlaceId) {
            const response = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/food-production-capacity-default`
            );
            commit(
                'setFoodCapacityDefaults',
                {
                    businessActivity: response?.data?.business_activity,
                    productionUnit: response?.data?.productionUnit,
                    productionCapacityRange: response?.data?.production_capacity_range,
                    resaleCapacityRange: response?.data?.resale_capacity_range,
                    timeUnit: response?.data?.time_unit,
                },
                rootGetters.selectedPlaceId
            );
        }

        return getters.foodResaleCapacityDefaults;
    },
    async getFoodCapacityRanges({ commit, getters }) {
        if (!getters.foodResaleCapacityRanges.length) {
            const resaleCapacityRanges = await http.get('/api/classifiers/food-resale-capacity-ranges ');
            commit('setFoodResaleCapacityRanges', resaleCapacityRanges.data);
        }

        if (!getters.foodProductionCapacityRanges.length) {
            const productionCapacityRanges = await http.get('/api/classifiers/food-production-capacity-ranges ');
            commit('setFoodProductionCapacityRanges', productionCapacityRanges.data);
        }
    },
    async getProductionProcessProfiles({ commit, rootGetters }) {
        try {
            if (rootGetters['shared-access/isSharedAccess'] || !rootGetters.selectedPlaceId) {
                return false;
            }

            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/onboarding-profiles/production-processes`
            );
            commit('setProductionProcessProfiles', data);
        } catch (error) {
            console.error(error);
        }
    },
    initProductionProcesses({ getters, rootGetters, commit }) {
        const fspProductGroupId = rootGetters['plan/fspProductGroup/selectedGroupId'];

        const routeParams = vm?.$route?.name?.split('.');
        const currentPage = vm.$route?.params?.custom || routeParams?.[routeParams?.length - 1];

        let currentParent = state.sideMenuObj.sideMenu.find((parent) => {
            return parent.pages.find((page) => page.static_name === currentPage);
        });

        if (!currentParent && vm.$route?.params?.custom) {
            // TODO no routing should be done inside store!! bugfix/FD-7314
            vm.$router.push({ name: 'plan.title-page' });
        }

        const processes = getters.productionProcessesObj.productionProcesses;

        let placeProcesses = rootGetters.selectedPlace?.place_processes;

        if (!placeProcesses?.length && state.productionProcessProfiles?.length) {
            placeProcesses = state.productionProcessProfiles
                .map((profileProcess) => {
                    const process = processes.find((p) => p.id === profileProcess.id);
                    if (!process) {
                        return;
                    }

                    return {
                        fromProfile: true,
                        production_process: {
                            ...process,
                        },
                        sub_processes: [...process.sub_processes],
                    };
                })
                ?.filter((process) => !!process);
        }

        const active = placeProcesses
            ?.filter((i) => i?.production_process?.id !== NOT_PROCESSED)
            ?.filter((process) => !fspProductGroupId || +process.fsp_product_group_id === +fspProductGroupId)
            ?.map((activeProcess) => {
                const originalProcess = processes.find((p) => p.id === activeProcess.production_process.id);

                return {
                    ...activeProcess.production_process,
                    selected: true,
                    originallySelected: true,
                    food_handling_place_id: originalProcess?.food_handling_place_id,
                    original_production_process_id: originalProcess?.original_production_process_id,
                    sub_processes: originalProcess?.sub_processes.map((sp) => ({
                        ...sp,
                        selected: activeProcess?.sub_processes?.some((activeSp) => activeSp.id === sp.id),
                        originallySelected: activeProcess?.sub_processes?.some((activeSp) => activeSp.id === sp.id),
                    })),
                };
            });

        const inactive = processes
            .filter((process) => {
                const isActive = active?.find((filteredProcess) => filteredProcess?.id === process?.id);
                const isFromThisProductGroup =
                    fspProductGroupId === (process.fsp_product_group_id ?? fspProductGroupId);
                const isOverridedInSelectedGroup = processes.some((processFromAllProcesses) => {
                    return (
                        process.food_handling_place_id &&
                        processFromAllProcesses.fsp_product_group_id === fspProductGroupId &&
                        processFromAllProcesses.original_production_process_id === process.id
                    );
                });
                return !isActive && isFromThisProductGroup && !isOverridedInSelectedGroup;
            })
            .map((p) => ({
                ...p,
                selected: false,
                originallySelected: false,
                fsp_product_group_id: p.fsp_product_group_id,
            }));

        commit('initProductionProcesses', { active, inactive });
    },
    async saveProductionProcesses({ rootGetters, getters, dispatch, commit }) {
        const fspProductGroupId = rootGetters['plan/fspProductGroup/selectedGroupId'];
        const processes = [
            ...cloneDeep(getters.getProductionProcesses.active),
            ...cloneDeep(getters.getProductionProcesses.inactive),
        ];
        let payload = processes
            .filter((p) => p.selected)
            .map((p) => ({
                process_id: p.id,
                fsp_product_group_id: p.fsp_product_group_id,
                sub_processes: p.sub_processes ? p.sub_processes.filter((sp) => sp.selected).map((sp) => sp.id) : [],
            }));

        if (fspProductGroupId) {
            const otherGroupsProcesses = rootGetters.selectedPlace?.place_processes
                ?.filter((process) => +process.fsp_product_group_id !== +fspProductGroupId)
                ?.map((process) => ({
                    process_id: process.production_process.id,
                    sub_processes: process.sub_processes.map((subProcess) => subProcess.id),
                    fsp_product_group_id: process.fsp_product_group_id,
                }));
            payload = [...payload, ...otherGroupsProcesses];
        }

        await dispatch('updateProcesses', payload);
        const active = processes
            .filter((p) => p.selected)
            .map((p) => ({
                ...p,
                originallySelected: true,
                fsp_product_group_id: p.fsp_product_group_id || fspProductGroupId,
            }));
        const inactive = processes
            .filter((p) => !p.selected)
            .map((p) => ({
                ...p,
                originallySelected: false,
                fsp_product_group_id: p.fsp_product_group_id || fspProductGroupId,
            }));

        commit('initProductionProcesses', { active, inactive });

        if (fspProductGroupId) {
            await dispatch('fspProductGroup/getAllStatuses');
        }
    },
    async init({ rootGetters, dispatch }) {
        if (rootGetters.selectedPlace && !rootGetters.selectedPlace.uses_custom_plan) {
            await dispatch('getLangIds');
            await dispatch('fetchPlanData', {
                isPlanChanged: false,
            });
        }

        await dispatch('getProductionProcesses', {
            businessActivityId: rootGetters.selectedPlace.business_activity?.id,
            extraDataIds: rootGetters.selectedPlace.extra_data?.map((ed) => ed.id),
            placeId: rootGetters.selectedPlaceId,
            fspProductGroupId: rootGetters['plan/fspProductGroup/selectedGroupId'],
        });
        await dispatch('getDigitalAudits');
        await dispatch('getHazardEstimations');
        await dispatch('initProductionProcesses');

        if (!rootGetters['shared-access/isSharedAccess']) {
            await dispatch('getAllTaskSubtypes');
        }
    },
    /**
     * Any plan module change should trigger this action
     */
    async getStates({ dispatch }) {
        try {
            return await Promise.all([dispatch('getDetails'), dispatch('getSelectedPlanStatuses')]);
        } catch {
            console.error('Failed to fetch plan details');
        }
    },

    changeBeforeOrAfterText({ rootGetters }, payload) {
        if (payload.id) {
            return http.put(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/before-or-after/${payload.id}`,
                payload
            );
        } else {
            return http.post(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/before-or-after`, payload);
        }
    },
    enableDisableBeforeOrAfterText({ rootGetters }, { id, bool }) {
        return http.put(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/before-or-after/${id}/disabled`, {
            disabled_for_place: bool,
        });
    },
    async getSelectedPlanStatuses({ rootGetters, commit }) {
        return retry(
            async () => {
                if (!rootGetters?.selectedPlace?.food_safety_plan?.id) {
                    throw 'Plan id not found!';
                }

                return await http
                    .get(
                        `api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters.selectedPlace.food_safety_plan.id}/statuses`
                    )
                    .then(({ data }) => {
                        const statuses = Object.assign(
                            {},
                            ...data.map((status) => ({ [status.fsp_status_type_id]: status.updated_at }))
                        );
                        commit('setStatuses', statuses);

                        return statuses;
                    });
            },
            50,
            300
        );
    },
    async addSectionGroupMember({ rootGetters }, payload) {
        return await preventMultiple(
            await http.post(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/section-groups`, payload)
        );
    },
    editSectionGroupMember({ rootGetters }, payload) {
        return preventMultiple(
            http.put(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/section-groups/${payload.original_fsp_section_group_id}`,
                payload
            )
        );
    },
    async deleteSectionGroupMember({ rootGetters }, doc) {
        if (doc.isCustom) {
            return await preventMultiple(
                await http.delete(
                    `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/section-groups/${doc.containerId}`
                )
            );
        } else {
            return await preventMultiple(
                await http.put(
                    `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/section-groups/${doc.containerId}`,
                    {
                        disabled_for_place: true,
                    }
                )
            );
        }
    },
    getDigitalAudits({ commit, rootGetters }) {
        if (rootGetters.selectedCompanyId) {
            return http.get(`/api/company/${rootGetters.selectedCompanyId}/digital-audits`).then((res) => {
                commit('setAudits', res?.data?.active || []);
            });
        } else {
            vm.$toastr.e(vm.$t("Can't get company id"));
        }
    },
    getAllTaskSubtypes({ commit, rootGetters }) {
        if (rootGetters.selectedPlaceId) {
            return http.get(`/api/place/${rootGetters.selectedPlaceId}/tasks`).then(({ data }) => {
                commit('setTaskSubtypes', data || []);
            });
        } else {
            vm.$toastr.e(vm.$t("Can't get company id"));
        }
    },
    getHazardEstimations({ commit }) {
        return Promise.all([
            http.get('/api/classifiers/fsp-hazard-types').then((res) => res.data),
            http.get('/api/classifiers/fsp-hazard-probabilities').then((res) => res.data),
            http.get('/api/classifiers/fsp-hazard-severities').then((res) => res.data),
            http
                .get('/api/classifiers/fsp-hazard-preventions')
                .then((res) => res.data.sort((a, b) => a.order_number - b.order_number)),
        ]).then((res) => {
            commit('updateHazardEstimations', {
                types: res[0],
                probabilities: res[1],
                severities: res[2],
                preventions: res[3],
            });
        });
    },
    deleteHazard({ rootGetters }, hazardId) {
        return http
            .delete(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/hazards/${hazardId}`)
            .then(({ data }) => {
                return data;
            });
    },
    getHazardDetails({ rootGetters, commit }, { hazardId, fspProductGroupId }) {
        return http
            .get(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/hazards/${hazardId}`, {
                params: {
                    fsp_product_group_id: fspProductGroupId,
                },
            })
            .then((res) => {
                commit('updateHazardDetails', res.data);
                return res.data;
            });
    },
    editProductionProcessInstruction({ rootGetters, commit }, { instruction }) {
        if (!instruction.id) {
            return;
        }
        return http
            .put(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/hazards/${instruction.id}`, instruction)
            .then((response) => {
                commit('updateProductionProcessInstruction', response.data);
                return response.data;
            })
            .catch(() => {
                vm.$toastr.e('error');
            });
    },
    addProductionProcessInstruction({ rootGetters, commit }, { instruction }) {
        return http
            .post(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/hazards/`, instruction)
            .then((response) => {
                commit('updateProductionProcessInstruction', response.data);
                return response.data;
            });
    },
    getProductionProcessInstructions({ rootGetters, commit }, { productionProcessId, fspProductGroupId }) {
        return http
            .get(`/api/places/${rootGetters.selectedPlaceId}/production-processes/${productionProcessId}`, {
                params: {
                    fsp_product_group_id: fspProductGroupId,
                },
            })
            .then((response) => {
                commit('setProductionProcessInstructions', response?.data?.hazards || []);
                return response.data;
            });
    },
    getProductionProcess({ rootGetters }, id) {
        return http
            .get(`/api/places/${rootGetters.selectedPlaceId}/production-processes/${id}`, null, true)
            .then(({ data }) => data)
            .catch(() => null);
    },
    getLangIds({ commit }) {
        return http
            .get(`/api/classifiers/all-languages`)
            .then((response) => response.data)
            .then((languages) => {
                if (languages) {
                    commit('setPlanIds', languages);
                }
            });
    },
    async getDetails({ commit, getters, rootGetters }) {
        return await http
            .get(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${getters.selectedPlanId}/status`)
            .then((response) => response.data)
            .then((status) => {
                commit('updateSelectedPlanDetails', {
                    general_data_once_filled: status.general_data_once_filled,
                    plan: {
                        id: state.selectedPlanDetails.id,
                        status: {
                            fsp_status_type_id: status.status.fsp_status_type_id,
                        },
                    },
                });
            });
    },
    updateWorkGroups({ commit, dispatch, rootGetters }, data) {
        const placeData = { ...rootGetters.selectedPlace };
        const jobTitlesCopy = Object.assign({}, placeData);
        placeData.job_titles = data;
        commit(UPDATE_PLACE, placeData, { root: true });

        return http
            .put(`/api/job-titles/${placeData.job_titles.id}`, data)
            .then(({ data }) => {
                dispatch('getStates');
                placeData.job_titles = data;
                commit(UPDATE_PLACE, placeData, { root: true });
                return data;
            })
            .catch(() => {
                placeData.job_titles = jobTitlesCopy;
                commit(UPDATE_PLACE, placeData, { root: true });
            });
    },
    createCustomAnalyzes({ commit, dispatch, rootGetters }, data) {
        const placeData = { ...rootGetters.selectedPlace };
        return preventMultiple(
            http.post(`/api/place/${rootGetters.selectedPlaceId}/custom_analyses`, data).then(({ data }) => {
                placeData.custom_analyses = data.data;
                commit(UPDATE_PLACE, placeData, { root: true });
                dispatch('getStates');
                return data;
            })
        );
    },
    async createCustomProcesses({ rootGetters, dispatch, commit }, data) {
        const response = await http.post(`/api/places/${rootGetters.selectedPlaceId}/production-processes`, data);

        let newProcess = response?.data ? response.data : null;
        if (newProcess) {
            commit('setProcessDetails', {
                ...newProcess,
                id: newProcess.id,
                sections: rootGetters['plan/custom-content/data'](newProcess?.fsp_table_id)?.filtered_children,
                fsp_table_id: newProcess.fsp_table_id,
            });
            const selected = (sp) =>
                data.is_disabled === true ? false : data?.subProcesses?.includes(sp?.original_production_subprocess_id);
            const fspProductGroupId =
                data?.fsp_product_group_id ||
                newProcess?.fsp_product_group_id ||
                rootGetters['plan/fspProductGroup/selectedGroupId'];
            const process = {
                id: newProcess.id,
                name: data.name,
                fsp_product_group_id: fspProductGroupId,
                order: newProcess.order,
                original_production_process_id: newProcess?.original_production_process_id,
                food_handling_place_id: newProcess?.food_handling_place_id,
                sub_processes: newProcess?.sub_processes.map((sp) => ({
                    ...sp,
                    selected: selected(sp),
                    originallySelected: selected(sp),
                })),
            };

            if (fspProductGroupId) {
                await dispatch('fspProductGroup/getStatuses', { fspProductGroupId, isCommit: true });
            }

            commit('deleteCustomProcess', data.original_production_process_id);

            if (data.is_disabled) {
                commit('addInactiveProductionProcess', process);
            } else {
                commit('addActiveProductionProcess', process);
            }
        }
        await dispatch('fetchPlanData', {
            placeId: rootGetters.selectedPlaceId,
            isPlanChanged: true,
        });

        return newProcess;
    },
    async editCustomProcess({ commit, getters, rootGetters, dispatch }, { data, noFetch }) {
        const response = await http.put(
            `/api/places/${rootGetters.selectedPlaceId}/production-processes/${data.id}`,
            data
        );

        let process = response?.data ? response.data : null;
        commit('editCustomProcesses', { process, getters, commit });
        commit('editActiveProductionProcess', { id: data.id, name: data.name, isDisabled: data.is_disabled });

        if (!noFetch) {
            await dispatch('fetchPlanData', { placeId: rootGetters.selectedPlaceId, isPlanChanged: true });
        }

        return true;
    },
    updateCustomAnalyses({ commit, dispatch, rootGetters }, data) {
        const placeData = { ...rootGetters.selectedPlace };
        return preventMultiple(
            http.put(`/api/place/${rootGetters.selectedPlaceId}/custom_analyses`, data).then(({ data }) => {
                placeData.custom_analyses = data.data;
                commit(UPDATE_PLACE, placeData, { root: true });
                dispatch('getStates');
                return data;
            })
        );
    },
    deleteCustomAnalyses({ commit, rootGetters }, id) {
        return http.delete(`/api/place/${rootGetters.selectedPlaceId}/custom_analyses/${id}`).then(() => {
            commit('deleteCustomAnalysis', { id, rootGetters });
            return true;
        });
    },
    async deleteCustomProcess({ commit, dispatch, rootGetters }, { id, originalId }) {
        await http.delete(`/api/places/${rootGetters.selectedPlaceId}/production-processes/${id}`);

        const placeData = { ...rootGetters.selectedPlace };
        placeData.place_processes = placeData.place_processes.filter((pp) => pp.production_process.id !== parseInt(id));
        await dispatch('getProductionProcesses', {
            businessActivityId: placeData.business_activity.id,
            extraDataIds: placeData.extra_data.map((ed) => ed.id),
            placeId: placeData.id,
            fspProductGroupId: rootGetters['plan/fspProductGroup/selectedGroupId'],
        });

        commit(UPDATE_PLACE, placeData, { root: true });
        dispatch('fetchPlanData', { placeId: rootGetters.selectedPlaceId, isPlanChanged: true });

        commit('deleteCustomProcess', id);

        if (originalId) {
            const originalProcess = state?.productionProcessesObj?.productionProcesses?.find(
                (process) => +process.id === +originalId
            );
            commit('addInactiveProductionProcess', originalProcess);
        }

        return true;
    },
    async getProductionProcesses({ commit }, { businessActivityId, extraDataIds, placeId, fspProductGroupId }) {
        if (businessActivityId != null) {
            return http
                .get(`/api/classifiers/business-activity/${businessActivityId}/production-processes`, {
                    params: {
                        extra_datas: extraDataIds?.length ? JSON.stringify(extraDataIds) : null,
                        placeId: placeId,
                        fsp_product_group_id: fspProductGroupId,
                    },
                })
                .then(({ data }) => {
                    commit('setProductionProcesses', {
                        placeId: placeId,
                        productionProcesses: data,
                    });
                    return data;
                });
        }
    },
    async updateProcesses({ commit, dispatch, rootGetters }, processes) {
        const fspProductGroupId = rootGetters['plan/fspProductGroup/selectedGroupId'];
        const params = { fsp_product_group_id: fspProductGroupId };

        return preventMultiple(
            await http
                .put(`/api/places/${rootGetters.selectedPlaceId}/production-processes`, processes, { params })
                .then(async ({ data }) => {
                    const placeData = { ...rootGetters.selectedPlace };
                    const previousLength = placeData.place_processes?.length;
                    placeData.place_processes = data;
                    commit(UPDATE_PLACE, placeData, { root: true });
                    if (previousLength === 0 || data.length === 0) {
                        await dispatch('getStates');
                    }
                    await dispatch('fetchPlanData', { placeId: rootGetters.selectedPlaceId, isPlanChanged: true });

                    if (fspProductGroupId) {
                        await dispatch('fspProductGroup/getStatuses', { fspProductGroupId, isCommit: true });
                    }

                    return data;
                })
        );
    },
    async getProductGroups({ commit, getters }, { businessActivityId, extraDataIds, placeId, refresh }) {
        const productGroupsObj = getters.productGroupsObj;
        if (
            productGroupsObj.productGroups &&
            productGroupsObj.productGroups.length &&
            placeId === productGroupsObj.placeId &&
            !refresh
        ) {
            return productGroupsObj.productGroups;
        }
        return http
            .get(
                `/api/classifiers/business-activity/${businessActivityId}/product-groups${
                    extraDataIds && extraDataIds.length ? '?extra_datas=' + JSON.stringify(extraDataIds) : ''
                }`
            )
            .then(({ data }) => {
                commit('setProductGroups', {
                    placeId: placeId,
                    productGroups: data,
                });
                return data;
            });
    },
    updateProductGroups({ dispatch, commit }, { productGroups, placeId }) {
        return preventMultiple(
            http.put(`/api/place/${placeId}/product-groups`, { product_groups: productGroups }).then(({ data }) => {
                commit(UPDATE_PLACE, data, { root: true });
                dispatch('getStates');
                return data;
            })
        );
    },
    updateProductionCapacities({ commit, dispatch, getters, rootGetters }, data) {
        const startingState = getters.checkStatus('productionVolumes');
        return preventMultiple(
            http.put('/api/production-capacity', data).then(({ data }) => {
                const placeData = { ...rootGetters.selectedPlace };
                if (data.resale_capacities) {
                    placeData.resale_capacities = data.resale_capacities;
                }
                if (data.food_production_capacities) {
                    placeData.food_production_capacities = data.food_production_capacities;
                }

                commit(UPDATE_PLACE, placeData, { root: true });

                dispatch('getStates').then((res) => {
                    const statuses = res[1];
                    if (!getters.generalDataOnceFilled) {
                        const productionVolumesIndex = 8;
                        const currentState = statuses?.[productionVolumesIndex];

                        if (!startingState && currentState) {
                            commit('showForeverDigitalModal', true);
                        }
                    }
                });

                return data;
            })
        );
    },
    async fetchFoodGroups({ commit }, { businessActivityId, extraDataIds, placeId }) {
        return http
            .get(
                `/api/classifiers/business-activity/${businessActivityId}/food-groups${
                    extraDataIds && extraDataIds.length ? '?extra_datas=' + JSON.stringify(extraDataIds) : ''
                }`
            )
            .then(({ data }) => {
                commit('setFoodGroups', {
                    placeId: placeId,
                    foodGroupCategories: data,
                });
                return data;
            });
    },
    async updateFoodGroups({ dispatch, rootGetters, commit }, data) {
        return await preventMultiple(
            await http.put(`/api/place/${rootGetters.selectedPlaceId}/food-groups`, data).then(({ data }) => {
                commit(UPDATE_PLACE, data, { root: true });
                dispatch('getStates');
                return data;
            })
        );
    },
    async generate({ commit, dispatch, rootGetters }) {
        await http.post(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/generate`).then(() => {
            const processes = rootGetters.selectedPlace?.onboarding_progress || [];
            const index = processes.findIndex((pr) => Object.keys(pr)[0] === 'plan_created');
            if (index !== -1) {
                processes[index]['plan_created'] = 'yes';
            }
            commit(UPDATE_PLACE, { ...this.selectedPlace, onboarding_progress: processes }, { root: true });
            dispatch('getSelectedPlanStatuses');
        });

        commit('updateSelectedPlanGeneralDataOnceFilled', true);
        await dispatch('fetchPlanData', {
            placeId: rootGetters.selectedPlaceId,
            isPlanChanged: true,
        });
    },
    async confirmPlan({ commit, rootGetters, dispatch }) {
        return await http.put(`/api/place/${rootGetters.selectedPlaceId}/confirm-plan`).then(({ data }) => {
            commit(UPDATE_PLACE, data, { root: true });
            dispatch('plan/getStates', null, { root: true });
            return data;
        });
    },
    fetchPlanData({ commit, state, rootGetters }, { isPlanChanged }) {
        if (
            !isPlanChanged &&
            state.sideMenuObj.sideMenu.length &&
            state.sideMenuObj.placeId === rootGetters.selectedPlaceId
        ) {
            return state.sideMenuObj.sideMenu;
        }
        if (rootGetters.selectedPlaceId) {
            return http
                .get(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/titles`)
                .then((planGroups) => {
                    commit('setPlanGroups', planGroups.data.sections);
                    commit('updateSelectedPlanDetails', planGroups.data);
                    const sideMenu = [];
                    planGroups.data.sections.map((planGroup) => {
                        if (
                            planGroup.static_name &&
                            planGroup.filtered_children &&
                            planGroup.filtered_children.length > 0
                        ) {
                            sideMenu.push({
                                title: planGroup.static_name,
                                pages: planGroup.filtered_children.map((planTable) => {
                                    return {
                                        title: planTable.translations,
                                        routerLink: `plan.${planTable.static_name}`,
                                        static_name: planTable.static_name,
                                    };
                                }),
                            });
                        }
                    });
                    commit('setSideMenu', {
                        placeId: rootGetters.selectedPlaceId,
                        sideMenu,
                        fspProductGroups: planGroups.data?.fsp_product_groups,
                    });
                });
        }
    },
    downloadFsp(_, { placeId, language, filename }) {
        return http
            .download(`/api/places/${placeId}/food-safety-plans/${state.selectedPlanDetails.id}/pdf?lang=${language}`)
            .then((res) => {
                if (res?.data) {
                    download(filename + '.zip', res.data);
                }
            })
            .catch((error) => {
                let message;
                try {
                    message = JSON.parse(Buffer.from(error.response.data).toString('utf8')).message;
                } catch {
                    message = 'error';
                }
                if (error?.response?.status === 402) {
                    throw new Error(message);
                } else {
                    vm.$toastr.e(vm.$t(message));
                }
            });
    },
    async setLayoutsOnPaperState({ dispatch, rootGetters }, value) {
        await dispatch(
            UPDATE_PLACE,
            {
                placeId: rootGetters.selectedPlaceId,
                data: {
                    layouts_on_paper: value ? 0 : 1,
                },
                redirect: false,
            },
            { root: true }
        );
        dispatch('getStates');
    },
    async setLocationOnPaperState({ dispatch, rootGetters }, value) {
        await dispatch(
            UPDATE_PLACE,
            {
                placeId: rootGetters.selectedPlaceId,
                data: {
                    location_plan_on_paper: value ? 1 : 0,
                },
                setSelectedPlace: false,
                redirect: false,
            },
            { root: true }
        );
        dispatch('getStates');
    },
};

const getters = {
    planState: (state) => state.planState,
    foodCapacityDefaults: (state) => state.foodCapacityDefaults,
    foodResaleCapacityRanges: (state) =>
        state.foodResaleCapacityRanges
            .filter((range) => range.id !== RANGE_OPTION_OTHER_ID)
            .map((range) => range.range_option),
    foodProductionCapacityRangesWithIds: (state) =>
        state.foodProductionCapacityRanges.filter((range) => range.id !== RANGE_OPTION_OTHER_ID),
    foodProductionCapacityRanges: (state) =>
        state.foodProductionCapacityRanges
            .filter((range) => range.id !== RANGE_OPTION_OTHER_ID)
            .map((range) => range.range_option),
    activeProcessSubProcesses: (state) => state.productionProcesses.active,
    getProductionProcesses: (state) => state.productionProcesses,
    isEditMode: (state) => state.isEditMode,
    editModeActive: (state) => state.editModeActive,
    sideMenuOpen: (state) => state.sideMenuOpen,
    showForeverDigitalModal: (state) => {
        return state.showForeverDigitalModal;
    },
    checkStatus: (state, getters, rootState, rootGetters) => (type) => {
        const planStatus = { generalDataFillingInProgress: 1, generalDataIsFilled: 2, confirmed: 3 };

        const generalData = rootGetters.isProductBasedPlan
            ? {
                  generalInfo: 4,
                  workGroups: 5,
                  analyses: 10,
                  locationPlan: 12,
                  drawings: 13,
                  fspProductGroup: 15,
              }
            : {
                  generalInfo: 4,
                  workGroups: 5,
                  productionProcesses: 6,
                  productGroup: 7,
                  productionVolumes: 8,
                  foodGroups: 9,
                  analyses: 10,
                  flowDiagram: 11,
                  locationPlan: 12,
                  drawings: 13,
              };

        if (type === 'allFilled') {
            return Object.values(generalData).every((key) => Object.keys(state.statuses).map(Number).includes(+key));
        }
        return state.statuses?.[{ ...planStatus, ...generalData }?.[type]] || false;
    },
    getCurrentInstructions: (state) => {
        return state.instructions;
    },
    planGroups: (state) => {
        return state.planGroups;
    },
    productionProcessesObj: (state) => {
        return state.productionProcessesObj;
    },
    productGroupsObj: (state) => {
        return state.productGroupsObj;
    },
    foodGroupCategoriesObj: (state) => {
        return state.foodGroupCategoriesObj;
    },
    isPlanChanged: (state) => {
        return state.isPlanChanged;
    },
    selectedPlanDetails: (state) => {
        return state.selectedPlanDetails;
    },
    generalDataOnceFilled: (state) => {
        return state.selectedPlanDetails.general_data_once_filled;
    },
    selectedPlanStatus: (state) => {
        switch (state?.selectedPlanDetails.status?.fsp_status_type_id) {
            case 1:
                return 'unfilled';
            case 2:
                return 'filled';
            case 3:
                return 'confirmed';
            default:
                return 'unfilled';
        }
    },
    selectedPlanId: (state) => {
        return state.selectedPlanDetails.id;
    },
    getLanguageIds: (state) => {
        return state.planLanguages;
    },
    hazardEstimations(state) {
        return state.hazard_estimations;
    },
    getProcessDetails(state) {
        return state.processDetails;
    },
    getAllAudits(state) {
        return state.audits;
    },
    getAllTaskSubtypes(state) {
        return state.taskSubtypes;
    },
    getSideMenu(state) {
        return state.sideMenuObj.sideMenu;
    },
    getFspProductGroupsSideMenu(state) {
        return state.sideMenuObj.fspProductGroups;
    },
    getTechnologyDiagrams(state) {
        return state.technologyDiagrams;
    },
    getTechnologyDiagramName(state) {
        return state.technologyDiagramName;
    },
    generalInfoPages(state, getters, rootState, rootGetters) {
        let query = {};
        if (!getters.generalDataOnceFilled) {
            query = { fromSetup: true };
        }
        let data = [
            {
                title: 'general_data',
                subTitle: 'general_data.sub_title',
                to: { name: 'plan.general-info', query },
                invalid: !getters.checkStatus('generalInfo'),
                fspProductGroupSectionIndex: 1,
            },
            {
                title: 'work_groups',
                subTitle: 'work_groups.sub_title',
                to: { name: 'plan.work-groups', query },
                invalid: !getters.checkStatus('workGroups'),
                fspProductGroupSectionIndex: 2,
            },
            {
                title: 'production_processes',
                subTitle: 'production_processes.sub_title',
                to: { name: 'plan.production-processes', query },
                invalid: !getters.checkStatus('productionProcesses'),
            },
            {
                title: 'ready_to_eat_food',
                subTitle: 'ready_to_eat_food.sub_title',
                to: { name: 'plan.ready-to-eat-food', query },
                invalid: !getters.checkStatus('productGroup'),
            },
            {
                title: 'food_groups',
                subTitle: 'food_groups.sub_title',
                to: { name: 'plan.raw-materials', query },
                invalid: !getters.checkStatus('foodGroups'),
            },
            {
                title: 'analyses',
                subTitle: 'analyses.sub_title',
                to: { name: 'plan.analyses', query },
                invalid: !getters.checkStatus('analyses'),
                fspProductGroupSectionIndex: 5,
            },
            {
                title: 'technological_schema',
                subTitle: 'technological_schema.sub_title',
                to: { name: 'plan.technological-schema', query },
                invalid: !getters.checkStatus('flowDiagram'),
            },
            {
                title: 'location',
                subTitle: 'location.sub_title',
                to: { name: 'plan.location', query },
                invalid: !getters.checkStatus('locationPlan'),
                fspProductGroupSectionIndex: 3,
                withToggle: rootGetters.isProductBasedPlan,
                toggleStatus: !getters.isLocationOnPaper,
            },
            {
                title: 'drawings',
                subTitle: 'drawings.sub_title',
                to: { name: 'plan.plans', query },
                invalid: !getters.checkStatus('drawings'),
                fspProductGroupSectionIndex: 4,
                withToggle: rootGetters.isProductBasedPlan,
                toggleStatus: getters.isLayoutsOnPaper,
            },
        ];

        const productGroups = rootGetters?.selectedPlace?.product_groups || [];

        if (
            !rootGetters.isProductBasedPlan &&
            (productGroups.length > 1 ||
                (productGroups.length !== 0 && !productGroups?.some((pg) => pg.id === BOUGHT_IN_PRODUCTS)))
        ) {
            data.splice(4, 0, {
                title: 'production_capacity',
                subTitle: 'production_capacity.sub_title',
                to: { name: 'plan.production-capacity', query },
                invalid: !getters.checkStatus('productionVolumes'),
            });
        }

        if (rootGetters.isProductBasedPlan) {
            const filteredData = data.filter((d) => d.fspProductGroupSectionIndex != null);
            filteredData.sort((a, b) => a.fspProductGroupSectionIndex - b.fspProductGroupSectionIndex);
            return filteredData;
        }

        return data;
    },
    sideMenu: (state, getters) => {
        const hazardListBody = {
            title: 'Summary',
            to: { name: 'plan.hazards-summary-list' },
        };
        const annexesSeparator = {
            title: 'ANNEXES',
            type: 'contentSeparator',
            noOrderNumber: true,
        };
        const hazardMatrixBody = {
            title: 'Hazard matrix',
            to: { name: 'plan.hazard-matrix' },
        };
        const list = [
            hazardListBody,
            {
                title: 'GENERAL',
                type: 'contentSeparator',
                noOrderNumber: true,
            },
            {
                title: 'Title page',
                to: { name: 'plan.title-page' },
                noOrderNumber: true,
            },
            {
                title: getters.getFspProductGroupsSideMenu
                    ? 'Company details'
                    : 'company_food_handling_place_general_data',
                children: getters?.generalInfoPages?.filter((link) => !link.withToggle || link.toggleStatus),
            },
            ...(getters.selectedPlanStatus === 'unfilled'
                ? []
                : getters.getSideMenu.map((item) => ({
                      title: item.title,
                      children: item.pages.map((page) => ({
                          title: planMixin.methods.decipherAPIString.call(vm, page.title) || page.static_name,
                          to: {
                              path: `/plan/${page.routerLink.split('.')[1]}`,
                              link: page.routerLink,
                              params: {
                                  inlineEditEnabled:
                                      ['food_handling_hazard_analysis', 'preconditions_to_prevent_hazard'].includes(
                                          item.title
                                      ) || ['production_documents'].includes(page.static_name),
                              },
                          },
                      })),
                  }))),
        ];

        if (getters.getFspProductGroupsSideMenu) {
            list.push({
                title: 'HACCP ANALYSIS',
                type: 'contentSeparator',
                noOrderNumber: true,
            });
            getters.getFspProductGroupsSideMenu?.forEach((group) => {
                const params = { fspProductGroupId: group.id };
                const query = params;
                list.push({
                    title: group.name,
                    children: [
                        {
                            title: 'Products list',
                            to: {
                                name: 'plan.fsp-products',
                                params,
                            },
                        },
                        {
                            title: 'food_groups',
                            to: {
                                name: 'plan.raw-materials',
                                query,
                            },
                        },
                        {
                            title: 'technological_schema',
                            to: {
                                name: 'plan.technological-schema',
                                query,
                            },
                        },
                        {
                            title: 'Hazard Analysis',
                            to: {
                                name: 'plan.hazard-analyses-overview',
                                query,
                            },
                        },
                        {
                            title: 'Food safety plan',
                            to: {
                                name: 'plan.ccp-list',
                                query,
                            },
                        },
                        {
                            title: 'Processes in detail',
                            children: cloneDeep(group?.production_processes)
                                ?.sort((p1, p2) => p1.order_number - p2.order_number)
                                ?.map((process) => ({
                                    title: process.static_name,
                                    to: {
                                        name: 'plan.custom',
                                        params: {
                                            custom: process.static_name,
                                            inlineEditEnabled: true,
                                        },
                                        query,
                                    },
                                })),
                        },
                    ],
                });
            });
            list.push(annexesSeparator);
            list.push(hazardMatrixBody);
        }

        const index = list.findIndex((item) => item.title === 'lisad');

        if (!getters.getFspProductGroupsSideMenu) {
            if (index === -1) {
                list.push({
                    title: 'lisad',
                    children: [hazardMatrixBody],
                });
            } else {
                list[index].children.unshift(hazardMatrixBody);
            }
        }

        return list;
    },
    isPlanCreated(state, getters, rootState, rootGetters) {
        // TODO: Hot fix just check is place exists
        if (rootGetters.selectedPlace?.id && rootGetters.isPlaceLoaded) {
            const onBoarding = rootGetters.selectedPlace?.onboarding_progress;
            return !!onBoarding?.find((type) => !!type?.plan_created);
        }

        return false;
    },
    // Drawings related
    isLayoutsOnPaper(state, getters, rootState, rootGetters) {
        return rootGetters.selectedPlace?.layouts_on_paper === 0;
    },
    isLocationOnPaper(state, getters, rootState, rootGetters) {
        return rootGetters.selectedPlace?.location_plan_on_paper === 1;
    },
    productionProcessProfiles(state) {
        return state.productionProcessProfiles;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    modules: {
        sections,
        analyses,
        workGroup,
        'custom-content': customContent,
        hazard,
        'general-info': generalInfo,
        fspProductGroup,
    },
    namespaced: true,
};
