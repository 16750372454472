// TODO: Get rid of it
import http from '@http';
import { vm } from '@main';

function state() {
    return {
        data: [],
    };
}

const getters = {
    currentPlan: (store, getters, rootStore, rootGetters) => {
        const planGroups: any = [];
        rootGetters['plan/planGroups'].map((planGroup: any) => {
            planGroups.push(...(planGroup?.filtered_children || []));
        });

        const routeParams = vm?.$route?.name?.split('.');
        const currentPage = vm.$route?.params?.custom || routeParams?.[routeParams?.length - 1];

        return planGroups.find((planGroup) => planGroup && planGroup.static_name === currentPage);
    },
    data: (state, getter, rootState, rootGetters) => (planTableId) => {
        const table = state?.data?.length
            ? state.data.filter((pg) => pg.id === planTableId)
            : rootGetters['plan/planGroups'].filter((pg) => pg.id === planTableId);
        return table && table.length ? table[0] : {};
    },
    estimations: (state) => (planId, sectionId, instructionId) => {
        const filteredChildren = state.data?.find((item) => item.id === planId)?.filtered_children;
        let child: any = {};
        if (isNaN(sectionId)) {
            child = filteredChildren?.find((item) =>
                item?.subcontainers?.find((subItem) => subItem.id === instructionId)
            );
        } else {
            child = filteredChildren?.find((item) => item.id === sectionId);
        }
        return child?.subcontainers?.find((item) => item.id === instructionId)?.most_popular_estimation;
    },
};

const mutations = {
    setData(state: any, payload) {
        state.data = payload;
    },
};

const actions = {
    async getProposals({ rootGetters }) {
        const params = {
            lang:
                rootGetters.selectedPlace?.preferred_language ||
                rootGetters.selectedCompany?.company?.preferred_language ||
                rootGetters.userProfile.lang,
        };
        const res = await http.get(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/change-proposals`, {
            params,
        });

        return res?.data || [];
    },
    sendProposals({ rootGetters }, payload) {
        return http.put(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/change-proposals`, payload);
    },
    async getContent({ commit, getters, rootGetters, dispatch }, { id, reload = false }) {
        if (!id) {
            return;
        }

        const section = !reload && rootGetters['plan/sections/getSection'](id);
        const res = section || (await dispatch('plan/sections/getSingle', id, { root: true }));

        commit('setData', res?.sections);

        return res?.sections;
    },
    async update({ commit, dispatch, rootGetters }, payload: any) {
        try {
            const body: any = {};
            body.name = payload.name;
            if (payload.isNew) {
                return await dispatch(
                    'plan/createCustomProcesses',
                    {
                        name: payload.name,
                        original_production_process_id: payload?.original_production_process_id,
                    },
                    { root: true }
                ).then(async (newProcess) => {
                    await dispatch(
                        'plan/updateProcesses',
                        prepareSelectedProcesses(payload.selectedProcesses, newProcess.id, payload.isSelected),
                        { root: true }
                    );

                    return;
                });
            } else {
                if (payload.isOriginal) {
                    body.food_handling_place_id = rootGetters.selectedPlace.id;
                    body.original_production_process_id = payload.id;
                    body.id = null;

                    const hazards: any = [];
                    for (let i = 0; i < payload.instructions.length; i++) {
                        await dispatch(
                            'plan/getHazardDetails',
                            { hazardId: payload.instructions[i].id, fspProductGroupId: payload.fspProductGroupId },
                            { root: true }
                        ).then((h) => {
                            hazards.push(h);
                        });
                    }
                    dispatch('plan/createCustomProcesses', body, { root: true }).then(async (newProcess) => {
                        await dispatch(
                            'plan/updateProcesses',
                            prepareSelectedProcesses(
                                payload.selectedProcesses,
                                newProcess.id,
                                payload.isSelected,
                                newProcess.original_production_process_id
                            ),
                            { root: true }
                        );
                        const promises: any = [];
                        await hazards.forEach((hazard) => {
                            promises.push(
                                dispatch(
                                    'plan/addProductionProcessInstruction',
                                    {
                                        instruction: {
                                            original_fsp_section_group_id: hazard.id,
                                            members: removeIdFromAllMembers(hazard.members),
                                            fromOriginal: true,
                                            food_handling_place_id: payload.food_handling_place_id,
                                            fsp_table_id: newProcess.fsp_table_id,
                                            disabled_for_place: hazard.disabled_for_place,
                                        },
                                    },
                                    { root: true }
                                )
                            );
                        });
                        await Promise.all(promises);

                        return newProcess;
                    });
                } else {
                    body.id = payload.id;
                    body.hazards = payload.instructions;
                    body.original_production_process_id = payload.original_production_process_id;
                    await dispatch('plan/editCustomProcess', { data: body }, { root: true }).then(() => {
                        dispatch(
                            'plan/updateProcesses',
                            prepareSelectedProcesses(payload.selectedProcesses, payload.id, payload.isSelected),
                            { root: true }
                        );
                    });

                    return;
                }
            }
        } finally {
            await dispatch('plan/getStates', null, { root: true });
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

function prepareSelectedProcesses(selectedProcesses, id, toBeAdded, originalId?) {
    let processes = selectedProcesses?.length ? [...selectedProcesses] : [];
    if (toBeAdded) {
        const index = processes.findIndex((data) => data.production_process.id === id);
        if (index === -1) {
            processes.push({ production_process: { id: id } });
            if (originalId) {
                processes = processes.filter((p) => p.production_process.id !== originalId);
            }
        } else {
            processes[index].production_process.id = id;
        }
    } else {
        processes = processes.filter((p) => p.production_process.id !== id);
    }
    processes = processes.map(function (sp) {
        return {
            process_id: sp.production_process.id,
            fsp_product_group_id: sp.production_process.fsp_product_group_id,
            sub_processes: sp.sub_processes,
        };
    });
    return processes;
}

function removeIdFromAllMembers(members) {
    return members.map((m) => ({
        ...m,
        id: null,
        static_name: m.static_name || m.parent.static_name,
        original_fsp_section_id: m.id || null,
    }));
}
