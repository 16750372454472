import { vm } from '@main';
import Vue from 'vue';

import http from '@http';

function state() {
    return {
        items: {},
        types: [],
    };
}

const getters = {
    getEquipment: (state) => (id) => state.items[id],
    equipments: (state) => Object.keys(state.items).map((id) => state.items[id]),
    types: (state) => state.types,
};

const mutations = {
    set(state, payload) {
        Vue.set(state.items, payload.id, payload);
    },
    setTypes(state, types) {
        Vue.set(state, 'types', types);
    },
    resetItems(storeState) {
        storeState.items = state().items;
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getAll({ commit, rootGetters }) {
        try {
            commit('resetItems');
            const response = await http.get(`/api/places/${rootGetters.selectedPlaceId}/equipment`);
            response.data.forEach((equipment) => {
                commit('set', fromJSON(equipment));
            });
        } catch (error) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async get({ commit, rootGetters }, id) {
        try {
            const response = await http.get(`/api/places/${rootGetters.selectedPlaceId}/equipment/${id}`);
            const responseData = fromJSON(response.data);

            commit('set', responseData);
        } catch (error) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async create({ commit, rootGetters, dispatch }, payload) {
        try {
            const sensor = await dispatch('sensor/change', payload.sensor, { root: true });
            if (!sensor && payload?.sensor?.name) {
                return false;
            }

            const response = await http.post(
                `/api/places/${rootGetters.selectedPlaceId}/equipment`,
                toJSON({ ...payload, sensor })
            );
            const data = fromJSON(response.data);
            commit('set', data);

            return data;
        } catch (error) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async update({ commit, rootGetters, dispatch }, payload) {
        try {
            const sensor = await dispatch('sensor/change', payload.sensor, { root: true });
            if (!sensor && payload?.sensor?.name) {
                return false;
            }

            const response = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/equipment/${payload.id}`,
                toJSON({ ...payload, sensor })
            );
            const data = fromJSON(response.data);
            commit('set', data);

            return data;
        } catch (error) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async toggleActivation({ dispatch, getters }, id) {
        const equipment = getters.getEquipment(id);

        dispatch('update', {
            ...equipment,
            isActive: !equipment.isActive,
        });
    },
    async getTypes({ getters, commit, rootGetters }) {
        if (getters.types?.length) {
            return getters.types;
        }

        try {
            const response = await http.get(`/api/places/${rootGetters.selectedPlaceId}/equipment/types`);

            commit('setTypes', response.data);

            return getters.types;
        } catch (error) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

function fromJSON(json) {
    return {
        id: json.id,
        name: json.name,
        sensor: {
            id: json?.sensor?.id,
            name: json?.sensor?.name,
            below: json?.sensor?.lower_error_margin,
            above: json?.sensor?.upper_error_margin,
            sendNotificationAfter: json?.sensor?.max_error_count,
        },
        room: {
            id: json.room?.id,
            name: json.room?.name,
        },
        type: json.equipment_type?.id,
        sensorId: json.sensor_id,
        isActive: json.is_active,
        isDefault: json.is_default,
        tasksGroups:
            json.entity_task_subtypes?.map((group) => ({
                id: group.id,
                subtypeId: group.task_subtype?.id,
                isActive: group.is_active,
                orderNumber: group.order_number,
                name: group.task_subtype?.name,
            })) || [],
    };
}

function toJSON(data) {
    return {
        id: data.id,
        name: data.name,
        sensor: {
            id: data?.sensor?.id,
            name: data?.sensor?.name,
            lower_error_margin: data?.sensor?.below,
            upper_error_margin: data?.sensor?.above,
            max_error_count: data?.sensor?.sendNotificationAfter,
        },
        room: {
            id: data.room?.id,
        },
        equipment_type: {
            id: data.type,
        },
        is_active: data.isActive,
        entity_task_subtypes:
            data.tasksGroups?.map((group) => ({
                id: group.id,
                is_active: group.isActive,
                order_number: group.orderNumber,
                task_subtype: {
                    name: group.name,
                },
            })) || [],
    };
}
