export default [
    {
        path: '',
        name: 'team',
        redirect: {
            name: 'team.people',
        },
        component: () => import(/* webpackChunkName: "team-module" */ './Index.vue'),
        children: [
            {
                path: 'people',
                name: 'team.people',
                component: () => import(/* webpackChunkName: "team-module" */ './TeamMembers/WorkersTable/index.vue'),
                children: [
                    {
                        path: ':id',
                        name: 'team.people.person',
                        component: () =>
                            import(/* webpackChunkName: "team-module" */ '@views/Team/TeamMembers/WorkerPanel/index'),
                    },
                    {
                        path: 'new',
                        name: 'team.people.new',
                        component: () =>
                            import(/* webpackChunkName: "team-module" */ '@views/Team/TeamMembers/WorkerPanel/index'),
                    },
                ],
            },
            {
                path: 'roles',
                name: 'team.roles',
                component: () => import(/* webpackChunkName: "team-module" */ './TeamRoles/index'),
                children: [
                    {
                        path: ':id',
                        name: 'team.roles.role',
                        component: () =>
                            import(/* webpackChunkName: "team-module" */ '@views/Team/TeamRoles/RolePanel/index'),
                    },
                    {
                        path: 'new',
                        name: 'team.roles.new',
                        component: () =>
                            import(/* webpackChunkName: "team-module" */ '@views/Team/TeamRoles/RolePanel/index'),
                    },
                ],
            },
            {
                path: 'certificates-and-trainings',
                name: 'team.certificates-and-trainings',
                component: () => import('./TeamTasks/TeamTasks'),
                children: [
                    {
                        path: ':id',
                        name: 'team.certificates-and-trainings.task',
                        component: () => import('@views/Team/TeamTasks/DefineTaskPanel/index'),
                    },
                    {
                        path: 'new',
                        name: 'team.certificates-and-trainings.new',
                        component: () => import('@views/Team/TeamTasks/DefineTaskPanel/index'),
                    },
                ],
            },
        ],
    },
];
