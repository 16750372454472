const Overview = () => import(/* webpackChunkName: "overview-module" */ '@views/Overview');
const Group = () => import(/* webpackChunkName: "overview-module" */ '@views/Overview/views/Group');

export default [
    {
        path: '/overview',
        name: 'overview',
        component: Overview,
    },
    {
        path: '/overview/:groupId',
        name: 'overview.group',
        component: Group,
    },
];
