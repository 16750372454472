import { vm } from '@main';
import http from '@services/http/http.service';

function state() {
    return {};
}

const getters = {};

const mutations = {};

const actions = {
    change({ dispatch }, payload) {
        if (payload?.id) {
            if (payload?.name) {
                return dispatch('edit', payload);
            } else {
                return dispatch('delete', payload);
            }
        } else if (payload?.name) {
            return dispatch('create', payload);
        }
    },
    async create({ rootGetters }, payload) {
        try {
            const response = await http.post(`/api/places/${rootGetters.selectedPlaceId}/sensors`, toJSON(payload));
            return fromJSON(response.data);
        } catch (error) {
            const msg = error?.response?.data?.error || error;
            console.error(msg);
            vm.$toastr.e(vm.$t(msg));
        }
    },
    async edit({ rootGetters }, payload) {
        try {
            const response = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/sensors/${payload.id}`,
                toJSON(payload)
            );
            return fromJSON(response.data);
        } catch (error) {
            const msg = error?.response?.data?.error || error;
            console.error(msg);
            vm.$toastr.e(vm.$t(msg));
        }
    },
    remove({ rootGetters }, payload) {
        try {
            return http.delete(`/api/places/${rootGetters.selectedPlaceId}/sensors/${payload.id}`, toJSON(payload));
        } catch (error) {
            const msg = error?.response?.data?.error || error;
            console.error(msg);
            vm.$toastr.e(vm.$t(msg));
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

function fromJSON(json) {
    return {
        id: json.id,
        name: json.name,
        below: json.lower_error_margin,
        above: json.upper_error_margin,
        sendNotificationAfter: json.max_error_count,
    };
}

function toJSON(data) {
    return {
        id: data?.id,
        name: data?.name,
        lower_error_margin: data?.below,
        upper_error_margin: data?.above,
        max_error_count: data?.sendNotificationAfter,
    };
}
