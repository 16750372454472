const CELERY_IMAGE = 'Celery';
const CRUSTACEAN_IMAGE = 'Crustacean';
const EGGS_IMAGE = 'Eggs';
const FISH_IMAGE = 'Fish';
const LUPIN_IMAGE = 'Lupin';
const MILK_IMAGE = 'Milk';
const MOLLUSCAN_IMAGE = 'Molluscan';
const MUSTARD_IMAGE = 'Mustard';
const WHEAT_IMAGE = 'Wheat';
const SESAME_IMAGE = 'Sesame';
const SOY_IMAGE = 'Soya';
const SULPHITES_IMAGE = 'Sulfites';
const PEANUTS_IMAGE = 'Peanuts';
const OTHER_NUTS_IMAGE = 'Other nuts';

export const allergensWithImages = [
    {
        id: 1,
        name: 'Milk',
        image: MILK_IMAGE,
    },
    {
        id: 2,
        name: 'Eggs',
        image: EGGS_IMAGE,
    },
    {
        id: 3,
        name: 'Fish',
        image: FISH_IMAGE,
    },
    {
        id: 4,
        name: 'Crustacean shellfish',
        image: CRUSTACEAN_IMAGE,
    },
    {
        id: 5,
        name: 'Tree nuts',
        image: OTHER_NUTS_IMAGE,
    },
    {
        id: 6,
        name: 'Peanuts',
        image: PEANUTS_IMAGE,
    },
    {
        id: 7,
        name: 'Wheat',
        image: WHEAT_IMAGE,
    },
    {
        id: 8,
        name: 'Soybeans',
        image: SOY_IMAGE,
    },
    {
        id: 9,
        name: 'Sulfites ≥10 mg/kg',
        image: SULPHITES_IMAGE,
    },
    {
        id: 10,
        name: 'Sesame',
        image: SESAME_IMAGE,
    },
    {
        id: 11,
        name: 'Cereals with gluten',
        image: WHEAT_IMAGE,
    },
    {
        id: 12,
        name: 'Molluscan shellfish',
        image: MOLLUSCAN_IMAGE,
    },
    {
        id: 13,
        name: 'Mustard',
        image: MUSTARD_IMAGE,
    },
    {
        id: 14,
        name: 'Bee pollen/propolis',
        image: '',
    },
    {
        id: 15,
        name: 'Royal jelly',
        image: '',
    },
    {
        id: 16,
        name: 'Celery',
        image: CELERY_IMAGE,
    },
    {
        id: 17,
        name: 'Lupin',
        image: LUPIN_IMAGE,
    },
];
