<template>
    <div
        class="uploading-wrapper"
        :class="{ 'block-page': blockPage, big: !smallLoader }"
    >
        <span class="loader"></span>
    </div>
</template>

<script>
export default {
    name: 'FdLoader',

    props: { blockPage: Boolean, smallLoader: Boolean },
};
</script>

<style lang="scss" scoped>
.uploading-wrapper {
    position: relative;

    &.big {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.block-page {
            position: fixed;
            z-index: 1055;
            width: 100%;
            height: 100%;

            &:before {
                display: block;
                width: 100%;
                height: 100%;
                content: '';
                background: rgba($white, 0.8);
            }
        }
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background: url('/public/img/loader.gif') no-repeat center;
    background-size: contain;
    transform: translate(-50%, -50%);
}
</style>
