import { vm } from '@main';

export default class ChatService {
    static isOpen = false;

    static openCallbacks = [];
    static closeCallbacks = [];

    static init() {
        window.HubSpotConversations?.widget?.load();
    }

    static open() {
        if (window.HubSpotConversations) {
            openChat();
        } else {
            window.hsConversationsOnReady = [
                () => {
                    openChat();
                },
            ];
        }

        ChatService.openCallbacks.forEach((i) => i());
    }

    static close() {
        ChatService.isOpen = false;

        ChatService.closeCallbacks.forEach((i) => i());
    }

    static refresh() {
        window.HubSpotConversations?.widget?.remove();

        if (ChatService.isOpen) {
            ChatService.open();
        }
    }

    static onOpen(callback) {
        ChatService.openCallbacks.push(callback);
    }

    static onClose(callback) {
        ChatService.closeCallbacks.push(callback);
    }
}

function openChat() {
    const query = vm.$route?.query || {};
    vm.$router.replace({
        query: {
            ...query,
            lang: vm.$i18n?.locale,
        },
    });

    const loaded = window.HubSpotConversations?.widget?.status()?.loaded;

    if (loaded) {
        window.HubSpotConversations?.widget?.open();
    } else {
        window.HubSpotConversations?.widget?.load({ widgetOpen: true });
    }

    ChatService.isOpen = true;
}
