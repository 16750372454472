<template>
    <li
        class="item"
        :class="{
            active: activeRoute,
            separator: value.separator,
            'space-related-out-y': hasNested,
            'content-separator-item': value.type === 'contentSeparator',
        }"
    >
        <div
            v-if="value.type === 'contentSeparator'"
            class="content-separator space-related-out-t"
        >
            <span class="content-separator-title info-text text-gray-600 letter-spacing-1-5">{{
                $t(value.title)
            }}</span>
        </div>
        <RouterLink
            v-else-if="value.to"
            :class="{
                'with-marker': hasMarker,
                active: activeRoute,
                clickable: !(disableClickable && value.children && value.children.length),
            }"
            :data-cy="value.to.name"
            :to="value.to"
            class="item-title"
            tag="a"
            @click="onClick"
        >
            <span
                v-if="hasMarker"
                class="item-marker"
            >
                <button
                    v-if="hasChildren"
                    class="btn border-0 py-0 icon-dark"
                    @click="toggle"
                >
                    <i :class="isOpen ? 'fd-chevron-down' : 'fd-chevron-right'"></i>
                </button>

                <slot
                    :index="index"
                    :value="value"
                    name="before-item"
                ></slot>
            </span>
            <span
                :class="{ 'single-title': !hasNested }"
                class="item-value d-inline-flex align-items-center"
            >
                {{ label(value) }}
                <slot
                    :index="index"
                    :value="value"
                    name="after-item"
                ></slot>
            </span>
        </RouterLink>
        <div
            v-else
            :class="{
                'with-marker': hasMarker,
                active: activeRoute,
                clickable: !(value.children && value.children.length),
            }"
            class="item-title"
        >
            <span
                v-if="hasMarker"
                class="item-marker"
            >
                <button
                    v-if="hasChildren"
                    class="btn btn-icon border-0 py-0"
                    @click="toggle"
                >
                    <i :class="isOpen ? 'fd-chevron-down' : 'fd-chevron-right'"></i>
                </button>

                <slot
                    name="before-item"
                    :value="value"
                    :index="index"
                ></slot>
            </span>
            <span
                v-else
                class="marker-placeholder"
            ></span>
            <span
                :class="{ 'single-title': !hasNested }"
                class="item-value top-level-title"
            >
                <span
                    class="cursor-pointer fs-sm"
                    @click="toggle"
                    >{{ label(value) }}</span
                >
                <slot
                    name="after-item"
                    :value="value"
                    :index="index"
                ></slot>
            </span>
        </div>

        <ul
            v-if="value.children && value.children.length && isOpen"
            :class="{
                'space-related-out-l': hasIndent,
            }"
        >
            <li
                v-for="(nestedItem, nestedIndex) in value.children"
                :key="nestedIndex"
                class="item"
            >
                <div
                    v-if="nestedItem.children"
                    class="nested-item inner"
                >
                    <SidemenuItem
                        :custom-label="customLabel"
                        has-nested
                        :value="nestedItem"
                        :index="customLabel(nestedItem)"
                        has-indent
                    />
                </div>
                <RouterLink
                    v-else-if="nestedItem.to"
                    :data-cy="nestedItem.to.name"
                    :to="nestedItem.to"
                    class="item-title clickable child-title d-flex"
                    tag="div"
                    @click="onClick"
                >
                    <div class="item-marker">
                        <slot
                            :index="nestedIndex"
                            :value="nestedItem"
                            name="before-item"
                        ></slot>
                    </div>
                    <div class="item-value child-item">
                        {{ label(nestedItem) }}
                    </div>
                    <span class="item-marker">
                        <slot
                            :index="nestedIndex"
                            :value="nestedItem"
                            name="after-item"
                        ></slot>
                    </span>
                </RouterLink>
                <div
                    v-else
                    :class="{ 'with-marker': hasMarker }"
                    class="item-title clickable child-title"
                >
                    <span class="item-marker">
                        <slot
                            :index="nestedIndex"
                            :value="nestedItem"
                            name="before-item"
                        ></slot>
                    </span>
                    <span class="item-value child-item">
                        <span>{{ label(nestedItem) }}</span>
                        <slot
                            :index="nestedIndex"
                            :value="nestedItem"
                            name="after-item"
                        ></slot>
                    </span>
                    <span class="item-marker">
                        <slot
                            :index="nestedIndex"
                            :value="nestedItem"
                            name="after-item"
                        ></slot>
                    </span>
                </div>
            </li>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'SidemenuItem',

    props: {
        value: {
            required: true,
            type: Object,
            validator(value) {
                // TODO
                return !!value;
            },
        },
        customLabel: {
            type: Function,
            default: (i) => i.title,
        },
        index: {
            required: true,
            type: [Number, String],
        },
        hasNested: Boolean,
        hasIndent: Boolean,
        disableClickable: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['click', 'toggle-visibility'],

    data() {
        return {
            isOpen: true,
        };
    },

    computed: {
        hasChildren() {
            return this?.value?.children?.length;
        },
        activeRoute() {
            return this.$route.name === this.value?.to?.name;
        },
        hasMarker() {
            return this.hasChildren || !!this.$scopedSlots['before-item'];
        },
    },

    methods: {
        label(value) {
            return this.$t(this.customLabel(value));
        },
        toggle() {
            this.isOpen = !this.isOpen;
            // couldn't do it better, component built badly
            setTimeout(() => this.$emit('toggle-visibility'), 100);
        },
        onClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss">
.content-separator-item {
    padding: 0 1rem !important;
}

.content-separator-title {
    padding: 0 10px !important;
    background: $gray-200 !important;
}
</style>

<style lang="scss" scoped>
.nested-item.inner {
    .item-title.with-marker {
        margin-left: 1.5rem !important;
    }

    .item-value.child-item {
        margin-left: 0.5rem !important;
    }
}

.marker-placeholder {
    margin-left: 2rem;
}

.router-link-exact-active,
.active {
    font-weight: 400 !important;
}

.single-title {
    @include media-breakpoint-down(md) {
        margin: 1rem 0 !important;
        font-size: 1.25rem !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 1.5rem !important;
        color: $gray-700 !important;
        letter-spacing: 0.15px;
        a {
            color: $gray-700 !important;
        }
    }
}

.item {
    width: 100%;
    padding: 0;
    margin: 0 !important;
    list-style-type: none;
    cursor: pointer;

    .item-title {
        align-items: center;
        padding: 0.5rem 0;
        font-size: 0.875rem;
        font-weight: 300;
        color: $gray-700;
        // todo this is a possibility to make them more distinguishable in Documents
        // border-top: 1px solid $gray-400;

        &.child-title {
            padding-left: 2rem;
            color: $gray-600;
            border-top: none;
        }

        &.with-marker {
            display: grid;
            grid-template-columns: minmax(auto, 30px) 1fr;
        }

        .item-marker {
            color: $gray-700;
            text-align: center;
        }

        & > .item-value {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.top-level-title {
                padding: 0 !important;
                white-space: normal;
            }

            @include media-breakpoint-down(md) {
                margin: 0 !important;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                &.child-item {
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-size: 0.8125rem !important;
                }
            }
        }

        button {
            padding: 0;
        }

        &.router-link-exact-active,
        &.active {
            color: $gray-700 !important;
            background-color: $gray-300;
            @include media-breakpoint-up(md) {
                border-right: 4px solid $green;
            }
        }

        &.clickable {
            &:hover {
                color: $gray-700;
                background-color: $gray-300;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 1rem 0;
        }
    }

    a {
        font-size: 0.875rem;
        text-decoration: none !important;

        &.router-link-exact-active,
        &.active {
            color: $black;
        }
    }

    .child-title {
        color: $gray-600 !important;
    }

    & > ul {
        padding: 0;
        margin: 0;

        a {
            color: $gray-600;
        }
    }

    &.separator:after {
        display: block;
        width: 90%;
        margin: 0 auto;
        content: '';
        border-bottom: 1px solid $gray-400;
    }
}

.content-separator {
    width: 100%;
    margin: 10px 0 20px;
    line-height: 0.1em;
    text-align: center;
    cursor: auto;
    border-bottom: 1px solid $gray-500;
}
</style>
