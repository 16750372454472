export default {
    path: 'users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users-module" */ './Index.vue'),
    children: [
        {
            path: 'new',
            name: 'users.new',
            component: () => import(/* webpackChunkName: "users-module" */ './panels/UserPanel.vue'),
        },
        {
            path: ':id',
            name: 'users.edit',
            component: () => import(/* webpackChunkName: "users-module" */ './panels/UserPanel.vue'),
        },
    ],
};
