const Billing = () => import(/* webpackChunkName: "billing-module" */ '@views/Billing');
const ChoosePayment = () => import(/* webpackChunkName: "billing-module" */ '@views/Billing/ChoosePayment/index');
const BillingSubscriptionOverview = () =>
    import(/* webpackChunkName: "billing-module" */ '@views/Billing/BillingSubscriptionOverview');
import DisabledForReadOnlyAndMonitoring from '@common/guards/disableForReadOnlyAndMonitoring.guard';
import RedirectRecurringCustomer from '@common/guards/redirectRecurringCustomer.guard';

export default [
    {
        path: '',
        component: Billing,
        beforeEnter: DisabledForReadOnlyAndMonitoring,
        children: [
            {
                path: '',
                name: 'billing',
                beforeEnter: RedirectRecurringCustomer,
                components: { billing: BillingSubscriptionOverview },
            },
            {
                path: 'choose-payment',
                components: { billing: ChoosePayment },
                name: 'billing.choose-payment',
            },
        ],
    },
];
