import store from '@store';

export default async (to, from, next) => {
    const onboardingProgress = store.getters.selectedCompany?.company?.onboarding_progress;

    if (
        !(to.query.fromWizard || localStorage.getItem('monitoring_onboarding_wizard_completed') === '1') &&
        onboardingProgress &&
        onboardingProgress[0]?.monitoring_onboarding_wizard_completed === null
    ) {
        next({ name: 'activity-log.intro' });
    } else {
        next();
    }
};
