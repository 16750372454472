<template>
    <DoubleConfirmationButton
        ref="double-confirm"
        :no-confirm="noConfirm"
        :order="order"
        @submit="$emit('delete')"
    >
        <template #button="{ click }">
            <div
                class="delete cursor-pointer d-none-for-print"
                :class="{ 'd-none': modalOnly, 'p-2': !label && !inputAppend, 'input-group-append': inputAppend }"
                @click.stop="click"
            >
                <button
                    v-if="isButton"
                    class="btn btn-link"
                >
                    <i class="icon-green fd-trash"></i>
                </button>
                <span
                    v-else-if="!modalOnly"
                    :class="{ 'input-group-text h-100': inputAppend }"
                    class="d-flex align-items-center"
                >
                    <i
                        v-if="!noIcon"
                        class="icon-green fd-trash"
                    ></i>
                    <span
                        v-if="label"
                        class="ml-2"
                        >{{ $t(label) }}</span
                    >
                </span>
            </div>
        </template>
    </DoubleConfirmationButton>
</template>

<script>
import DoubleConfirmationButton from '@components/DoubleConfirmationButton.vue';

export default {
    name: 'BaseDelete',

    components: {
        DoubleConfirmationButton,
    },

    props: {
        label: {
            type: String,
            default: '',
        },
        order: {
            type: Number,
            default: 1,
        },
        inputAppend: Boolean,
        noConfirm: Boolean,
        noIcon: Boolean,
        modalOnly: Boolean,
        isButton: Boolean,
    },

    emits: ['delete'],

    methods: {
        show() {
            this.$refs?.btn?.show();
        },
    },
};
</script>

<style lang="scss">
div.delete {
    display: inline-flex;
}
</style>
