import Vue from 'vue';

export default class Path {
    constructor() {
        this.source = {};
    }

    push(path, value) {
        if (!path.length) {
            throw new TypeError('Invalid path');
        }

        let source = this.source;
        for (const location of path.slice(0, -1)) {
            if (!source[location]) {
                Vue.set(source, location, {});
            }

            source = source[location];
        }

        Vue.set(source, path[path.length - 1], value);
    }

    get(path) {
        if (!Array.isArray(path)) {
            throw new TypeError('Path should be an array of strings!');
        }

        let obj = this.source;

        for (const key of path) {
            if (obj[key]) {
                obj = obj[key];
            } else {
                return null;
            }
        }

        return obj;
    }

    remove(path) {
        const obj = this.get(path.slice(0, -1));

        Vue.delete(obj, path[path.length - 1]);
    }
}
