// Mutations
export const RESTORE_PLACE = 'RESTORE_PLACE';

// Actions
export const UPDATE_PLACE_RELATED_DATA = 'UPDATE_PLACE_RELATED_DATA';
export const ADD_PLACE_INFO = 'ADD_PLACE_INFO';
export const START_PLACE_CREATION = 'START_PLACE_CREATION';
export const DUPLICATE_PLACE = 'DUPLICATE_PLACE';
export const REFRESH_LAST_VISIT = 'REFRESH_LAST_VISIT';
export const DELETE_PLACE = 'DELETE_PLACE';
export const RENAME_VTA_DOC = 'RENAME_VTA_DOC';

// Both
export const SET_PLACES = 'SET_PLACES';
export const SET_SELECTED_PLACE = 'SET_SELECTED_PLACE';
export const CREATE_PLACE = 'CREATE_PLACE';
export const UPDATE_PLACE = 'UPDATE_PLACE';
