<template>
    <div
        class="custom-control custom-radio"
        :class="{
            'custom-control-inline': inline,
            'is-invalid': validation && validation.$error,
            'space-related-out-b': hasSpaceBetween,
        }"
    >
        <input
            :id="id"
            v-model="valModel"
            class="custom-control-input"
            :class="{ 'is-invalid': invalid }"
            type="radio"
            :name="name"
            :value="val"
            :disabled="disabled"
            @click="toggleSelect"
        />
        <label
            class="custom-control-label"
            :for="id"
            >{{ $t(label) }}</label
        >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BaseRadio',

    props: {
        value: {
            type: [Boolean, String, Number, Object],
            default: '',
        },
        val: {
            type: [Boolean, String, Number, Object],
            default: '',
        },
        validation: {
            type: Object, // TODO: legacy
            nullable: true,
            default: null,
        },
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        disabled: Boolean,
        inline: Boolean,
        invalid: Boolean,
        hasSpaceBetween: Boolean,
        unselectable: Boolean,
    },

    emits: ['input'],

    computed: {
        valModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        toggleSelect() {
            if (this.unselectable && this.val?.id === this.valModel?.id) {
                this.valModel = null;
                this.$emit('input', null);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-control {
    font-size: 0.875rem;

    &.is-invalid {
        .custom-control-label {
            &::before {
                border-color: var(--danger);
            }
        }
    }
}

.custom-control-label {
    padding-top: 1px;
    line-height: 1.5;

    &:hover {
        cursor: pointer;
    }
}

.custom-control-label::before {
    background-color: $gray-300;
    border-color: $gray-300;
}
</style>
