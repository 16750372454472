import { vm } from '@main';
import authService from '@services/auth.service';
import { applyAllRequestsFileMiddleware } from '@services/http/middleware/file-request';
import store from '@store';
import { STOP_VIEW_LOADER } from '@store/modules/loader/constants';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import Vue from 'vue';

const defaultStrategy = authTokenStrategy;

class HttpService {
    constructor() {
        const ignorePlaceErrors = [
            'No query results for model [App\\Place]',
            'No query results for model [App\\FileV2]',
            'No query results for model [App\\BusinessActivity]',
        ];
        const ignoreErrors = [];

        this.generateCancelToken();

        if (HttpService.instance) {
            return HttpService.instance;
        }

        axios.interceptors.request.use(
            (config) => {
                if (!config.cancelToken) {
                    if (!(config.method === 'get' && config.url.includes('api/lang'))) {
                        config.cancelToken = this.cancelToken;
                    }
                }

                return applyAllRequestsFileMiddleware(config);
            },
            (error) => {
                store.commit(STOP_VIEW_LOADER);
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                if (response && [201, 200].includes(response.status)) {
                    return response;
                }
                return Promise.reject(response);
            },
            (error) => {
                const conf = cloneDeep(error.config);
                if (
                    process.env.NODE_ENV !== 'production' &&
                    process.env.IGNORE_MOCK_API != null &&
                    process.env.IGNORE_MOCK_API !== '' &&
                    process.env.IGNORE_MOCK_API !== 'true'
                ) {
                    const urls = process.env.MOCK_API_URLS?.split(' ');
                    if (urls?.length) {
                        if (conf.baseURL === process.env.API_URL) {
                            conf.baseURL = urls[0];
                            return axios.request(conf);
                        } else {
                            const index = urls.indexOf(conf.baseURL);
                            if (index !== urls.length - 1) {
                                conf.baseURL = urls[index + 1];
                                return axios.request(conf);
                            }
                        }
                    }
                }
                if (error?.message?.config?.cancelToken?.reason) {
                    // Incase of cancel request just skip it
                    return Promise.reject({ reason: 'cancelled' });
                }

                if (error.response) {
                    let errorData;
                    try {
                        errorData = JSON.parse(Buffer.from(error.response.data).toString('utf8'));
                    } catch {
                        errorData = error.response.data;
                    }
                    let showError = true;
                    let returnPromise = true;
                    switch (error.response.status) {
                        case 401: {
                            return this._handleUnauthorised(error);
                        }
                        case 402: {
                            showError = false;
                            break;
                        }
                        case 413: {
                            // TODO: Temp message it should be caught before send a request
                            Vue.prototype.$toastr.e('Attachment is too large');
                            break;
                        }
                        case 429: {
                            if (Vue.rollbar) {
                                Vue.rollbar.error(error);
                            }
                            this.cancelRequests(error.response);
                            authService.logout();
                            break;
                        }
                        case 403: {
                            vm.$toastr.e(vm.$t('You are not authorized to do that'));
                            return;
                        }
                        case 404:
                        case 422: {
                            // Fast solution for solving the problem of unwanted error messages
                            if (ignorePlaceErrors.find((ie) => errorData?.message?.includes(ie))) {
                                showError = false;
                                authService.setCompanyPlaceOrCreateNew();
                            } else if (ignoreErrors.find((ie) => errorData?.message?.includes(ie))) {
                                showError = false;
                            }
                            // Just skip Rollbar report
                            console.error(errorData?.message || errorData?.status || error);
                            break;
                        }
                        case 400: {
                            return Promise.reject(error);
                        }
                        case 500: {
                            showError = true;
                            returnPromise = false;
                            break;
                        }
                        default: {
                            if (error.response.message === 'Network Error') {
                                Vue.prototype.$toastr.e(error.message);
                                store.commit(STOP_VIEW_LOADER);
                                return Promise.reject(error);
                            }
                            break;
                        }
                    }

                    if (showError && !this.ignoreErrors) {
                        if (errorData) {
                            if (errorData.errors) {
                                Object.keys(errorData.errors).forEach((key) => {
                                    Vue.prototype.$toastr.e(errorData.errors[key]);
                                });
                            } else if (errorData.message) {
                                Vue.prototype.$toastr.e(errorData.message);
                            } else if (errorData.status) {
                                Vue.prototype.$toastr.e(errorData.status);
                            } else {
                                Vue.prototype.$toastr.e(Vue.prototype.$t('error'));
                            }
                        } else if (error.response.message) {
                            Vue.prototype.$toastr.e(error.response.message);
                        } else {
                            Vue.prototype.$toastr?.e(error);
                        }
                    }
                    if (returnPromise) {
                        return Promise.reject(error);
                    }
                } else {
                    return Promise.reject(error);
                }
            }
        );

        this.setDefaultStrategy();

        this.setToken();

        HttpService.instance = this;
    }

    get cancelSource() {
        return this._cancelSource;
    }

    get cancelToken() {
        return this.cancelSource.token;
    }

    setDefaultStrategy() {
        delete axios.defaults.headers.common['Shared-Access-Token'];
        this.tokenStrategy = defaultStrategy;
    }

    generateCancelToken() {
        const CancelToken = axios.CancelToken;
        this._cancelSource = CancelToken.source();
    }

    cancelRequests(message) {
        this.cancelSource.cancel(message);
        this.generateCancelToken();
    }

    setToken(value) {
        try {
            this.tokenStrategy(value);
        } catch (e) {
            console.error('Not set strategy', e);
        }
    }

    get(url, options = {}, ignoreErrors = false) {
        const cancelTokenSource = axios.CancelToken.source();
        this.ignoreErrors = ignoreErrors;
        const req = axios.get(url, {
            cancelToken: cancelTokenSource.token,
            ...options,
        });

        req.cancelRequest = (msg) => cancelTokenSource.cancel(msg);

        return req;
    }

    post(url, body, options = {}) {
        return axios.post(url, body, options);
    }

    put(url, body, options = {}) {
        return axios.put(url, body, options);
    }

    delete(url, options = {}) {
        return axios.delete(url, options);
    }

    download(url) {
        return axios.get(url, { responseType: 'arraybuffer' });
    }

    async _handleUnauthorised(error) {
        this.cancelRequests(error.response);
        if (store.getters['shared-access/isSharedAccess']) {
            store.commit('shared-access/expired');
        } else {
            authService.logout({ skipRequest: true });
        }
        return Promise.reject(error);
    }
}

export default new HttpService();

export function preventMultiple(request) {
    return (...data) => {
        if (!request.target || request.target !== data.toString()) {
            request.target = data.toString();
            request.request = request(...data);
            request.request.finally(() => {
                delete request.target;
                delete request.request;
            });

            return request.request;
        } else if (request.request) {
            return request.request;
        }
    };
}

export function authTokenStrategy(token) {
    const val = token || localStorage.getItem('access_token');

    axios.defaults.headers.common.Authorization = `Bearer ${val}`;
}

export function sharedAccessTokenStrategy(token) {
    const val = token || localStorage.getItem('shared_access_token');

    axios.defaults.headers.common['Shared-Access-Token'] = `${val}`;
}
