import { DateValue } from '@common/types/DateValue';
import { TimeUnit } from '@common/types/TimeUnit';

export class Frequency {
    public id?: number;
    public name: string;
    public taskSubtypeId: number;
    public taskFrequencyCnt: number;
    public timeUnitCnt: number;
    public timeUnit?: TimeUnit;

    constructor(data?: any) {
        this.id = data?.id;
        this.name = data?.name;
        this.taskSubtypeId = data?.taskSubtypeId;
        this.taskFrequencyCnt = data?.taskFrequencyCnt;
        this.timeUnitCnt = data?.timeUnitCnt;
        this.timeUnit = data?.timeUnit;
    }

    static fromJSON(json: any) {
        return new Frequency({
            id: json?.id,
            name: json?.name,
            taskSubtypeId: json?.task_subtype_id,
            taskFrequencyCnt: json?.task_frequency_cnt,
            timeUnitCnt: json?.time_unit_cnt,
            timeUnit: json?.time_unit ? TimeUnit.fromJSON(json?.time_unit) : undefined,
        });
    }

    toJSON() {
        const json = {
            name: this.name,
            task_subtype_id: this.taskSubtypeId,
            task_frequency_cnt: this.taskFrequencyCnt,
            time_unit_cnt: this.timeUnitCnt,
        };

        this.id ? (json['id'] = this.id) : null;

        return json;
    }
}

export class CustomFrequency {
    repeatEveryUnitId?: number;
    repeatEvery?: number;
    times?: [];
    weekdays?: [];
    startDateTime?: DateValue;
    repeatOn?: any | null; // todo idk about this

    static fromJSON(json) {
        return {
            repeatEveryUnitId: json?.value_type_id || null,
            repeatEvery: json?.task_frequency_cnt || 1,
            times: json?.times || [],
            weekDays: json?.days_of_week || [],
            startDateTime: DateValue.fromRequestDateTime(json?.start_time),
            repeatOn: json?.repeat_on || null,
        };
    }

    toJSON() {
        return {
            custom_frequency_type_id: this.repeatEveryUnitId,
            task_frequency_cnt: this.repeatEvery,
            times: this.times || [],
            days_of_week: this.weekdays || [],
            start_time: this.startDateTime?.startOf('day').requestDateTimeFormat,
            repeat_on: this.repeatOn || null,
        };
    }
}
