import { vm } from '@main';

import http from '@http';

import { DateValue } from '@common/types';

import store from '@store';

const state = {
    pausePeriods: [],
    selected_place_id: +localStorage.getItem('selected_place_id') || null,
};

const mutations = {
    updatePeriods(state, data) {
        state.pausePeriods = data;
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ dispatch }) {
            if (!store.getters['shared-access/isSharedAccess']) {
                dispatch('getSelectedPlacePausePeriods');
            }
        },
    },

    async getSelectedPlacePausePeriods({ rootGetters, commit }) {
        try {
            if (!rootGetters.selectedPlaceId) {
                return;
            }
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods`);

            let periods = data?.map((period) => ({
                ...period,
                startDate: DateValue.fromRequestDate(period.start_date),
                endDate: DateValue.fromRequestDate(period.end_date),
                editing: false,
            }));
            commit('updatePeriods', periods);
            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async updatePausePeriodDetails({ rootGetters, commit, dispatch }, period) {
        try {
            const payload = {
                name: period.name,
                start_date: period.startDate.requestDateFormat,
                end_date: period.endDate.requestDateFormat,
            };
            const { data } = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods/${period.id}`,
                payload
            );

            commit('updatePeriods', { data });
            dispatch('getSelectedPlacePausePeriods');
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async getPeriodDetails({ rootGetters }, periodId) {
        try {
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods/${periodId}`
            );
            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async deletePausePeriod({ rootGetters }, periodId) {
        try {
            return await http.delete(`/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods/${periodId}`);
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async addPausePeriod({ rootGetters, dispatch }, period) {
        try {
            const payload = {
                name: period.pauseName,
                start_date: period.startDate.requestDateFormat,
                end_date: period.endDate.requestDateFormat,
            };

            const { data } = await http.post(
                `/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods`,
                payload
            );

            dispatch('getSelectedPlacePausePeriods');
            return {
                ...data,
                startDate: DateValue.fromRequestDate(data.start_date),
                endDate: DateValue.fromRequestDate(data.end_date),
                editing: false,
            };
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },
};

const getters = {
    getSelectedPlacePausePeriods: (state) => {
        return state.pausePeriods;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
