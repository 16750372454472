import { UPDATE_COMPANY } from '@store/modules/company/constants';
import { ADD_PLACE_INFO } from '@store/modules/company/place/constants';

function state() {}

const getters = {};

const mutations = {};

const actions = {
    async update({ dispatch }, { place, company }) {
        // TODO: do not manage API data structure inside component
        if (company) {
            await dispatch(UPDATE_COMPANY, { companyId: company.id, data: company }, { root: true });
        }

        const data = {
            placeData: place,
        };
        await dispatch(ADD_PLACE_INFO, { placeId: place.id, data }, { root: true });

        await dispatch('plan/getStates', null, { root: true });

        return;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
