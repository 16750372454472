var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center",class:{
        'w-100': _vm.fullWidth,
        'h-100': _vm.fullHeight,
        'd-flex': _vm.imageCenter,
    }},[(_vm.url.length)?[(_vm.isImage)?_c('img',{staticClass:"img-fluid",class:{
                'm-auto': _vm.imageCenter,
                [_vm.imgClass]: !!_vm.imgClass,
            },attrs:{"src":_vm.url,"alt":_vm.file.name || _vm.file.title || _vm.file.fileName || _vm.$t('name not found')}}):_c('iframe',{staticStyle:{"min-height":"500px"},attrs:{"src":_vm.url,"width":"100%","height":"100%"}})]:_c('Loader')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }