<template>
    <div
        v-if="showBanner"
        class="float-bottom-mobile"
    >
        <div class="download-notification shadow w-100 space-related-in font-size-xs">
            <div class="mb-2 d-flex align-items-center justify-content-between">
                <button class="btn btn-icon" />
                <div class="text-center">
                    {{ $t('FoodDocs is even better in the mobile app') }}
                </div>
                <button
                    class="btn btn-icon"
                    @click="dismiss"
                >
                    <i class="fd-close icon-dark"></i>
                </button>
            </div>

            <div class="mb-2 w-100 d-flex justify-content-center align-items-center">
                <img
                    :src="image"
                    :alt="image"
                    height="60"
                    @click="openStore"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileDownloadNotification',

    emits: ['dismiss'],

    data() {
        return {
            show: true,
        };
    },

    computed: {
        userAgent() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/windows phone/i.test(userAgent)) {
                return 'windows';
            }

            if (/android/i.test(userAgent)) {
                return 'android';
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return 'ios';
            }
            return '';
        },
        image() {
            switch (this.userAgent) {
                case 'ios':
                    return '/img/fooddocs_app_store.svg';
                case 'android':
                    return '/img/fooddocs_play_store.svg';
                default:
                    return '';
            }
        },
        link() {
            switch (this.userAgent) {
                case 'ios':
                    return 'https://apps.apple.com/us/app/fooddocs-food-safety-system/id1487448481';
                case 'android':
                    return 'https://play.google.com/store/apps/details?id=eu.fooddocs.operator&hl=en&gl=US';
                default:
                    return '';
            }
        },
        showBanner() {
            return ['ios', 'android'].includes(this.userAgent) && !localStorage.getItem('mobile-banner') && this.show;
        },
    },

    methods: {
        openStore() {
            window.open(this.link, '_blank');
        },
        dismiss() {
            localStorage.setItem('mobile-banner', 'true');
            this.show = false;
        },
    },
};
</script>
