<template>
    <div :class="{ 'grid-template-with-label': !ignoreFirst }">
        <component
            :is="firstTag"
            v-if="!ignoreFirst"
        >
            <slot name="first"></slot>
        </component>
        <div class="bottom-row align-self-center">
            <slot name="second"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TwoRow',

    props: {
        ignoreFirst: Boolean,
        firstTag: {
            type: String,
            default: 'div',
        },
    },
};
</script>
