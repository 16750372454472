import http from '@http';

class ProfileService {
    constructor() {
        if (ProfileService.instance) {
            return ProfileService.instance;
        }

        ProfileService.instance = this;
    }

    getProfile() {
        return http.get('/api/profile').then((res) => {
            return res.data;
        });
    }
}

export default new ProfileService();
