export class TimeUnit {
    id: number;
    name: string;
    orderNumber: number;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.orderNumber = data.orderNumber;
    }

    static fromJSON(json: any) {
        if (!json) {
            throw new Error('Time unit JSON should not be empty!');
        }
        return new TimeUnit({
            id: json.id,
            name: json.name,
            orderNumber: json.order_number,
        });
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            order_number: this.orderNumber,
        };
    }

    toString() {
        switch (this.id) {
            case 7:
                return 'year';
            case 6:
                return 'months';
            case 5:
                return 'weeks';
            case 4:
                return 'days';
            default:
                return 0;
        }
    }
}
