import http from '@http';

import { DateValue } from '@common/types';

function state() {
    return {
        showHeader: true,
        headerConfigured: false,
        globalCommons: {
            measurementSystems: [],
            tempScales: [],
        },
        mobileHeaderOpen: false,
        mobileSubHeaderOpen: false,
        sideNavOpen: true,
    };
}

const mutations = {
    setSideNavOpen(state, boolean) {
        state.sideNavOpen = boolean;
    },
    setShowHeader(state, boolean) {
        state.showHeader = boolean;
        state.headerConfigured = true;
    },
    setMeasurementSystems(state, data) {
        state.globalCommons.measurementSystems = data;
    },
    setTempSystems(state, data) {
        state.globalCommons.tempScales = data;
    },
    setMobileHeaderState(state, data) {
        state.mobileHeaderOpen = data;
    },
    setMobileSubHeaderOpen(state, data) {
        state.mobileSubHeaderOpen = data;
    },
};

const actions = {
    fetchAllGlobalCommons({ dispatch }) {
        dispatch('fetchMeasurementSystems');
        dispatch('fetchTempScales');
    },
    fetchMeasurementSystems({ commit }) {
        http.get('/api/classifiers/measurement-systems').then(({ data }) => {
            commit('setMeasurementSystems', data);
        });
    },
    fetchTempScales({ commit }) {
        http.get('/api/classifiers/temperature-scales').then(({ data }) => {
            commit('setTempSystems', data);
        });
    },
};

const getters = {
    subscriptionRenewalDifferenceInDays: (state, getters, rootState, rootGetters) => {
        const subscription = rootGetters.selectedCompany?.company?.subscription;
        const validTo = DateValue.fromRequestDateTime(subscription.renewal_valid_to);
        const today = new DateValue();
        const differenceInTime = validTo.diffInDays(today);
        return differenceInTime > 0 ? differenceInTime : 0;
    },
    isSubscriptionOngoing: (getters) => {
        return getters.subscriptionRenewalDifferenceInDays > 0;
    },
    isTrialOngoing: (state, getters, rootState, rootGetters) => {
        if (getters.isSubscriptionOngoing) {
            return false;
        }

        const selectedCompany = rootGetters.selectedCompany?.company;
        return selectedCompany?.trial_end_date;
    },
    hasTrialEnded: (state, getters, rootState, rootGetters) => {
        const selectedCompany = rootGetters.selectedCompany?.company;
        return selectedCompany?.trial_end_date && !selectedCompany?.subscription;
    },
    sideNavOpen: (state) => state.sideNavOpen,
    showHeader: (state) => state.showHeader,
    mobileHeaderOpen: (state) => state.mobileHeaderOpen,
    mobileSubHeaderOpen: (state) => state.mobileSubHeaderOpen,
    headerConfigured: (state) => state.headerConfigured,
    tempScales: (state) => state.globalCommons.tempScales,
    getTempScaleById: (state, getters) => (id) => {
        return getters.tempScales.find((tmp) => tmp.id === id);
    },
    measurementSystems: (state) => state.globalCommons.measurementSystems,
    metricUnits: (state) => {
        const system = state.globalCommons.measurementSystems.filter((s) => s.name === 'metric');
        if (system?.length && system[0]?.units?.length) {
            return system[0].units;
        }
        return [];
    },
    allMeasurements: (state) => {
        const system = state.globalCommons.measurementSystems;
        if (system.length) {
            return system.map((s) => s.units).flat() || [];
        }
        return [];
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
