import { TaskEntry } from '@common/types/TaskEntry';

export class Worker {
    public id?: number;
    public name: string;
    public email: number;
    public accessLevel: number | string;
    public phone: number;
    public position: number;
    public idCode: number;
    public isDisabled: boolean;
    public stopNotificationCert: boolean;
    public stopNotificationTraining: boolean;
    public groups: number[];
    public teamTaskEntries: TaskEntry[];
    public userId?: number;
    public placeId?: number;

    constructor(data?: any) {
        this.id = data ? data.id : null;
        this.name = data ? data.name : '';
        this.email = data ? data.email : '';
        this.accessLevel = data ? data.accessLevel : null;
        this.phone = data ? data.phone : null;
        this.position = data ? data.position : null;
        this.idCode = data ? data.idCode : null;
        this.isDisabled = !!data?.isDisabled;
        this.stopNotificationCert = data?.stopNotificationCert;
        this.stopNotificationTraining = data?.stopNotificationTraining;
        this.groups = data ? data.groups : [];
        this.teamTaskEntries = data ? data.teamTaskEntries : [];
        this.userId = data ? data.userId : null;
        this.placeId = data ? data.placeId : null;
    }

    static fromJSON(json: any) {
        return new Worker({
            id: json.id,
            name: json.name,
            email: json.email,
            accessLevel: json.access_level,
            phone: json.phone,
            position: json.position,
            idCode: json.idcode,
            isDisabled: json.is_disabled,
            stopNotificationCert: json.stop_notification_cert,
            stopNotificationTraining: json.stop_notification_training,
            groups: json.groups?.map((group) => group.id),
            teamTaskEntries: json.team_task_entries?.map((entry) => TaskEntry.fromJSON(entry)),
            userId: json.user_id,
        });
    }

    toJSON() {
        const json = {
            name: this.name,
            email: this.email,
            access_level: this.accessLevel,
            phone: this.phone,
            position: this.position,
            idcode: this.idCode,
            is_disabled: this.isDisabled,
            stop_notification_cert: this.stopNotificationCert,
            stop_notification_training: this.stopNotificationTraining,
            groups: this.groups,
            team_task_entries: this.teamTaskEntries?.map((entry) => entry.toJSON()) || [],
        };

        this.id ? (json['id'] = this.id) : null;
        this.userId ? (json['user_id'] = this.userId) : null;
        this.placeId ? (json['place_id'] = this.placeId) : null;

        return json;
    }
}
