import Vue from 'vue';

import http from '@http';

import { DateValue } from '@common/types';

function state() {
    return {
        chartData: { reset: true },
        mode: 'week',
        chartDate: null,
        loader: false,
    };
}

let request = null;
const getters = {
    chartData: (state) => state.chartData,
    chartMode: (state) => state.mode,
    chartDate: (state) => state.chartDate || new DateValue(),
    loader: (state, getters, rootState, rootGetters) => state.loader && !rootGetters.isViewLoading,
};

const mutations = {
    reset(state) {
        Vue.set(state, 'chartData', { reset: true });
    },
    setChartData(state, payload) {
        state.chartData = {};
        payload.forEach((index) => {
            Vue.set(state.chartData, index.date, {
                due: parseInt(index.due) + parseInt(index.overdue),
                ok: index.ok,
                paused: index.pauseMonitoring,
            });
        });
    },
    setChartMode(state, payload) {
        state.mode = payload;
    },
    setChartDate(state, payload) {
        state.chartDate = payload;
    },
    setLoader(state, payload) {
        state.loader = payload;
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async init({ dispatch }, { date, groupId, mode }) {
        try {
            date && dispatch('setChartDate', { date });
            mode && dispatch('setChartMode', { mode });
            if (groupId) {
                await dispatch('getStatistics', { groupId });
            } else {
                await dispatch('getStatistics');
            }
        } catch (e) {
            console.error(e);
        }
    },
    /**
     * Get statistic for chart
     * @param {Object} payload.
     * @param {number} payload.groupId - Group id.
     * @param {Date} payload.entry.startDate
     * @param {Date} payload.entry.endDate
     */
    async getStatistics({ state, rootGetters, commit, dispatch }, payload) {
        try {
            const todaysDate = new DateValue(state.chartDate);
            let startDate, endDate;
            if (state.mode === 'week') {
                startDate = todaysDate.startOf('isoWeek');
                endDate = todaysDate.endOf('isoWeek');
            } else if (state.mode === 'month') {
                startDate = todaysDate.startOf('month');
                endDate = todaysDate.endOf('month');
            }

            let url = `/api/places/${rootGetters.selectedPlaceId}/activity-log/statistics`;

            let query = '';
            if (startDate && endDate) {
                query = `?startDate=${startDate.requestDateFormat}&endDate=${endDate.requestDateFormat}`;
            }

            if (payload?.groupId) {
                if (query) {
                    query += `&taskSubtypeId=${payload.groupId}`;
                } else {
                    query += `?taskSubtypeId=${payload.groupId}`;
                }
            }

            commit('setLoader', true);

            await request?.cancelRequest('Cancel previous');

            request = http.get(`${url}${query}`);
            const res = await request;
            const pauseDates = await dispatch('getMonitoringPeriodInDates', { startDate, endDate });

            if (pauseDates) {
                await res.data.forEach((date) => {
                    date.pauseMonitoring = null;
                    pauseDates.forEach((pause) => {
                        if (date.date >= pause?.start_date && date.date <= pause.end_date) {
                            date.pauseMonitoring = pause;
                        }
                    });
                });
            }

            commit('setChartData', res.data);

            commit('setLoader', false);
        } catch (error) {
            if (!error.__CANCEL__) {
                console.error(error);
                commit('setLoader', false);
            }
        }
    },
    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    async updateStatistic({ dispatch }, payload) {
        try {
            dispatch('getStatistics', payload);
        } catch (error) {
            console.error(error);
        }
    },
    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    setChartMode({ commit, state }, payload) {
        if (state.mode !== payload.mode) {
            commit('setChartMode', payload.mode);
        }
    },
    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    setChartDate({ commit }, payload) {
        commit('setChartDate', payload.date);
    },

    async getMonitoringPeriodInDates({ rootGetters }, payload) {
        const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods`, {
            query: {
                fromDate: payload.startDate.requestDateFormat,
                toDate: payload.endDate.requestDateFormat,
            },
        });
        return data;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
