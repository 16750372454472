import { DateValue } from '@common/types';

export interface ISharedItem {
    name: string;
    email: string;
    modules: Array<string>;
    validUntil: DateValue;
    createdAt: DateValue;
    token?: string;
    lang?: string;
}

export class SharedItem implements ISharedItem {
    name = '';
    email = '';
    modules = new Array<string>();
    validUntil = new DateValue();
    createdAt = new DateValue();
    token;
    lang;

    constructor(data: ISharedItem) {
        this.name = data.name;
        this.email = data.email;
        this.modules = data.modules;
        this.createdAt = data.createdAt;
        this.validUntil = data.validUntil;
        this.token = data.token;
        this.lang = data.lang;
    }

    toJSON() {
        return {
            name: this.name,
            email: this.email,
            valid_date: this.validUntil.requestDateFormat,
            modules: this.modules,
        };
    }

    static fromJSON(json: any): SharedItem {
        return new SharedItem({
            name: json.name,
            email: json.email,
            modules: json.modules,
            token: json.token,
            createdAt: DateValue.fromRequestDateTime(json.created_at),
            validUntil: DateValue.fromRequestDateTime(json.valid_date),
            lang: json.lang,
        });
    }
}
