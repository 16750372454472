import users from '@views/Users/routes';
import token from '@views/SetUp/token/routes';
import places from '@views/SetUp/Location/routes';
import companies from '@views/SetUp/Company/routes';

export default [
    {
        path: '',
        name: 'set-up',
        component: () => import(/* webpackChunkName: "set-up-module" */ './Index.vue'),
        redirect: {
            name: 'users',
        },
        children: [
            users,
            token,
            places,
            companies,
            {
                path: 'welcome',
                name: 'set-up.welcome',
                component: () => import('./Welcome.vue'),
            },
        ],
    },
];
