import { vm } from '@main';
import moment, { Moment } from 'moment';
import StartOf = moment.unitOfTime.StartOf;

const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
const REQUEST_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const REQUEST_DATE_TIME_NOSECONDS_FORMAT = 'YYYY-MM-DD HH:mm';
const FALLBACK_DATE_FORMAT = REQUEST_DATE_FORMAT;

// important thing here is that make sure that user will not destroy time zone value for example in case when he will get it from URL query or something
export class DateValue {
    private date: Moment;

    constructor(date?: DateValue | Moment | Date) {
        if (date instanceof DateValue) {
            this.date = date.isValid ? date.momentValue.clone() : moment(new Date());
            return;
        }

        const dateMoment = moment(date);
        this.date = dateMoment.isValid() ? dateMoment.clone() : moment(new Date());
    }

    get momentValue(): Moment {
        return this.date;
    }

    set momentValue(value: Moment) {
        this.date = value;
    }

    get dateValue(): Date {
        return this.date.toDate();
    }

    set dateValue(date: Date) {
        this.date = moment(date);
    }

    get isValid(): boolean {
        return this.date.isValid();
    }

    /**
     * FORMATS
     */
    get dateFormat() {
        return this.format(DateValue.getCompanyDateFormat());
    }

    get dateTimeFormat() {
        const format = DateValue.getCompanyDateFormat();
        return this.format(`${format} HH:mm`);
    }

    get timeFormat() {
        return this.date.format(`HH:mm`);
    }

    get dateDayTimeFormat() {
        return this.format(`dddd, ${DateValue.getCompanyDateFormat()}`);
    }

    get requestDateFormat() {
        return this.format(REQUEST_DATE_FORMAT);
    }

    get requestDateTimeFormat() {
        return this.format(REQUEST_DATE_TIME_FORMAT);
    }

    get requestDateTimeFormatNoSeconds() {
        return this.format(REQUEST_DATE_TIME_NOSECONDS_FORMAT);
    }

    get urlFormat() {
        return this.dateValue.getTime();
    }

    static daysTranslationKeysLetters(): string[] {
        // vm.$t();
        const monday = vm.$t('Mo');
        const tuesday = vm.$t('Tu');
        const wednesday = vm.$t('We');
        const thursday = vm.$t('Th');
        const friday = vm.$t('Fr');
        const saturday = vm.$t('Sa');
        const sunday = vm.$t('Su');
        return [monday, tuesday, wednesday, thursday, friday, saturday, sunday];
    }

    static monthsTranslationKeys(): string[] {
        const january = vm.$t('January');
        const february = vm.$t('February');
        const march = vm.$t('March');
        const april = vm.$t('April');
        const may = vm.$t('May');
        const june = vm.$t('June');
        const july = vm.$t('July');
        const august = vm.$t('August');
        const september = vm.$t('September');
        const october = vm.$t('October');
        const november = vm.$t('November');
        const december = vm.$t('December');
        return [january, february, march, april, may, june, july, august, september, october, november, december];
    }

    format(formatStr: string): string {
        return this.date.format(formatStr);
    }

    static fromRequestDate(str: string): DateValue {
        const momentValue = moment(str, REQUEST_DATE_FORMAT);
        if (!momentValue.isValid()) {
            return DateValue.fromRequestDateTime(str);
        }
        return new DateValue(momentValue.toDate());
    }

    static fromRequestDateTime(str: string): DateValue {
        return new DateValue(moment(str, REQUEST_DATE_TIME_FORMAT).toDate());
    }

    static fromFormattedDate(str: string): DateValue {
        const format = DateValue.getCompanyDateFormat();
        return new DateValue(moment(str, format).toDate());
    }

    static fromFormattedDateTime(str: string): DateValue {
        const format = DateValue.getCompanyDateFormat() + ' hh:mm';
        return new DateValue(moment(str, format));
    }

    static getCompanyDateFormat(): string {
        return vm?.$store?.getters?.selectedCompanyDateFormat || FALLBACK_DATE_FORMAT;
    }

    datePartEqualTo(dateTime?: DateValue | Moment | Date): boolean {
        const dateTimeDateValue = new DateValue(dateTime);
        return this.momentValue.diff(dateTimeDateValue.momentValue, 'days') === 0;
    }

    isInRangeOf(currentDate?: DateValue | Moment | Date, mode?: String): boolean {
        if (!currentDate || currentDate == new Date(0)) {
            return true;
        }

        const current: Moment = new DateValue(currentDate).date;
        let start: Moment, end: Moment;

        switch (mode) {
            case 'week': {
                start = current.clone().startOf('isoWeek');
                end = current.clone().endOf('isoWeek');
                break;
            }
            case 'month': {
                start = current.clone().startOf('month');
                end = current.clone().endOf('month');
                break;
            }
            default: {
                return false;
            }
        }

        return this.date.isSameOrAfter(start) && this.date.isSameOrBefore(end);
    }
    /**
     * Unlike moment.startOf, this does not mutate the original value
     * @param unit
     * @returns
     */
    startOf(unit: StartOf | string): DateValue {
        return new DateValue(this.momentValue.clone().startOf(typeof unit === 'string' ? (unit as StartOf) : unit));
    }
    /**
     * Unlike moment.endOf, this does not mutate the original value
     * @param unit
     * @returns
     */
    endOf(unit: StartOf): DateValue {
        return new DateValue(this.momentValue.clone().endOf(unit));
    }

    isBefore(date: DateValue): boolean {
        return this.momentValue.isBefore(date.momentValue);
    }

    isAfter(date: DateValue): boolean {
        return this.momentValue.isAfter(date.momentValue);
    }

    isBeforeDate(dateTime: DateValue): boolean {
        return this.momentValue.isBefore(dateTime.momentValue, 'day');
    }

    isAfterDate(dateTime: DateValue): boolean {
        return this.momentValue.isAfter(dateTime.momentValue, 'day');
    }

    diffInDays(date: DateValue | null): number | undefined {
        return this.diffWrapper(date, 'days');
    }
    diffInWeeks(date: DateValue | null): number | undefined {
        return this.diffWrapper(date, 'weeks');
    }

    private diffWrapper(date: DateValue | null, unitOfTime) {
        if (!date) {
            return;
        }
        const targetDate = date.startOf('day');
        const diff = this.startOf('day').momentValue.diff(targetDate.momentValue, unitOfTime);
        return diff;
    }
}
