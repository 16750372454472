import { vm } from '@main';

import routes from '@routes';

import http from '@http';
import TrackingService from '@services/tracking.service';

import store from '@store';
import { SET_SELECTED_COMPANY, START_COMPANY_CREATION } from '@store/modules/company/constants';
import { SET_SELECTED_PLACE, START_PLACE_CREATION } from '@store/modules/company/place/constants';
import { INIT_USER_PROFILE } from '@store/modules/profile/constants';

class AuthService {
    constructor() {
        if (AuthService.instance) {
            return AuthService.instance;
        }

        AuthService.instance = this;
    }

    get isAuthorized() {
        return !!localStorage.getItem('access_token');
    }

    get isSharedLink() {
        return this._isSharedLink;
    }

    activateSharedLink() {
        this._isSharedLink = true;
    }

    hasEmail() {
        return (
            store.getters.userProfile.username &&
            store.getters.userProfile.username.length > 3 &&
            !store.getters.userProfile.username.includes('mail.fooddocs.ee')
        );
    }

    async _postAuthCallback() {
        localStorage.setItem('hideBillingDaysLeftNotification', JSON.stringify({ value: 'false' }));
        if (process.env.APP_ENV === 'production') {
            TrackingService.login(store.getters.profileId);
        }
        await store.dispatch('initApp', null, { root: true });
    }

    finishSmartidLogin(code) {
        return http
            .post(`/api/finish_smartid_login?response_type=token&client_id=${process.env.MIX_CLIENT_ID}`, {
                oauth_code: code,
                redirect_uri: window.location.origin + window.location.pathname,
            })
            .then(async (res) => {
                if (res) {
                    if (res?.data?.firstname && res?.data?.lastname && res?.data?.idcode) {
                        await routes.push({ name: 'messageRegisterIdCard' });
                    } else {
                        http.setToken(res.data.access_token);

                        localStorage.setItem('access_token', res.data.access_token);
                        localStorage.setItem('expires_in', res.data.expires_in);

                        await this._postAuthCallback();
                        routes.push('/').catch(() => {});
                    }
                }
            })
            .catch(() => {
                routes.push({ name: 'login' }).catch(() => {});
            });
    }

    async login(username, password, rememberMe) {
        try {
            const res = await http.post('/oauth/token', {
                username,
                password,
                remember_me: rememberMe || false,
                lang: localStorage.getItem('lang'),
            });

            if (!(res?.data && res.data.access_token && res.data.expires_in)) {
                throw new Error("Something went wrong can't find res for /oauth/token request", res);
            }

            await http.setToken(res.data.access_token);

            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('expires_in', res.data.expires_in);

            await store.dispatch(INIT_USER_PROFILE);

            await this._postAuthCallback();
        } catch (error) {
            vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
        }
    }

    forceSetToken(token) {
        http.setToken(token);
        localStorage.setItem('access_token', token);
    }

    async logout(values = {}) {
        const { skipRequest } = values;

        if (!this.isAuthorized) {
            return;
        }

        if (skipRequest) {
            this.clearStorage();

            routes.push({ name: 'login' });
        } else {
            return http
                .get('/api/logout')
                .catch(() => console.error('Already logged out'))
                .finally(() => {
                    this.clearStorage();

                    routes.push({ name: 'login' });
                });
        }
    }

    checkUsername(username) {
        return http.get('/api/account/check_username', { params: { username: username } }).then((res) => res.data);
    }

    checkCoupon(coupon) {
        return http.get('/api/account/check_promo_code', { params: { coupon } });
    }

    register(data) {
        const payload = { ...data };
        payload.recaptcha_token = data?.recaptchaToken;
        payload.recaptcha_action = data?.recaptchaAction;

        delete payload.recaptchaToken;
        delete payload.recaptchaAction;

        return http.post('/api/account', payload);
    }

    requestForgotPassword(username) {
        return http.post('/api/auth/recover', { username, lang: localStorage.getItem('lang') }).then((res) => {
            if (res.status === 200) {
                routes.push({ name: 'password.sent' }).catch(() => {});
            }
        });
    }

    setNewPassword(data) {
        return http.put('/api/account/set_new_password', data);
    }

    changePassword(data) {
        return http.put('/api/account/set_new_password', data);
    }

    setCompanyOrCreate() {
        let companyId = store.getters.prevCompanyAndPlace.companyId;
        if (!companyId) {
            const filteredCompanies = store.getters.companies.filter((company) => company?.company?.name);
            if (filteredCompanies && filteredCompanies.length) {
                companyId = filteredCompanies[0].company.id;
            }
        }
        if (companyId && store.getters.companies.some((company) => company?.company?.id === companyId)) {
            vm && vm.$toastr && vm.$toastr.i(vm.$t('Error with current company, changed selected company'));
            store.dispatch(SET_SELECTED_COMPANY, companyId);
        } else {
            vm && vm.$toastr && vm.$toastr.i(vm.$t('Error with current company, redirected to create new company'));
            store.dispatch(START_COMPANY_CREATION);
            vm.$router
                .push({
                    name: 'create.company',
                    params: {
                        isCreateNew: false,
                    },
                })
                .catch(() => {});
        }
    }

    setPlaceOrCreate() {
        let placeId = store.getters.prevCompanyAndPlace.placeId;
        if (!placeId) {
            const filteredPlaces = store.getters.selectedCompany?.places?.filter((place) => place.name);
            if (filteredPlaces && filteredPlaces.length) {
                placeId = filteredPlaces[0].id;
            }
        }
        if (placeId && store.getters?.places?.some((place) => place && place.id === placeId)) {
            vm.$toastr.i(vm.$t('Error with current place, changed selected place'));
            store.dispatch(SET_SELECTED_PLACE, placeId);
            return;
        }

        store.dispatch(START_PLACE_CREATION);
    }

    // TODO: Legacy code
    setCompanyPlaceOrCreateNew() {
        if (!store.getters.selectedCompanyId) {
            this.setCompanyOrCreate();
        } else if (!store.getters.selectedPlaceId) {
            this.setPlaceOrCreate();
        }
    }

    clearStorage() {
        const updateStatus = localStorage.getItem('update_status');
        const lang = localStorage.getItem('lang');

        localStorage.clear();
        sessionStorage.clear();

        updateStatus && localStorage.setItem('update_status', updateStatus);
        lang && localStorage.setItem('lang', lang);
    }
}

export default new AuthService();
