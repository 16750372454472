import http from '@http';
import { UPDATE_PLACE } from '@store/modules/company/place/constants';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash.clonedeep';

function state() {
    return {
        chosenAnalyses: [],
        frequency: {},
        waterSourceTypes: [],
    };
}

const getters = {
    chosenAnalyses(state): any {
        return state.chosenAnalyses;
    },
    frequency(state) {
        return state.frequency || {};
    },
    waterSourceTypes(state) {
        return state.waterSourceTypes || [];
    },
};

const mutations = {
    setAnalysesSettings(state, payload) {
        state.chosenAnalyses = payload;
    },
    setFrequency(state, { id, data }) {
        state.frequency[id] = data;
    },
    setWaterSourceTypes(state, payload) {
        state.waterSourceTypes = payload;
    },
    deleteCustomAnalyses(state, id) {
        state.chosenAnalyses = state.chosenAnalyses.filter((item) => item.id !== id);
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getWaterSourceTypes({ getters, commit }) {
        if (!getters.waterSourceTypes.length) {
            const res = await http
                .get('/api/classifiers/fhp-feature-types')
                .then((res) => res.data?.find((i) => i.name === 'Water source type'));
            commit('setWaterSourceTypes', res);
        }

        return getters.waterSourceTypes;
    },
    async getFrequencies({ commit, getters }, id) {
        if (!getters.frequency[id]?.length) {
            const data = await http.get(`/api/classifiers/task-subtypes/${id}/frequencies`).then((res) => res.data);
            commit('setFrequency', { id, data });
        }

        return getters.frequency[id];
    },
    async getAnalysesSettings({ rootGetters, commit }) {
        let res = await http.get(`/api/places/${rootGetters.selectedPlaceId}/analyses`).then((res) => res.data);
        res = checkAndSetMandatory(res || []);

        const analyses = res?.map(AnalysesSetting.fromJSON);

        // Need it cause we keep water source separately but it has the same presentation
        analyses.unshift(
            new AnalysesSetting({
                taskDesc: 'Water system type',
                frequency: rootGetters.selectedPlace?.water_source?.fhp_feature_option_id ?? CENTRAL_WATER_NETWORK,
                comment: rootGetters.selectedPlace?.water_source?.comment,
                taskSubtypeId: WATER_SOURCE,
                isWaterSource: true,
            })
        );

        commit('setAnalysesSettings', analyses);

        return analyses;
    },
    updateAnalysesSettings({ dispatch, getters }, analysesSettings: AnalysesSetting[]): Promise<any> {
        const values: AnalysesSetting[] = cloneDeep(
            analysesSettings.filter((item) => {
                return !isEqual(
                    item,
                    getters.chosenAnalyses.find((i) => i.id === item.id)
                );
            })
        );
        const requests: any = [];

        const waterSourceIndex = values.findIndex((i) => i.taskSubtypeId === WATER_SOURCE);
        requests.push(dispatch('updateWaterSource', values[waterSourceIndex]));
        values.splice(waterSourceIndex, 1);

        values.forEach((item: AnalysesSetting) => {
            if (!item.id) {
                requests.push(dispatch('createCustomAnalyses', item));
            } else if (item) {
                requests.push(dispatch('updatedCustomAnalyses', item));
            }
        });

        return Promise.all(requests)
            .then(() => {
                dispatch('plan/getStates', null, { root: true });
            })
            .finally(() => {
                dispatch('plan/getSelectedPlanStatuses', {}, { root: true });
            });
    },
    createCustomAnalyses({ rootGetters }, payload: AnalysesSetting): Promise<any> {
        return http.post(`/api/places/${rootGetters.selectedPlaceId}/analyses`, {
            task_subtype_id: payload.taskSubtypeId,
            task_frequency_type_id: payload.frequency,
            task_desc: payload.title,
            comment: payload.comment,
            custom_frequency: payload.customFrequency,
        });
    },
    updatedCustomAnalyses({ rootGetters }, payload: AnalysesSetting): Promise<any> {
        return http.put(`/api/places/${rootGetters.selectedPlaceId}/analyses/${payload.id}`, {
            id: payload.id,
            task_subtype_id: payload.taskSubtypeId,
            task_frequency_type_id: payload.frequency,
            task_desc: payload.title,
            comment: payload.comment,
            custom_frequency: payload.customFrequency,
        });
    },
    async deleteCustomAnalyses({ rootGetters, commit }, id: number) {
        await http.delete(`/api/places/${rootGetters.selectedPlaceId}/analyses/${id}`);
        commit('deleteCustomAnalyses', id);
    },
    updateWaterSource({ dispatch, rootGetters }, payload: AnalysesSetting) {
        return dispatch(
            UPDATE_PLACE,
            {
                placeId: rootGetters.selectedPlaceId,
                data: {
                    water_source: {
                        fhp_feature_option_id: payload.frequency,
                        comment: payload.comment,
                    },
                },
                redirect: false,
            },
            { root: true }
        );
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

const CUSTOM_TASK_SUBTYPE_ID = 20;
const CENTRAL_WATER_NETWORK = 2;
const WATER_SOURCE = 'water_source';

export class AnalysesSetting {
    public id?: number;
    public title: string;
    public taskDesc: string;
    public taskSubtypeId: number | string;
    public frequency: any;
    public comment: string;
    public customFrequency: string;
    public isWaterSource: boolean;

    constructor(data: any) {
        this.id = data?.id;
        this.taskDesc = data?.taskDesc;
        this.title = data?.taskDesc;
        this.taskSubtypeId = data?.taskSubtypeId || CUSTOM_TASK_SUBTYPE_ID;
        this.frequency = data?.frequency;
        this.comment = data?.comment;
        this.customFrequency = data?.customFrequency;
        this.isWaterSource = data?.isWaterSource;
    }

    get isCustom() {
        return this.taskSubtypeId === CUSTOM_TASK_SUBTYPE_ID;
    }

    static fromJSON(json: any) {
        return new AnalysesSetting({
            id: json.id,
            taskDesc: json.task_desc,
            frequency: json.task_frequency_type_id,
            comment: json.comment,
            customFrequency: json.custom_frequency,
            taskSubtypeId: json.task_subtype_id,
            isWaterSource: false,
        });
    }
}

/**
 * Set default values if it's not exists in response
 * API doesn't return it by default
 */
function checkAndSetMandatory(values: any[] = []): any[] {
    const resArray: any[] = [...values];

    const defaults = [
        {
            is_default: false,
            task_desc: 'listeria_bacteria_analyses',
            task_subtype_id: 18,
            task_frequency_type_id: null,
            custom_frequency: null,
            comment: null,
            allow_not_done: false,
        },
        {
            is_default: false,
            task_desc: 'extending_best_before',
            task_subtype_id: 19,
            task_frequency_type_id: null,
            custom_frequency: null,
            comment: null,
            allow_not_done: false,
        },
        {
            is_default: false,
            task_desc: 'taking_water_analyses',
            task_subtype_id: 16,
            task_frequency_type_id: null,
            custom_frequency: null,
            comment: null,
            allow_not_done: false,
        },
    ];

    defaults.forEach((defaultValue) => {
        const res = values.find((value) => {
            return value.task_subtype_id === defaultValue.task_subtype_id;
        });
        if (!res) {
            resArray.push(defaultValue);
        }
    });

    return resArray;
}
