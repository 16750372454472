import Path from '@services/path.service';

import { GET_AND_DELETE_FROM_ARCHIVE, REMOVE_FROM_ARCHIVE, SAVE_TO_ARCHIVE } from './constants';

const state = {
    archive: new Path(),
};

const mutations = {
    [SAVE_TO_ARCHIVE](state, { value, path }) {
        state.archive.push(path, value);
    },
    [REMOVE_FROM_ARCHIVE](state, path) {
        state.archive.remove(path);
    },
};

const actions = {
    [GET_AND_DELETE_FROM_ARCHIVE]({ commit, getters }, path) {
        const value = getters.getValueFromArchiveByPath(path);

        commit(REMOVE_FROM_ARCHIVE, path);

        return value;
    },
};

const getters = {
    getValueFromArchiveByPath: (state) => (path) => {
        return state.archive.get(path);
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
