import http from '@http';
import store from '@store';
import { STOP_VIEW_LOADER } from '@store/modules/loader/constants';

class SettingsService {
    updateProfile(data) {
        return http.put('/api/account/profile', data).then((res) => res.data);
    }

    changePassword(data) {
        return http
            .put('/api/account/password', data)
            .then((res) => res.data)
            .finally(() => {
                store.commit(STOP_VIEW_LOADER);
            });
    }

    getTranslates(key) {
        return http.get(`/api/lang/${key}.json`).then((msgs) => {
            if (msgs && msgs.data) {
                return msgs.data;
            }

            return {};
        });
    }

    changeAccountLang(langKey) {
        return http
            .put('/api/account/lang', { lang: langKey })
            .then((res) => res.data)
            .catch((e) => {
                if (e && e?.reason !== 'cancelled') {
                    return e;
                }
            });
    }

    async hasSeenIntroSlides() {
        const profile = await http.get('/api/profile');
        return profile?.data?.has_seen_intro_slides;
    }

    async setUserHasSeenIntroSlides(data) {
        return await http.put('/api/account/profile', data).then((res) => res.data);
    }
}

export default new SettingsService();
