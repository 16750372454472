<script>
export default {
    name: 'AccessLevel',

    props: {
        allowFor: {
            type: Array,
            nullable: true,
            default: null,
        },
        disableFor: {
            type: Array,
            nullable: true,
            default: null,
        },
    },

    render() {
        let denied = true;

        if (['member', 'owner'].includes(this.$store.getters.selectedCompany?.company?.role)) {
            denied = false;
        } else if (this.disableFor?.includes(['company-readonly'])) {
            denied = true;
        } else if (['member', 'owner'].includes(this.$store.getters.selectedPlace?.role)) {
            denied = false;
        } else {
            if (this.disableFor) {
                const companyDenied = this.disableFor.includes(this.$store.getters.selectedCompany?.company?.role);
                const placeDenied = this.disableFor.includes(this.$store.getters.selectedPlace?.role);
                const denied = placeDenied || companyDenied;

                return this.$scopedSlots.default({
                    denied,
                    accepted: !denied,
                });
            } else if (this.allowFor) {
                const companyAllowed = this.allowFor.includes(this.$store.getters.selectedCompany?.company?.role);
                const placeAllowed = this.allowFor.includes(this.$store.getters.selectedPlace?.role);
                const allowed = placeAllowed || companyAllowed;

                return this.$scopedSlots.default({
                    denied: !allowed,
                    accepted: allowed,
                });
            }
        }

        return this.$scopedSlots.default({
            denied: denied,
            accepted: !denied,
        });
    },
};
</script>
