import http from '@http';

class PlaceService {
    constructor() {
        if (PlaceService.instance) {
            return PlaceService.instance;
        }

        PlaceService.instance = this;
    }

    postPlace(companyId, data) {
        return http.post(`/api/place/${companyId}`, data).then((res) => {
            if (res.status === 200) {
                return res.data;
            }

            return Promise.reject();
        });
    }

    updatePlace(placeId, data) {
        return http.put(`/api/place/${placeId}`, data).then((res) => {
            if (res.status === 200) {
                return res.data;
            }

            return Promise.reject();
        });
    }

    delete(placeId) {
        return http.delete(`/api/place/${placeId}`);
    }

    updateLocation(placeId, locationType) {
        return http.put(`/api/place/${placeId}/location-type/${locationType}`).then((res) => res.data);
    }

    updateExtraData(placeId, data) {
        return http.put(`/api/place/${placeId}/extra-data`, data).then((res) => res.data);
    }

    updateCustomerGroups(placeId, data) {
        return http.put(`/api/place/${placeId}/customer-groups`, data).then((res) => res.data);
    }

    refreshLastVisit(placeId) {
        return http.put(`/api/place/${placeId}/set_last_visit`).then((res) => res.data);
    }
}

export default new PlaceService();
