import http from '@http';
import { vm } from '@main';

function state() {
    return {};
}

const mutations = {
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getTicket({ rootGetters }, id) {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/tickets/${id.id}`);
            return data;
        } catch (error) {
            handleError(error);
        }
    },
    async create({ rootGetters }, payload) {
        try {
            const { data } = await http.post(`/api/places/${rootGetters.selectedPlaceId}/tickets/store-new`, payload);
            return data;
        } catch (error) {
            handleError(error);
        }
    },
    async update({ rootGetters }, payload) {
        try {
            const { data } = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/tickets/${payload.id}`,
                payload
            );
            return data;
        } catch (error) {
            handleError(error);
        }
    },
};

const getters = {};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

function handleError(error) {
    vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
    console.error(error);
}
