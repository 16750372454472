import { vm } from '@main';

import auditService from '@services/audit.service';
import { preventMultiple } from '@services/http/http.service';

import {
    ADD_AUDIT_RESULT,
    ADD_AUDIT_TEMPLATE,
    DELETE_AUDIT_RESULT,
    DELETE_AUDIT_TEMPLATE,
    INIT_AUDITS,
    LOADED,
    RESET_AUDITS,
    SET_RESULTS,
    SET_TEMPLATES,
    UPDATE_AUDIT_RESULT,
    UPDATE_AUDIT_TEMPLATE,
    UPDATE_CONFIRM_AUDIT,
} from './constants';

const state = {
    auditTemplatesObj: { companyId: null, auditTemplates: [] },
    auditResultsObj: { placeId: null, auditResults: [] },
    loaded: false,
};

const mutations = {
    [LOADED](state) {
        state.loaded = true;
    },
    [SET_TEMPLATES](state, { companyId, auditTemplates }) {
        state.auditTemplatesObj = { companyId: companyId, auditTemplates: auditTemplates };
    },
    [SET_RESULTS](state, { placeId, auditResults }) {
        state.auditResultsObj = { placeId: placeId, auditResults: auditResults };
    },

    [ADD_AUDIT_TEMPLATE](state, auditTemplate) {
        state.auditTemplatesObj.auditTemplates.unshift(auditTemplate);
    },
    [UPDATE_AUDIT_TEMPLATE](state, auditTemplate) {
        const templates = state.auditTemplatesObj.auditTemplates;
        for (let i = 0; i < templates.length - 1; i++) {
            if (templates[i] && templates[i].id === auditTemplate.id) {
                templates[i] = auditTemplate;
                return;
            }
        }
    },
    [UPDATE_CONFIRM_AUDIT](state, { id, confirmed }) {
        const results = state.auditResultsObj.auditResults;
        for (let i = 0; i < results.length - 1; i++) {
            if (results[i] && results[i].id === id) {
                results[i].confirmed = confirmed;
                return;
            }
        }
    },
    [DELETE_AUDIT_TEMPLATE](state, id) {
        state.auditTemplatesObj.auditTemplates = state.auditTemplatesObj.auditTemplates.filter(
            (auditTemplate) => auditTemplate.id !== id
        );
    },

    [ADD_AUDIT_RESULT](state, auditResult) {
        state.auditResultsObj.auditResults.unshift(auditResult);
    },
    [UPDATE_AUDIT_RESULT](state, auditResult) {
        const results = state.auditResultsObj.auditResults;

        state.auditResultsObj.auditResults = results.map((data) => {
            if (data.id === auditResult.id) {
                data = { ...auditResult };
            }
            return data;
        });
    },
    [DELETE_AUDIT_RESULT](state, id) {
        state.auditResultsObj.auditResults = state.auditResultsObj.auditResults.filter(
            (auditResult) => auditResult.id !== id
        );
    },
};

const actions = {
    [INIT_AUDITS]({ commit, getters }, forceUpdate) {
        if (getters.auditResultsPlaceId !== getters.selectedPlaceId || forceUpdate) {
            preventMultiple(auditService.getAuditResults)(getters.selectedPlaceId)
                .then((auditResults) => {
                    auditResults = auditResults.sort((a, b) => {
                        return b.id - a.id; // needs better sorting based on updated_at
                    });
                    commit(SET_RESULTS, { placeId: getters.selectedPlaceId, auditResults: auditResults });
                })
                .finally(() => {
                    commit(LOADED);
                });
        }

        if (getters.auditTemplatesCompanyId !== getters.selectedCompanyId || forceUpdate) {
            preventMultiple(auditService.getAuditTemplates)(getters.selectedCompanyId).then((auditTemplates) => {
                commit(SET_TEMPLATES, {
                    companyId: getters.selectedCompanyId,
                    auditTemplates: [...auditTemplates.active, ...auditTemplates.archived],
                });
            });
        }
    },
    [RESET_AUDITS]({ commit, dispatch }) {
        commit(SET_RESULTS, { placeId: null, auditResults: [] });
        commit(SET_TEMPLATES, { companyId: null, auditTemplates: [] });
        if (vm?.$route?.name?.startsWith('audits.')) {
            dispatch(INIT_AUDITS);
        }
    },

    [DELETE_AUDIT_TEMPLATE]({ commit }, id) {
        auditService.deleteAudit(id).then(() => {
            commit(DELETE_AUDIT_TEMPLATE, id);
        });
    },

    [DELETE_AUDIT_RESULT]({ commit }, auditId) {
        auditService.deleteAuditResult(auditId).then(() => {
            commit(DELETE_AUDIT_RESULT, auditId);
        });
    },
};

const getters = {
    auditLoaded(state) {
        return state.loaded;
    },
    auditTemplates: (state) => {
        if (state.auditTemplatesObj) {
            return state.auditTemplatesObj.auditTemplates || [];
        }

        return [];
    },
    auditTemplatesCompanyId: (state) => {
        if (state.auditTemplatesObj) {
            return state.auditTemplatesObj.companyId || null;
        }
        return null;
    },
    auditResults: (state) => {
        if (state.auditResultsObj) {
            return state.auditResultsObj.auditResults || [];
        }

        return [];
    },
    auditResultsPlaceId: (state) => {
        if (state.auditResultsObj) {
            return state.auditResultsObj.placeId || null;
        }
        return null;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
