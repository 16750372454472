import cloneDeep from 'lodash.clonedeep';
import { v1 as uuidv1 } from 'uuid';

import http from '@http';

function state() {
    return {
        tasks: [],
    };
}

const mutations = {
    setTasks(state, tasks) {
        state.tasks = tasks;
    },
    addTask(state, task) {
        state.tasks.push(task);
    },
    updateTask(state, task) {
        const index = state.tasks.findIndex((t) => t.id === task.id);
        if (index >= 0) {
            state.tasks[index] = { ...task, isOutsourceable: state.tasks[index].isOutsourceable };
        } else {
            console.error('Trying to edit a non existing WorkGroup task: ', index);
        }
    },
    deleteCustomTask(state, id) {
        state.tasks = state.tasks.filter((item) => item.id !== id);
    },
};

const actions = {
    async setTasks({ rootGetters, commit }) {
        const response = await http
            .get(`/api/places/${rootGetters.selectedPlaceId}/work-group-tasks`)
            .then(({ data }) => data);
        const tasks = response.map(WorkGroupTask.fromJSON);
        commit('setTasks', tasks);
        return tasks;
    },
    async updateTasks({ dispatch }, workGroupTasks: WorkGroupTask[]) {
        const values: WorkGroupTask[] = cloneDeep(workGroupTasks);
        const requests: any = [];

        const resolvePromise = (item: any) => {
            if (!item?.id) {
                return dispatch('createTask', item);
            } else if (item) {
                return dispatch('updateTask', item);
            }
        };

        for (const item of values) {
            const index = values.indexOf(item);
            if (index === values.length - 1) {
                await Promise.all(requests);
                await resolvePromise(item);

                continue;
            }

            requests.push(resolvePromise(item));
        }

        await dispatch('plan/getSelectedPlanStatuses', {}, { root: true });
        await dispatch('plan/getDetails');

        return;
    },
    async createTask({ rootGetters, commit }, workGroupTask: WorkGroupTask) {
        const response = await http
            .post(`api/places/${rootGetters.selectedPlaceId}/work-group-tasks/`, workGroupTask.toJSON())
            .then(({ data }) => data);
        const task = WorkGroupTask.fromJSON(response);
        commit('addTask', task);
        return response;
    },
    async updateTask({ rootGetters, commit }, workGroupTask: WorkGroupTask) {
        const response = await http
            .put(
                `api/places/${rootGetters.selectedPlaceId}/work-group-tasks/${workGroupTask.id}`,
                workGroupTask.toJSON()
            )
            .then(({ data }) => data);
        const task = WorkGroupTask.fromJSON(response);
        commit('updateTask', task);
        return response;
    },
    async deleteCustomTask({ rootGetters, commit }, id) {
        const response = await http.delete(`api/places/${rootGetters.selectedPlaceId}/work-group-tasks/${id}`);
        commit('deleteCustomTask', id);
        return response;
    },
};

const getters = {
    getTasks: (state) => state.tasks,
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export class WorkGroupTask {
    public id?: number;
    public uuid?: string;
    public isDefault?: boolean;
    public taskDesc: string;
    public taskSubtypeId?: number;
    public responsibleName: string;
    public serviceProvider: string;
    public isOutsourceable: boolean;
    public isOutsourced: boolean;
    public isMandatory: boolean;

    constructor(data?: any) {
        this.id = data?.id;
        (this.uuid = data?.id ? undefined : uuidv1()), (this.isDefault = data?.isDefault);
        this.taskDesc = data?.taskDesc || '';
        this.taskSubtypeId = data?.taskSubtypeId;
        this.responsibleName = data?.responsibleName || '';
        this.serviceProvider = data?.serviceProvider || '';
        this.isOutsourceable = data ? data.isOutsourceable : true;
        this.isOutsourced = !!data?.serviceProvider;
        this.isMandatory = data?.isMandatory;
    }

    static fromJSON(json: any) {
        return new WorkGroupTask({
            id: json.id,
            isDefault: json.is_default,
            taskDesc: json.task_desc,
            taskSubtypeId: json.task_subtype_id,
            responsibleName: json.responsible_name,
            serviceProvider: json.service_provider,
            isOutsourceable: json.is_outsourceable,
            isOutsourced: !!json.service_provider,
            isMandatory: !!json.is_mandatory,
        });
    }

    toJSON() {
        const json = {
            is_default: this.isDefault,
            task_subtype_id: this.taskSubtypeId,
            responsible_name: this.responsibleName,
            service_provider: this.isOutsourced ? this.serviceProvider : '',
            is_outsourceable: this.isOutsourceable,
        };
        if (this.id) {
            json['id'] = this.id;
        }
        if (!this.isDefault) {
            json['task_desc'] = this.taskDesc;
        }

        return json;
    }
}
