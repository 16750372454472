class FdComponent extends HTMLElement {
    shadowRoot: ShadowRoot;

    constructor() {
        super();
        this.shadowRoot = this.attachShadow({ mode: 'open' });
        this.shadowRoot.innerHTML = `
      <style>:host { display: block; color: red; }</style>
      <div id="content">Hello, Web Component!</div>
      <button id="button">Click me</button>
    `;

        this.shadowRoot.querySelector('#button')!.addEventListener('click', () => {
            this.dispatchEvent(
                new CustomEvent('custom-click', {
                    detail: { message: 'Button clicked' },
                    bubbles: true,
                    composed: true,
                })
            );
        });
    }

    // Typed observed attributes
    static get observedAttributes(): string[] {
        return ['message'];
    }

    // Attribute change handler with typed parameters
    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
        if (name === 'message') {
            this.shadowRoot.querySelector('#content')!.textContent = newValue;
        }
    }
}

export default FdComponent;
