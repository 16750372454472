import SettingsCreatePlace from '@views/SetUp/Location/PlaceInformation';
import PlaceInformation from '@views/SetUp/Location/PlaceInformation';

export default {
    path: 'places',
    component: () => import(/* webpackChunkName: "locations-module" */ './Index.vue'),
    name: 'places',
    redirect: {
        name: 'places.current',
    },
    children: [
        {
            path: 'new',
            components: { createSettings: SettingsCreatePlace },
            name: 'places.new',
        },
        {
            path: '',
            components: { createSettings: PlaceInformation },
            name: 'places.current',
        },
    ],
};
