export class TaskStep {
    public id: number;
    public allowCustomValueInd: number;
    public createdAt: string;
    public customUnit: any;
    public entryMaxValue: number;
    public entryMinValue: number;
    public fhpId: number;
    public isDefault: number;
    public name: string;
    public orderNumber: number;
    public originalId: number;
    public sensorId: number;
    public taskId: number;
    public taskStepTypeName: string;
    public updatedAt: string;

    constructor(data: any) {
        this.id = data?.id;
        this.allowCustomValueInd = data?.allowCustomValueInd;
        this.createdAt = data?.createdAt;
        this.customUnit = data?.customUnit;
        this.entryMaxValue = data?.entryMaxValue;
        this.entryMinValue = data?.entryMinValue;
        this.fhpId = data?.fhpId;
        this.isDefault = data?.isDefault;
        this.name = data?.name;
        this.orderNumber = data?.orderNumber;
        this.originalId = data?.originalId;
        this.sensorId = data?.sensorId;
        this.taskId = data?.taskId;
        this.taskStepTypeName = data?.taskStepTypeName;
        this.updatedAt = data?.updatedAt;
    }

    static fromJSON(json: any) {
        return new TaskStep({
            id: json.id,
            allowCustomValueInd: json.allow_custom_value_ind,
            createdAt: json.created_at,
            customUnit: json.custom_unit,
            entryMaxValue: json.entry_max_value,
            entryMinValue: json.entry_min_value,
            fhpId: json.fhp_id,
            isDefault: json.is_default,
            name: json.name,
            orderNumber: json.order_number,
            originalId: json.original_id,
            sensorId: json.sensor_id,
            taskId: json.task_id,
            taskStepTypeName: json.task_step_type_name,
            updatedAt: json.updated_at,
        });
    }

    toJSON() {
        return {
            id: this.id,
            allow_custom_value_ind: this.allowCustomValueInd,
            created_at: this.createdAt,
            custom_unit: this.customUnit,
            entry_max_value: this.entryMaxValue,
            entry_min_value: this.entryMinValue,
            fhp_id: this.fhpId,
            is_default: this.isDefault,
            name: this.name,
            order_number: this.orderNumber,
            original_id: this.originalId,
            sensor_id: this.sensorId,
            task_id: this.taskId,
            task_step_type_name: this.taskStepTypeName,
            updated_at: this.updatedAt,
        };
    }
}
