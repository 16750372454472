import { INIT_OVERVIEW, RESET_OVERVIEW, SET_DATA } from '@store/modules/overview/constants';

import overviewService from '@views/Overview/services/overview.service';

const initialState = {
    /**
     * Keeps groups and places in groups
     */
    grouped: [],
    /**
     * Keeps ungrouped places
     */
    ungrouped: [],
    companies: [],

    hasGroups: false,
};

const state = { ...initialState };

const mutations = {
    [SET_DATA](state, data) {
        if (data) {
            if (data.hasGroups) {
                state.hasGroups = data.hasGroups;
                state.grouped = data.grouped;
                state.ungrouped = data.ungrouped;
            } else {
                state.companies = data.companies;
            }
        }
    },
    [RESET_OVERVIEW](state) {
        Object.assign(state, initialState);
    },
};

const actions = {
    async [INIT_OVERVIEW]({ commit, getters }) {
        try {
            const res = await overviewService.getCompanies(getters.selectedCompanyId);
            commit(SET_DATA, res);
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    overviewHasCachedData(state) {
        return !!state.grouped?.length || !!state.ungrouped?.length || !!state.companies?.length;
    },
    overviewCompanies(state) {
        return Array.isArray(state.companies) ? state.companies : [];
    },
    isSingleCompany(state) {
        return !!state.companies && state.companies.length === 1 && !!state.companies[0];
    },
    overviewHasGroups(state) {
        return state.hasGroups;
    },
    overviewGroups(state) {
        return Array.isArray(state.grouped) ? state.grouped : [];
    },
    overviewUngroupedPlaces(state) {
        return Array.isArray(state.ungrouped) ? state.ungrouped.filter((i) => !!i.name) : [];
    },

    getOverviewGroupById: (_, getters) => (id) => {
        return getters.overviewGroups.find((i) => i.group_id === id);
    },
};

const overview = {
    state,
    mutations,
    actions,
    getters,
};

export default overview;
