import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/modules/loader/constants';

const state = {
    isViewLoading: 0,
};

const mutations = {
    [START_VIEW_LOADER](state) {
        state.isViewLoading++;
    },

    [STOP_VIEW_LOADER](state) {
        state.isViewLoading--;
        if (state.isViewLoading < 0) {
            state.isViewLoading = 0;
        }
    },
};

const actions = {
    startLoader({ commit }) {
        commit(START_VIEW_LOADER);
    },
    stopLoader({ commit }) {
        commit(STOP_VIEW_LOADER);
    },
};

const getters = {
    isViewLoading: (state) => {
        return state?.isViewLoading > 0;
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
