import { vm } from '@main';

import { DateValue } from '@common/types';

const TWO = 2;
const TEN = 10;
const HUNDRED = 100;

const positiveOrZero = (value: number) => (value > 0 ? value : 0);

const printNumTwoDeci = (value: string | number) => {
    if (typeof value === 'string') {
        value = parseInt(value);
    }

    if (isNaN(value) || `${value}`?.trim() === '') {
        return '0.00';
    }

    const valueFragments = `${+value}`.split('.');

    if (valueFragments.length === TWO) {
        if (valueFragments[1].length === 1) {
            return `${value}0`;
        } else if (valueFragments[1].length === TWO) {
            return `${value}`;
        } else {
            return `${valueFragments[0]}.${valueFragments[1].slice(0, TWO)}`;
        }
    } else {
        return `${value}.00`;
    }
};

const twoDeci = (value: string | number) => {
    if (!value) {
        return '';
    }

    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    const res = Math.round(value * HUNDRED) / HUNDRED;

    if (res) {
        return res;
    } else {
        return '';
    }
};

const percentage = (value: number, delimiterValue: number) => {
    if (!Number.isFinite(+value)) {
        return '';
    }
    if (delimiterValue) {
        // If the percent is lower than <delimiterValue> only then 2 decimals are shown
        return value < delimiterValue
            ? `${Math.round(value * HUNDRED) / HUNDRED}%`
            : `${Math.round(value * TEN) / TEN}%`;
    }
    return `${value}%`;
};

const formatDate = (date: moment.Moment | Date) => {
    return new DateValue(date).dateFormat;
};

const formatDateTime = (date: moment.Moment | Date) => {
    return new DateValue(date).dateTimeFormat;
};

const localeOrSomething = (value) => {
    if (value) {
        if (value[vm.$i18n.locale]) {
            return value[vm.$i18n.locale];
        }

        if (typeof value == 'string') {
            return value;
        }

        const keys = Object.keys(value);
        if (keys && keys.length) {
            const keys = Object.keys(value);
            if (keys.length) {
                for (const key of keys) {
                    if (value[key]) {
                        return value[key];
                    }
                }
            }
        }
    }
    return value;
};

export default {
    positiveOrZero,
    printNumTwoDeci,
    percentage,
    formatDate,
    twoDeci,
    formatDateTime,
    localeOrSomething,
};
