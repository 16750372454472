import { vm } from '@main';

export default class TrackingService {
    static haccp(userId) {
        TrackingService.action('haccp_created', userId);
    }

    static fsms(userId) {
        TrackingService.action('fsms_created', userId);
    }

    static signup(userId, countryCode) {
        TrackingService.action('Signup', userId, countryCode);
    }

    static login(userId) {
        TrackingService.action('login', userId);
    }

    static purchase(trackingData) {
        TrackingService.action('purchase', trackingData.user_id, trackingData.code, trackingData);
    }

    static action(action, userId, countryCode, trackingData) {
        vm.$gtm.trackEvent({
            event: 'web tracking',
            action,
            value: {
                user_id: userId,
                country: countryCode,
                ...trackingData,
            },
        });
    }
}
