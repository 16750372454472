<template>
    <div class="d-flex align-items-center flex-wrap">
        <span
            v-for="badge in displayedItems"
            :key="badge.id"
            class="d-flex badge badge-pill badge-secondary m-1"
            :class="`${disabled && 'disabled'} ${clickable && 'cursor-pointer'}`"
            data-cy="role-value"
            @click="$emit('click', badge)"
        >
            <template v-if="!hideNoId || badge.id">
                <div class="text my-auto">
                    {{ badgeText(badge) }}
                </div>
                <button
                    v-if="!disabled"
                    v-b-tooltip.hover="$t('delete')"
                    type="button"
                    class="btn d-none-for-print"
                    @click.stop="openConfirmDelete(badge)"
                >
                    <i class="fd-close"></i>
                </button>
            </template>
        </span>
        <span
            v-if="showCountBadge"
            class="d-flex badge badge-pill badge-secondary my-0 py-1"
        >
            +{{ items.length - numberOfElements }}
        </span>

        <slot name="action"></slot>

        <BaseDelete
            ref="delete-modal"
            modal-only
            @delete="deleteItem"
        />
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
    name: 'BaseBadges',

    props: {
        clickable: Boolean,
        hideNoId: Boolean,
        disabled: Boolean,
        confirmDelete: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Array,
            required: true,
        },
        itemLength: {
            type: Number,
            default: 35,
        },
        numberOfElements: {
            type: Number,
            required: false,
            default: null,
        },
    },

    emits: ['delete', 'click'],

    data() {
        return {
            item: null,
        };
    },

    computed: {
        displayedItems() {
            let items;
            if (this.numberOfElements != null && this.items.length > this.numberOfElements) {
                items = cloneDeep(this.items).splice(0, this.numberOfElements);
            } else {
                items = this.items;
            }
            return items.filter((item) => !!item);
        },
        countIsShownOnRoute() {
            const pagesWhereBadgeCountIsShown = [
                'team.people',
                'activity-log.intro.team-simplified',
                'activity-log.task-new',
            ];
            return pagesWhereBadgeCountIsShown.includes(this.$route.name);
        },
        showCountBadge() {
            return this.numberOfElements < this.items.length && this.countIsShownOnRoute;
        },
    },

    methods: {
        openConfirmDelete(item) {
            this.item = item;
            if (!this.confirmDelete) {
                this.deleteItem();
                return;
            }
            this.$refs?.['delete-modal']?.$refs?.['double-confirm']?.$refs?.['modal']?.show();
        },
        deleteItem() {
            if (this?.item?.id) {
                this.$emit('delete', this.item.id);
            }
        },
        badgeText(badge) {
            const text = this.$t(badge.name || badge.text || '');
            if (this.itemLength && text.length > this.itemLength) {
                return `${text.substring(0, this.itemLength)}...`;
            }
            return text;
        },
    },
};
</script>

<style lang="scss" scoped>
.close {
    margin-bottom: 0.1rem !important;
}

.badge {
    padding: 0.5rem 0.75rem 0.3rem 0.75rem;
    font-size: 0.75rem;
    background-color: $gray-200;

    button {
        padding: 0 !important;
        margin-left: 0.5rem;
    }

    &:hover {
        background-color: $gray-300;
    }

    &.disabled {
        padding: 0.2rem 0.6rem;
    }
}

.badge-secondary {
    background-color: $gray-200;
}

.text {
    line-height: unset !important;
}
</style>
