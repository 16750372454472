<template>
    <div v-if="crumbs && crumbs.length">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent">
                <li
                    v-for="(crumb, index) of crumbs"
                    :key="crumb.name + index"
                    class="breadcrumb-item"
                    :class="index === crumbs.length - 1 ? 'active' : null"
                    :aria-current="index === crumbs.length - 1 ? 'page' : null"
                >
                    <RouterLink
                        :to="crumb.routerLink"
                        tag="a"
                    >
                        {{ $t(crumb.name) }}
                    </RouterLink>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        crumbs: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.breadcrumb-item.active a {
    color: $gray-500 !important;
    cursor: default !important;
}
</style>
