export default (to, from, next) => {
    if (
        to.name === 'register.setting-up' &&
        (from.name === 'register.user-verification' || from.name === 'create.company')
    ) {
        next();
    } else {
        window.history.back();
    }
};
