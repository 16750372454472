export class OverviewPercentage {
    constructor(data) {
        this.audits_percentage = data.audits_percentage;
        this.documents_percentage = data.documents_percentage;
        this.monitoring_percentage = data.monitoring_percentage;
        this.personnel_percentage = data.personnel_percentage;
        this.plan_percentage = data.plan_percentage;
        this.production_percentage = data.production_percentage;
    }

    get overallPercent() {
        let counted = 0;
        let countedPercent = 0;
        if (this.documents_percentage !== null) {
            counted++;
            countedPercent += this.documents_percentage;
        }
        if (this.monitoring_percentage !== null) {
            counted++;
            countedPercent += this.monitoring_percentage;
        }
        if (this.personnel_percentage !== null) {
            counted++;
            countedPercent += this.personnel_percentage;
        }
        if (this.plan_percentage !== null) {
            counted++;
            countedPercent += this.plan_percentage;
        }
        if (this.production_percentage !== null) {
            counted++;
            countedPercent += this.production_percentage;
        }
        if (this.audits_percentage !== null) {
            counted++;
            countedPercent += this.audits_percentage;
        }

        return Math.floor(countedPercent / counted);
    }

    get docsPercent() {
        return this.documents_percentage;
    }

    get monitoringPercent() {
        return this.monitoring_percentage;
    }

    get personnelPercent() {
        return this.personnel_percentage;
    }

    get planPercent() {
        return this.plan_percentage;
    }

    get productionPercent() {
        return this.production_percentage;
    }

    get auditsPercentage() {
        return this.audits_percentage;
    }
}
