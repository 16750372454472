<template>
    <span class="marker-wrapper">
        <span
            v-if="variant"
            :class="`text-${variant} d-flex align-items-center`"
            :title="hint"
        >
            <i class="fd-marker font-size-xs mr-1"></i>
        </span>

        <span
            v-else
            :class="isSuccess ? 'bg-success' : 'bg-danger'"
            class="marker d-inline-block"
        ></span>
    </span>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: '',
        },
        hint: {
            type: String,
            default: '',
        },
        isSuccess: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.marker-wrapper {
    display: inline-block;
    width: 1.5rem;
    padding: 0 0.25rem;
}

.marker {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
</style>
