import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    ChartData,
    ChartOptions,
    Element,
    Legend,
    LinearScale,
    Tooltip,
} from 'chart.js';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

class FdTimelineChart extends HTMLElement {
    private chart: Chart | null = null;
    private selected: number | null = null;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback(): void {
        this.render();
        this.createChart();
    }

    static get observedAttributes(): string[] {
        return ['chart-data', 'selected'];
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
        if (name === 'chart-data' && this.chart) {
            const chartData = JSON.parse(newValue);
            this.updateChart(chartData);
        }
        if (name === 'selected') {
            this.selected = newValue === null ? null : parseInt(newValue);
            if (this.chart) {
                this.chart.update();
            }
        }
    }

    private render(): void {
        const canvas = document.createElement('canvas');
        canvas.id = 'barChart';
        this.shadowRoot!.appendChild(canvas);

        this.style.display = 'block';
        this.style.width = 'auto';
        this.style.height = '300px';
    }

    private createChart(): void {
        const ctx = this.shadowRoot!.querySelector<HTMLCanvasElement>('canvas')!.getContext('2d')!;
        const chartData = JSON.parse(this.getAttribute('chart-data') || '{}');

        const data: ChartData<'bar'> = {
            labels: chartData.labels || [],
            datasets: chartData.datasets || [],
        };

        const options: ChartOptions<'bar'> = {
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                },
                y: {
                    beginAtZero: true,
                    suggestedMax: 5,
                    stacked: true,
                    ticks: {
                        stepSize: 5,
                        callback: function (value) {
                            return Number(value) % 5 === 0 ? value : '';
                        },
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    xAlign: 'center',
                    callbacks: {
                        title: (tooltipItems) => {
                            // Display custom title or return the default
                            return tooltipItems[0].label;
                        },
                        label: (tooltipItem) => {
                            const index = tooltipItem.dataIndex;
                            let total = 0;

                            data.datasets.forEach((dataset) => {
                                total += dataset.data[index];
                            });
                            return `${tooltipItem.raw}/${total}`;
                        },
                    },
                },
            },
            onClick: (event, elements) => {
                if (elements.length > 0) {
                    const element = elements[0];
                    const datasetIndex = element.datasetIndex;
                    const index = element.index;
                    const date = data.labels![index] as string;
                    const clickEvent = new CustomEvent('bar-click', { detail: { date, datasetIndex, index } });
                    this.dispatchEvent(clickEvent);
                }
            },
        };

        this.chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
            plugins: [
                {
                    id: 'highlightSelected',
                    afterDraw: (chart) => {
                        if (this.selected !== null) {
                            const meta = chart.getDatasetMeta(0);
                            const selectedElement = meta.data[this.selected] as Element<any, any>;
                            if (selectedElement) {
                                const ctx = chart.ctx;
                                const x = selectedElement.x;
                                const y = 0;
                                const height = 235;

                                const isMonthView = data.labels!.length > 7;
                                const width = isMonthView ? 40 : 120;

                                ctx.save();
                                ctx.strokeStyle = 'black';
                                ctx.lineWidth = 1;
                                ctx.strokeRect(x - width / 2, y, width, height);
                                ctx.restore();
                            }
                        }
                    },
                },
            ],
        });
    }

    private updateChart(chartData: any): void {
        if (this.chart && this.chart.data && this.chart.data.datasets) {
            this.chart.data.labels = chartData.labels;
            this.chart.data.datasets = chartData.datasets;
            this.chart.update();
        }
    }
}

export default FdTimelineChart;
