import userGroupService from '@services/userGroup.service';

import {
    CREATE_USER_GROUP,
    INIT_USER_GROUPS,
    REMOVE_USER_GROUP,
    SET_USER_GROUPS,
    UPDATE_USER_GROUP,
} from '@store/modules/user-groups/constants';

// TODO: Override it

const state = {
    all_user_groups: [],
};

const mutations = {
    [SET_USER_GROUPS](state, data) {
        state.all_user_groups = data;
    },
    [REMOVE_USER_GROUP](state, groupId) {
        const index = state.all_user_groups.findIndex((i) => i.id === groupId);
        state.all_user_groups.splice(index, 1);
    },
    [CREATE_USER_GROUP](state, data) {
        state.all_user_groups.push(data);
    },
    [UPDATE_USER_GROUP](state, data) {
        const index = state.all_user_groups.findIndex((i) => i.id === data.id);
        state.all_user_groups[index] = data;
    },
};

const actions = {
    async [INIT_USER_GROUPS]({ commit, getters }) {
        return await userGroupService.groups(getters.selectedPlaceId).then((res) => {
            commit(SET_USER_GROUPS, res);

            return res;
        });
    },
    [CREATE_USER_GROUP]({ commit }, data) {
        userGroupService.store(data).then((res) => {
            commit(CREATE_USER_GROUP, res);
        });
    },
    [UPDATE_USER_GROUP]({ commit }, { id, data }) {
        userGroupService.update(id, data).then((res) => {
            commit(UPDATE_USER_GROUP, res);
        });
    },
};

const getters = {
    allUserGroups(state) {
        return Array.isArray(state.all_user_groups) && state.all_user_groups.length ? state.all_user_groups : [];
    },
    placeUserGroups: (state, getters) => (id) => {
        return getters.allUserGroups.filter((group) => group.place_id === id);
    },
    placeActiveUserGroups: (state, getters) => (id) => {
        return getters.allUserGroups.filter((group) => group.place_id === id);
    },
    selectedPlacesUserGroups(state, getters) {
        return getters.allUserGroups.filter((group) => group.place_id === getters.selectedPlaceId);
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
