import authService from '@services/auth.service';
import store from '@store';
import RegisterRoutes from '@views/Auth/views/Register/routes.js';

const Login = () => import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/Login');
const AuthWrapper = () => import(/* webpackChunkName: "auth-module" */ '@views/Auth/AuthWrapper');
const OauthHandler = () => import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/OauthHandler');
const ForgotPassword = () => import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/ForgotPassword');
const PasswordSent = () => import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/PasswordSent');
const InvitationPassword = () => import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/InvitationPassword');
const ChangePassword = () => import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/ChangePassword');
const UnsubscribedWeeklyEmail = () =>
    import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/UnsubscribedWeeklyEmail');
const MessageRegisterIdCard = () =>
    import(/* webpackChunkName: "auth-module" */ '@views/Auth/views/MessageRegisterIdCard');

export default [
    {
        path: '/auth',
        redirect: { name: 'login' },
        component: AuthWrapper,
        children: [
            ...RegisterRoutes,
            {
                name: 'messageRegisterIdCard',
                path: 'message-register-id-card',
                components: { unauthorized: MessageRegisterIdCard },
            },
            {
                path: 'oauth-return',
                components: { unauthorized: OauthHandler },
            },
            {
                path: 'login',
                components: { unauthorized: Login },
                name: 'login',
            },
            {
                path: 'forgot-password',
                components: { unauthorized: ForgotPassword },
            },
            {
                path: 'password-sent',
                components: { unauthorized: PasswordSent },
                name: 'password.sent',
            },
            {
                path: 'invitation-password',
                components: { unauthorized: InvitationPassword },
                name: 'invitation.password',
            },
            {
                path: 'change-password',
                components: { unauthorized: ChangePassword },
                name: 'changePassword',
            },
            {
                path: ':lang/unsubscribed',
                components: { unauthorized: UnsubscribedWeeklyEmail },
                name: 'unsubscribed-weekly-email',
            },
        ],
    },
];

export const beforeEachGuard = async (to, from, next) => {
    const accessConditions = [
        to.path?.includes('/auth/message-register-id-card'),
        to.path?.includes('/auth/oauth-return'),
        to.path?.includes('/auth/login'),
        to.path?.includes('/auth/register'),
        to.path?.includes('/auth/forgot-password'),
        to.path?.includes('/auth/password-sent'),
        to.name?.includes('invitation.password'),
        to.name?.includes('invitation-password'),
        to.name?.includes('changePassword'),
        to.name?.includes('unsubscribed-weekly-email'),
        to.name?.includes('product.show-by-token'),
        to.name?.includes('register.setting-up'),
    ];
    const isUnauthorizedRoute = accessConditions.some((item) => item);
    if ((authService.isAuthorized || authService.isSharedLink) && !isUnauthorizedRoute) {
        const notCreationPage = !to?.name?.startsWith('create') && !from?.name?.startsWith('create');

        if (notCreationPage && store.getters.companies?.length) {
            if (!store.getters.selectedCompanyId) {
                await authService.setCompanyOrCreate();
            } else if (!store.getters.selectedPlaceId) {
                await authService.setPlaceOrCreate();
            }
        }

        next();
    } else {
        if (isUnauthorizedRoute) {
            next();
        } else {
            if (!from.path.includes('/auth/login')) {
                next({
                    path: '/auth/login',
                });
            }
        }
    }
};
