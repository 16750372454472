<template>
    <div>
        <transition name="logo-fade">
            <img
                v-if="!small"
                :class="responsive ? 'd-none d-lg-inline-block' : ''"
                src="/img/fd_logo.svg"
                :width="width"
                :height="height"
                alt="FoodDocs logo"
            />
        </transition>
        <img
            v-if="responsive || small"
            :class="responsive ? 'd-inline-block d-lg-none' : ''"
            :height="(height !== 40 && height) || responsiveHeight"
            :width="responsiveWidth"
            src="/img/fd_avatar.svg"
            alt="FoodDocs logo"
        />
    </div>
</template>

<script>
export default {
    name: 'FdLogo',

    props: {
        width: {
            type: Number,
            default: 140,
        },
        height: {
            type: Number,
            default: 40,
        },
        responsive: Boolean,
        responsiveHeight: {
            type: Number,
            default: 40,
        },
        responsiveWidth: {
            type: Number,
            default: 40,
        },
        small: Boolean,
    },
};
</script>

<style>
.logo-fade-enter-active {
    transition: all 2s ease;
}

.logo-fade-leave-active {
    transition: all 0s;
}

.logo-fade-enter,
.logo-fade-leave-to {
    opacity: 0;
    transform: translateX(10px);
}
</style>
