import http from '@http';

class CompanyTokenService {
    constructor() {
        if (CompanyTokenService.instance) {
            return CompanyTokenService.instance;
        }

        CompanyTokenService.instance = this;
    }

    getCompanyToken(companyId) {
        return http.get(`/api/company_token/${companyId}`);
    }

    postCompanyToken(companyId, data) {
        return http.post(`/api/company_token/${companyId}`, data);
    }

    deleteCompanyToken(tokenId) {
        return http.delete(`/api/company_token/${tokenId}`);
    }
}

export default new CompanyTokenService();
