// Requests
import { hasBase64, removeBase64Prefix } from '@services/helper.service';

export function applyAllRequestsFileMiddleware(config) {
    return removeBase64(makeFileRequestsTheSame(config));
}

export function removeBase64(config) {
    if (config) {
        if (config.method === 'post' || config.method === 'put') {
            if (config.data && typeof config.data === 'object') {
                const possibleKeys = [
                    'certificate_file',
                    'file_base64_encode',
                    'base64',
                    'file',
                    'unsigned_pdf_file',
                    'image_file',
                ];

                const existKey = possibleKeys.find((key) => config.data[key]);

                if (existKey) {
                    if (hasBase64(config.data[existKey])) {
                        config.data[existKey] = removeBase64Prefix(config.data[existKey]);
                    }
                }

                if (config.data.attachments) {
                    config.data.attachments.forEach((item) => {
                        const existKey = possibleKeys.find((key) => item[key]);

                        if (existKey) {
                            if (hasBase64(item[existKey])) {
                                item[existKey] = removeBase64Prefix(item[existKey]);
                            }
                        }
                    });
                }
                // TODO: Create map for file uploads to make same naming for all files and certificates
            }
        }
    }

    return config;
}

export function makeFileRequestsTheSame(config) {
    return config;
}

// Response
export function addBase64(config) {
    // TODO: Create map with mame types

    return config;
}

export function makeFileResponseTheSame(config) {
    return config;
}
