import { vm } from '@main';
import axios from 'axios';
import VueCookies from 'vue-cookies';

import routes from '@routes';

import http, { sharedAccessTokenStrategy } from '@http';
import authService from '@services/auth.service';

import { DateValue } from '@common/types';

import { LOG_ERROR } from '@store/modules/common/constants';
import { SET_SELECTED_COMPANY } from '@store/modules/company/constants';

import { SUBSCRIPTION_PACKAGES } from '@common/constants';

const getters = {
    isAuthorized() {
        return authService.isAuthorized || authService.isSharedLink;
    },
    isInvitedUser() {
        return !!window.localStorage.getItem('user-was-invited');
    },
    hasAccessToFullPlanFeatures() {
        let subscription = vm.$store.getters.selectedCompany.company.subscription;

        let haccpOnly = subscription?.haccp_only;
        let haccpRenewable = subscription?.haccp_renewable;
        let haccpFast = subscription?.haccp_fast;

        let validTo = DateValue.fromRequestDateTime(subscription?.renewal_valid_to);
        let today = new DateValue();
        let hasSubscriptionDaysLeft = today.isBefore(validTo);

        return hasSubscriptionDaysLeft && !haccpOnly && !haccpRenewable && !haccpFast;
    },
    hasAccessToHaccpPlanFeatures() {
        let subscription = vm.$store.getters.selectedCompany?.company?.subscription;
        let validTo = DateValue.fromRequestDateTime(subscription?.renewal_valid_to);
        let today = new DateValue();
        return today.isBefore(validTo);
    },
    hasAccessToProfessionalPackageFeatures(state, getters) {
        let subscription = vm.$store.getters.selectedCompany.company.subscription;
        return (
            getters.hasAccessToFullPlanFeatures &&
            [SUBSCRIPTION_PACKAGES.FULL_FSMS, SUBSCRIPTION_PACKAGES.TRIAL].includes(subscription.price_package_id)
        );
    },
    isMonitoringEditingAllowed() {
        const isCompanyOwner = vm.$store.getters.selectedCompany.company.role === 'owner';
        const isAllowedMonitoringInPlace = ['readonly,temp-measurements', 'owner'].includes(
            vm.$store.getters.selectedPlace.role
        );
        return isCompanyOwner || isAllowedMonitoringInPlace;
    },
    isAdmin() {
        return [vm.$store.getters.selectedCompany.company.role, vm.$store.getters.selectedPlace.role].includes('owner');
    },
};

const actions = {
    async login(_, cred) {
        // TODO: TMP to override
        return await authService.login(cred.username, cred.password, cred.rememberMe);
    },
    logout() {
        authService.logout().then(() => {
            const newStore = {};
            Object.keys(vm.$store).forEach((key) => {
                newStore[key] = null;
            });
            vm.$store.replaceState(newStore);

            // TODO: Hot fix for data clearing
            location.reload();
        });
    },
    signup({ dispatch }, { data }) {
        return authService
            .register(data)
            .then((res) => {
                if (res.status === 200) {
                    if (typeof window.gtag_report_conversion === 'function') {
                        window.gtag_report_conversion();
                    }

                    if (VueCookies.get('invite_code')) {
                        VueCookies.remove('invite_code');
                    }

                    vm?.$ga?.event({
                        eventCategory: 'Register',
                        eventAction: 'User registered',
                        eventLabel: data.username,
                    });

                    return true;
                }
            })
            .catch((error) => {
                dispatch(LOG_ERROR, `authService.register error: ${error}`, { root: true });
                return false;
            });
    },
    checkUsername(_, username) {
        return authService.checkUsername(username).then((res) => res.exists);
    },
    checkCoupon(_, coupon) {
        return authService.checkCoupon(coupon);
    },
    async recoverPassword(_, username) {
        await authService.requestForgotPassword(username).catch((error) => {
            console.error(error);
        });
    },
    async setNewPassword(_, data) {
        await authService.setNewPassword(data).catch((error) => {
            console.error(error);
        });
        return await authService.login(data.username, data.new_password);
    },
    changePassword(_, data) {
        return authService
            .changePassword(data)
            .then((res) => {
                if (res) {
                    routes.push({ name: 'login' });
                }
            })
            .catch((err) => {
                return err;
            });
    },
    setSharedToken({ commit }, token) {
        // it will reset selected previously company and place
        commit(SET_SELECTED_COMPANY, null, { root: true });

        delete axios.defaults.headers.common['Authorization'];
        http.tokenStrategy = sharedAccessTokenStrategy;

        authService.activateSharedLink();

        http.setToken(token);

        commit('shared-access/setSharedAccessState', token, { root: true });
    },
    setUserAsInvited() {
        window.localStorage.setItem('user-was-invited', true);
    },
    unsetUserAsInvited() {
        window.localStorage.removeItem('user-was-invited');
    },
};

export default {
    store: {},
    mutations: {},
    getters,
    actions,
    namespaced: true,
};
