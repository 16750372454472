// Import Pinia and the necessary libraries
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { v1 as uuidv1 } from 'uuid';

import { FilesService } from '@services/files-v2.service';

const TEN = 10;
const NINETY = 90;
const HUNDRED = 100;
const FIVE_HUNDRED = 500;

export const useFilesStore = defineStore('files', {
    state: () => ({
        filesUploading: {},
    }),

    getters: {
        filesUploadingList: (state) => Object.keys(state.filesUploading).map((key) => state.filesUploading[key]),
    },

    actions: {
        async upload({ files, location, onPaper, metadata, payload = null }) {
            if (!files?.length) {
                return [];
            }

            const filesCopy = cloneDeep(Object.values(files));
            const toUpload: { id: String; file: any }[] = [];
            filesCopy.forEach((file) => {
                toUpload.push({
                    id: uuidv1(),
                    file,
                });
            });

            try {
                return Promise.all(
                    toUpload.map((file) => {
                        return FilesService.initFile({ file, metadata, onPaper, payload }).then(async () => {
                            await FilesService.upload(file, (e) => {
                                const progress = TEN + parseInt(String((e.loaded * NINETY) / e.total));
                                this.updateProgress({
                                    id: file.id,
                                    name: file.file?.name,
                                    progress,
                                    link: location,
                                });

                                if (progress === HUNDRED) {
                                    this.updateProgress({
                                        id: file.id,
                                        name: file.file?.name,
                                        progress,
                                        link: location,
                                        done: true,
                                    });
                                }
                            })
                                .then(() => {
                                    this.updateProgress({
                                        id: file.id,
                                        name: file.file?.name,
                                        progress: HUNDRED,
                                        link: location,
                                        done: true,
                                    });

                                    setTimeout(() => {
                                        this.clearItem(file.id);
                                    }, FIVE_HUNDRED);
                                })
                                .catch((e) => {
                                    this.updateProgress({
                                        id: file.id,
                                        name: file.file?.name,
                                        error: e?.message,
                                        link: location,
                                        done: true,
                                    });
                                });

                            return file;
                        });
                    })
                );
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        updateProgress(payload) {
            if (!this.filesUploading[payload.id]) {
                this.filesUploading = {
                    ...this.filesUploading,
                    [payload.id]: {
                        id: payload.id,
                        name: payload.name,
                        progress: 0, // Initialize progress
                        link: payload.link,
                        error: null,
                        done: false,
                    },
                };
            }

            const fileUploading: any = this.filesUploading[payload.id];
            if (payload.error) {
                fileUploading.error = payload.error;
            } else {
                fileUploading.progress = payload.progress;
                if (payload.done) {
                    fileUploading.done = payload.done;
                }
            }
        },

        clearItem(id) {
            delete this.filesUploading[id];
        },

        clear() {
            this.filesUploading = {};
        },
    },
});

export default useFilesStore;
