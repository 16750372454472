import { ArcElement, Chart, ChartData, ChartOptions, DoughnutController } from 'chart.js';

Chart.register(DoughnutController, ArcElement);
class FdOverviewRadialChart extends HTMLElement {
    private chart: Chart | null = null;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback(): void {
        this.render();
        this.createChart();
    }

    static get observedAttributes(): string[] {
        return ['percent'];
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
        if (name === 'percent' && this.chart) {
            this.updateChart(parseInt(newValue));
        }
    }

    private render(): void {
        const canvas = document.createElement('canvas');
        this.shadowRoot!.appendChild(canvas);

        const slot = document.createElement('slot');
        this.shadowRoot!.appendChild(slot); // for the h2 label

        this.style.display = 'block';
        this.style.width = `${this.getAttribute('height') || 100}px`;
        this.style.height = `${this.getAttribute('height') || 100}px`;
    }

    private createChart(): void {
        const ctx = this.shadowRoot!.querySelector('canvas')!.getContext('2d')!;
        const percent = parseInt(this.getAttribute('percent') || '0');
        const color = this.calculateColor(percent);

        const data: ChartData = {
            datasets: [
                {
                    data: [100 - percent, percent],
                    backgroundColor: ['#f5f5f5', color],
                    borderWidth: 6,
                    borderColor: ['#f5f5f5', color],
                    borderRadius: 8,
                },
            ],
        };

        const options: ChartOptions = {
            rotation: 90,
            cutout: '90%',
            tooltips: { enabled: false },

            // @ts-ignore
            hover: { mode: null },
        };

        this.chart = new Chart(ctx, {
            type: 'doughnut',
            data: data,
            options: options,
        });
    }

    private updateChart(percent: number): void {
        const color = this.calculateColor(percent);
        if (this.chart && this.chart.data && this.chart.data.datasets) {
            this.chart.data.datasets[0].data = [percent, 100 - percent];
            this.chart.data.datasets[0].backgroundColor = [color, '#f5f5f5'];
            this.chart.update();
        }
    }

    private calculateColor(percent: number): string {
        if (percent <= 50) {
            return '#b20117';
        } else if (percent > 50 && percent < 100) {
            return '#f58b00';
        } else {
            return '#378752';
        }
    }
}

export default FdOverviewRadialChart;
