var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{staticClass:"custom-control custom-checkbox",class:{ disabled: _vm.disabled, big: _vm.big, rounded: _vm.rounded, medium: _vm.medium },attrs:{"rules":_vm.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valModel),expression:"valModel"}],staticClass:"custom-control-input",attrs:{"id":_vm.id,"disabled":_vm.disabled,"type":"checkbox","data-cy":`${_vm.dataCy}-checkbox`},domProps:{"value":_vm.val,"checked":Array.isArray(_vm.valModel)?_vm._i(_vm.valModel,_vm.val)>-1:(_vm.valModel)},on:{"change":function($event){var $$a=_vm.valModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.val,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valModel=$$c}}}}),_vm._v(" "),(!_vm.$slots.label && !_vm.label)?_c('label',{staticClass:"custom-control-label",class:{
            'has-error': (_vm.validation && _vm.validation.$error) || v.errors.length,
            'no-label': !_vm.$slots.label && !_vm.label,
        },attrs:{"for":_vm.id}}):_c('label',{staticClass:"custom-control-label",class:{
            'has-error': (_vm.validation && _vm.validation.$error) || v.errors.length,
            'no-label': !_vm.$slots.label && !_vm.label,
        },attrs:{"for":_vm.id,"data-cy":_vm.dataCy}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]})],2),_vm._v(" "),_vm._t("default")]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }