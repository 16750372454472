import http from '@http';

const state = {
    currencies: [],
    estimation: null,
    subscriptionRenewal: [],
    quantityChange: null,
};

const mutations = {
    setSubscriptionRenewal(state, data) {
        state.subscriptionRenewal = data;
    },
    setEstimationData(state, data) {
        state.estimation = data;
    },
    periodUpdated(state, data) {
        state.yearly_payment = data;
    },
    setCurrencies(state, data) {
        state.currencies = data;
    },
    setQuantityChangePreview(state, data) {
        state.quantityChange = data;
    },
};

const actions = {
    // eslint-disable-next-line no-empty-pattern
    getCurrentPackage({}, { companyId }) {
        return http.get(`/api/company/${companyId}/current-package`).then((res) => res.data);
    },
    stripeCheckout({ rootGetters }, priceId) {
        return http
            .post(`/api/payments/${rootGetters.selectedCompanyId}/create-checkout-session`, { price_id: priceId })
            .then(({ data }) => {
                return data;
            });
    },
    stripePortalSession({ rootGetters }) {
        return http
            .get(
                `/api/payments/${rootGetters.selectedCompanyId}/create-customer-portal-session?return_url=` +
                    window.location.href
            )
            .then(({ data }) => {
                return data;
            });
    },
    async getSubscriptionRenewalCosts({ commit, rootGetters }, { currency }) {
        if (rootGetters?.selectedCompanyId) {
            try {
                const params = {
                    currency,
                };
                const { data } = await http.get(
                    `/api/company/${rootGetters.selectedCompanyId}/subscription/active-packages-costs`,
                    { params }
                );
                const packs = data.map((pack) => ({
                    name: pack?.package?.name,
                    id: pack?.package?.id,
                    costs: pack?.period_costs,
                }));
                commit('setSubscriptionRenewal', packs);
                return packs;
            } catch (e) {
                console.error(e);
            }
        }
    },
    getCurrencies({ commit }) {
        http.get('/api/classifiers/currencies').then(({ data }) => {
            commit('setCurrencies', data);
        });
    },
    // API takes 1 or -1 as quantityChange -> indicates deleting or adding business unit
    async getQuantityChangePreview({ commit, rootGetters }, quantityChange) {
        try {
            const { data } = await http.get(
                `/api/company/${rootGetters.selectedCompanyId}/subscription/quantity-change-preview?quantity_change=${quantityChange}`
            );
            commit('setQuantityChangePreview', data);
        } catch (e) {
            console.error(e);
        }
    },
};

const getters = {
    periods: (state) => {
        const allPeriods = state.subscriptionRenewal.reduce(
            (a, c) => a.concat(c?.costs?.map((cost) => cost?.period)),
            []
        );
        const uniquePeriods = [];
        const addedPeriods = [];

        allPeriods.forEach((period) => {
            const periodSlug = `${period.time_unit_id}_${period.time_unit_cnt}`;
            if (!addedPeriods.includes(periodSlug)) {
                uniquePeriods.push(period);
                addedPeriods.push(periodSlug);
            }
        });

        return uniquePeriods;
    },
    currencies(state) {
        return state.currencies;
    },
    quantityChange(state) {
        return state.quantityChange;
    },
    isFreePackage() {
        if (state.estimation && state.estimation.next_payment) {
            return state.estimation.next_payment.amount === 0;
        } else {
            return false;
        }
    },
    subscriptionRenewal: (state) => {
        return state.subscriptionRenewal;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
