<template>
    <aside class="files-box border-radius">
        <header
            class="files-box_header"
            @click="toggleCollapse"
        >
            <ProgressBar
                v-if="progress"
                :show="isCollapsed"
                :percent="progress"
                class="w-100"
            />
            <p
                v-if="!isCollapsed"
                class="w-100 p-0 m-0"
            >
                {{ $t('Upload progress') }}
            </p>
            <button class="btn btn-icon m-1">
                <i :class="`${isCollapsed ? 'fd-chevron-up' : 'fd-chevron-down'} icon-dark`"></i>
            </button>
        </header>

        <section v-show="!isCollapsed">
            <ul class="w-100 files-box_list list list-unstyled">
                <FileItem
                    v-for="item in value"
                    :key="item.id"
                    tag="li"
                    :value="item"
                    class="item font-size-xs"
                />
            </ul>

            <div
                v-if="finishedAll"
                class="text-right w-100 d-flex justify-content-end px-2 pb-2"
            >
                <button
                    class="btn"
                    @click="clickClear"
                >
                    {{ $t('clear') }}
                </button>
            </div>
        </section>
    </aside>
</template>

<script>
import { mapActions } from 'pinia';

import useFilesStore from '@store/files-pinia';

import FileItem from '@components/FileLoaderBox/FileItem.vue';
import ProgressBar from '@components/ProgressBar/ProgressBar.vue';

export default {
    name: 'FileLoaderBox',

    components: {
        ProgressBar,
        FileItem,
    },

    props: {
        value: {
            type: [Array, FileList, Object],
            required: true,
            validator(value) {
                // TODO: check each item
                return !!value;
            },
        },
    },

    data() {
        return {
            isCollapsed: false,
        };
    },

    computed: {
        progress() {
            if (this.value) {
                return (
                    this.value.filter((item) => !item.error).reduce((sum, a) => sum + a.progress, 0) / this.value.length
                );
            }

            return 0;
        },
        finishedAll() {
            return this.value.some((item) => item.done || item.error);
        },
    },

    methods: {
        ...mapActions(useFilesStore, {
            clear: 'clear',
        }),
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        clickClear() {
            this.clear();
        },
    },
};
</script>

<style lang="scss" scoped>
.files-box {
    padding-top: 0.5rem;
    z-index: 9999999;
    min-width: 300px;
    background: $modal-content-bg;
    box-shadow: $box-shadow-lg;

    @include media-breakpoint-down(md) {
        max-width: 95vw;
    }

    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: $modal-inner-padding;
    }

    &_list {
        padding-left: $modal-inner-padding;
        padding-top: 0;
        margin-bottom: 0;
    }

    .item {
        word-bottom: break-all;
        width: 100%;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
</style>
