import { vm } from '@main';
import Vue from 'vue';

import authService from '@services/auth.service';
import ChatService from '@services/chat.service';
import settingsService from '@services/settings.service';

import { STOP_VIEW_LOADER } from '@store/modules/loader/constants';
import { SET_USER_PROFILE } from '@store/modules/profile/constants';

import { COUNTRIES, EMAILS, PHONES } from '@common/constants';

import { SET_CURRENT_TRANSLATIONS_FOR, SET_LANGUAGE, SET_TRANSLATES } from './constants';

// in order to change languages quickly on register, language objects also have the FoodDocs in-system country ID e.g America = 230
// (only languages we actually support)

// other languages besides English
const languages = {
    et: {
        key: 'et',
        flag: 'ee',
        text: 'Eesti',
        id: 63,
    },
    de: {
        key: 'de',
        flag: 'de',
        text: 'Deutsch',
        id: 56,
    },
    uk: {
        key: 'uk',
        flag: 'ua',
        text: 'Українська',
        id: 227,
    },
    ru: {
        key: 'ru',
        flag: 'ru',
        text: 'Русский',
        id: 190,
    },
    es: {
        key: 'es',
        flag: 'es',
        text: 'Español',
        id: 67,
    },
    lv: {
        key: 'lv',
        flag: 'lv',
        text: 'Latviešu',
    },
};

// shown in UI settings + company/place setups
const languagesWithEnglishVariations = {
    enUs: {
        key: 'en',
        flag: 'gb',
        text: 'English (United States)',
        id: 230,
    },
    enCA: {
        key: 'en_CA',
        flag: 'ca',
        text: 'English (Canada)',
        id: 36,
    },
    enAU: {
        key: 'en_AU',
        flag: 'au',
        text: 'English (Australia)',
        id: 14,
    },
    enGB: {
        key: 'en_GB',
        flag: 'gb',
        text: 'English (United Kingdom)',
        id: 76,
    },
    ...languages,
};

const displayFrontPage = {
    en: {
        key: 'en',
        flag: 'gb',
        text: 'English',
        id: 230,
    },
    ...languages,
};
const state = {
    languages: displayFrontPage,
    languagesWithEnglishVariations: languagesWithEnglishVariations,
    language: localStorage.getItem('lang'),
    translates: {},
    currentTranslationsFor: null,
};

const mutations = {
    [SET_LANGUAGE](state, key) {
        if (typeof key === 'string') {
            state.language = key;
            vm.$i18n.locale = state.language;
            localStorage.setItem('lang', state.language);
            vm.$cookies.set('lang', state.language, '10y');
            vm.$i18n.setLocaleMessage(key, state.translates[key]);
        } else {
            throw new TypeError('Key should be lang key (string)');
        }
    },

    [SET_TRANSLATES](state, { key, data }) {
        Vue.set(state.translates, key, data);
        vm.$i18n.setLocaleMessage(key, data);
    },

    [SET_CURRENT_TRANSLATIONS_FOR](state, key) {
        state.currentTranslationsFor = key;
    },
};

const actions = {
    async [SET_LANGUAGE]({ commit, getters }, key) {
        if (!key) {
            throw new TypeError("Can't find lang parameter");
        }

        if (getters.language !== key || !getters.hasTranslate(key)) {
            commit(SET_TRANSLATES, { key, data: 'in-progress' });

            settingsService
                .getTranslates(key)
                .then((data) => {
                    commit(SET_TRANSLATES, {
                        key,
                        data,
                    });
                    commit(SET_CURRENT_TRANSLATIONS_FOR, key);

                    ChatService.refresh();
                })
                .finally(() => {
                    commit(STOP_VIEW_LOADER);
                });

            if (authService.isAuthorized) {
                const profile = getters.userProfile;
                profile.lang = key;
                commit(SET_USER_PROFILE, profile);
                if (!getters['shared-access/isSharedAccess']) {
                    settingsService.changeAccountLang(key);
                }
            }
            commit(SET_LANGUAGE, key);
        }
    },
};

const getters = {
    languagesWithEnglishVariations(state) {
        return Object.keys(state.languagesWithEnglishVariations).map(
            (key) => state.languagesWithEnglishVariations[key]
        );
    },
    languages(state) {
        return Object.keys(state?.languages).map((key) => state.languages[key]);
    },
    language(state) {
        return state?.language || 'en';
    },
    currentTranslationsFor(state) {
        return state.currentTranslationsFor;
    },
    hasTranslate: (state) => (key) => {
        return !!state.translates[key];
    },
    email: (state, getters) => {
        const countryId = getters.placeCountryId;
        if (countryId === COUNTRIES.UKRAINE) {
            return EMAILS.UKRAINE;
        } else if (countryId === COUNTRIES.GERMANY) {
            return EMAILS.GERMANY;
        }
        return EMAILS.GENERAL;
    },
    phone: (state, getters) => {
        const countryId = getters.placeCountryId;
        if (countryId === COUNTRIES.UKRAINE) {
            return PHONES.UKRAINE;
        } else if (countryId === COUNTRIES.ESTONIA) {
            return PHONES.ESTONIA;
        } else if ([COUNTRIES.UNITED_KINGDOM, COUNTRIES.IRELAND].includes(countryId)) {
            return PHONES.UNITED_KINGDOM;
        }
        return null;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
