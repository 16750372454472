<template>
    <BaseModal
        ref="modal"
        size="md"
        modal-class="baseModal"
        footer-class="modalFooter"
        hide-header
        centered
    >
        <template #default>
            <img
                src="/assets/img/illu_celebrate.svg"
                class="modal-image"
                alt=""
            />

            <slot></slot>
        </template>

        <template #modal-footer>
            <span>
                <div class="text-center d-flex align-items-center justify-content-center">
                    <button
                        class="btn btn-outline-primary mx-1"
                        @click="$emit('cancel')"
                    >
                        {{ $t('Cancel') }}
                    </button>
                    <button
                        class="btn btn-primary mx-1"
                        @click="$emit('submit')"
                    >
                        {{ $t('continue') }}
                    </button>
                </div>
                <div class="text-center mt-5">
                    {{ $t('Not sure yet') }} <a :href="mailto">{{ $t('take-contact-with-fooddocs') }}</a>
                </div>
            </span>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@components/BaseModal';

export default {
    name: 'NeedToPayNotification',

    components: { BaseModal },

    emits: ['cancel', 'submit'],

    computed: {
        shown() {
            return this.$refs.modal.shown;
        },
        mailto() {
            return `mailto:${process.env.SUPPORT_EMAIL}`;
        },
    },

    methods: {
        hide() {
            this.$refs.modal.hide();
        },
        show() {
            this.$refs.modal.show();
        },
    },
};
</script>
