import { vm } from '@main';
import cloneDeep from 'lodash/cloneDeep';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import http from '@http';

import { Worker } from '@common/types/Worker';

interface UsersState {
    items: { [key: string]: User };
}

function state(): UsersState {
    return {
        items: {},
    };
}

const getters: GetterTree<UsersState, any> = {
    getUser:
        (state) =>
        (id: number): User =>
            state.items[id],
    users: (state): User[] => Object.keys(state.items).map((id) => state.items[id]),
};

const mutations: MutationTree<UsersState> = {
    set(state, payload) {
        state.items[payload.id] = payload;
    },
    delete(state, id: number) {
        delete state.items[id];
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions: ActionTree<UsersState, any> = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getAll({ commit, getters, rootGetters }): Promise<User | undefined> {
        try {
            commit('reset');
            const response = await http.get(`/api/places/${rootGetters.selectedPlaceId}/users`);

            response.data.forEach((user) => {
                commit('set', User.fromJSON(user));
            });

            return getters.users;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async get({ rootGetters }, id: number): Promise<User | undefined> {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/users/${id}`);

            return User.fromJSON(data);
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async create({ commit, rootGetters, dispatch }, payload: User): Promise<User | undefined> {
        try {
            const { data } = await http.post(`/api/places/${rootGetters.selectedPlaceId}/users`, payload.toJSON());
            const user = User.fromJSON(data);
            commit('set', user);

            await dispatch(
                'updateRoles',
                new User({
                    ...user,
                    placeAccesses: payload.placeAccesses,
                })
            );

            return user;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async update({ commit, dispatch, rootGetters }, payload: User): Promise<User | undefined> {
        try {
            const { data } = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/users/${payload.id}`,
                payload.toJSON()
            );
            const user = User.fromJSON(data);
            commit('set', user);

            await dispatch(
                'updateRoles',
                new User({
                    ...user,
                    placeAccesses: payload.placeAccesses,
                })
            );

            return user;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    async delete({ commit, rootGetters }, id: number): Promise<void> {
        try {
            await http.delete(`/api/places/${rootGetters.selectedPlaceId}/users/${id}`);

            commit('delete', id);
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.response?.data?.message || error?.message || 'error'));
            console.error(error);
        }
    },
    /**
     * Will trigger worker groups update cause
     * on the Backend side User receives roles from Workers groups
     */
    async updateRoles({ dispatch, commit, rootGetters }, user: User): Promise<void> {
        for (const place of user.placeAccesses!) {
            if (place?.roles?.length) {
                const worker = place.worker ? cloneDeep(place.worker) : {};
                worker.groups = place.roles.map((role) => role?.id);

                if (worker.id) {
                    await dispatch('team/update', { worker: new Worker(worker), placeId: place.id }, { root: true });
                } else {
                    worker.name = user.name || `${user.firstName} ${user.lastName}`;
                    worker.email = user.email;
                    await dispatch('team/create', { worker: new Worker(worker), placeId: place.id }, { root: true });
                }

                /**
                 * User-level roles is used to display roles in the table
                 */
                if (rootGetters.selectedPlaceId === +place.id!) {
                    user.roles = [...place.roles];
                }

                commit('set', user);
            }
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

type Role = { id: number; name: string };

export interface IUser {
    id?: number;
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    accessLevel?: string;
    companyAccessLevel?: string;
    roles?: Role[];
    status?: string;
    placeAccesses?: { id?: number; worker?: any; roles?: Role[]; name?: string; accessLevel?: string }[];
    hasSeenIntroSlides?: boolean;
}

export interface IUserJSON {
    id?: number;
    name?: string;
    first_name?: string;
    last_name?: string;
    username?: string;
    access_level?: string;
    company_access_level?: string;
    roles?: Role[];
    status?: string;
    place_accesses?: { fhp_id?: number; worker?: any; roles?: Role[]; fhp_name?: string; access_level?: string }[];
    has_seen_intro_slides?: boolean;
}

export class User implements IUser {
    id?: number;
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    accessLevel?: string;
    companyAccessLevel?: string;
    roles?: Role[];
    status?: string;
    placeAccesses?: { id?: number; worker?: any; roles?: Role[]; name?: string; accessLevel?: string }[];
    hasSeenIntroSlides?: boolean;

    constructor(data?: IUser) {
        this.id = data?.id;
        this.name = data?.name || '';
        this.firstName = data?.firstName || '';
        this.lastName = data?.lastName || '';
        this.email = data?.email || '';
        this.accessLevel = data?.accessLevel || '';
        this.companyAccessLevel = data?.companyAccessLevel || '';
        this.roles = data?.roles || [];
        this.status = data?.status || '';
        this.placeAccesses = data?.placeAccesses || [];
        this.hasSeenIntroSlides = data?.hasSeenIntroSlides || true;
    }

    static fromJSON(json: any): User {
        return new User({
            id: json.id,
            name: json.name,
            firstName: '',
            lastName: '',
            email: json.username,
            accessLevel: json.access_level,
            companyAccessLevel: json.company_access_level,
            roles: json.roles,
            status: json.status,
            placeAccesses:
                json.place_accesses?.map((i) => ({
                    id: i.fhp_id,
                    name: i.fhp_name,
                    accessLevel: i.access_level,
                    worker: i.worker,
                    roles: i.roles,
                })) || [],
            hasSeenIntroSlides: json.has_seen_intro_slides,
        });
    }

    toJSON(): IUserJSON {
        return {
            id: this.id,
            name: this.name || `${this.firstName} ${this.lastName}`,
            last_name: this.lastName,
            first_name: this.firstName,
            username: this.email,
            company_access_level: this.companyAccessLevel,
            status: this.status,
            place_accesses: this.placeAccesses?.map((i) => ({
                fhp_id: i.id,
                fhp_name: i.name,
                access_level: i.accessLevel,
                roles: i.roles,
                worker: i.worker,
            })),
            has_seen_intro_slides: this.hasSeenIntroSlides,
        };
    }
}
