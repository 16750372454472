<template>
    <div class="d-flex flex-column not-found">
        <AppHeader :class="`${$store.getters['global/showHeader'] ? '' : 'd-none'}`" />
        <div class="container not-found__block">
            <div class="row">
                <div class="col-md-7 mb-5 mb-md-0 d-flex flex-column justify-content-center align-items-start">
                    <h1>{{ $t('The page you are looking for doesn’t exist') }}</h1>
                    <p>{{ $t('You may have mistyped the address or the page may have moved.') }}</p>
                    <RouterLink
                        type="button"
                        class="btn btn-primary"
                        :to="{ name: 'overview' }"
                    >
                        {{ $t('Go to homepage') }}
                    </RouterLink>
                </div>
                <div class="col-md-5 text-center">
                    <img
                        class="img-fluid"
                        src="/assets/img/page_not_found.png"
                        :alt="$t('Page not found')"
                    />
                </div>
            </div>
        </div>
        <!--Commented out due to hubsbot bot FD-5453-->
        <!--<AppFooter />-->
    </div>
</template>

<script>
import AppHeader from '@components/layout/Header';
// import AppFooter from '@components/layout/Footer';

export default {
    components: {
        AppHeader,
        // AppFooter,
    },
};
</script>

<style>
.not-found {
    min-height: 100vh;
}

.not-found__block {
    margin-top: 85px;
}
</style>
