import { EntryField } from '@common/types/EntryField';

export interface ITaskEntry {
    id?: number;
    taskId?: number;
    fileList?: any[];
    entryFields?: EntryField[];
    taskDesc?: string;
}

export class TaskEntry implements ITaskEntry {
    public id?: number;
    public taskId?: number;
    public fileList?: any[];
    public entryFields?: EntryField[];
    public taskDesc?: string;

    constructor(data?: ITaskEntry) {
        this.id = data?.id;
        this.taskId = data?.taskId;
        this.fileList = data?.fileList || [];
        this.entryFields = data?.entryFields || [];
        this.taskDesc = data ? data.taskDesc : '';
    }

    static fromJSON(json: any) {
        return new TaskEntry({
            id: json.id,
            taskId: json.task_id,
            fileList: json.file_list,
            entryFields: json.entry_fields.map(EntryField.fromJSON),
        });
    }

    toJSON() {
        const json = {
            task_id: this.taskId,
            file_list: this.fileList,
            entry_fields: this.entryFields?.map((field) => field.toJSON()),
        };

        this.id ? (json['id'] = this.id) : null;

        return json;
    }
}
