<template>
    <Transition name="jump-down">
        <fd-progress-bar
            v-show="show"
            :percent="percent"
            class="w-100"
            @after-leave="afterLeave"
        ></fd-progress-bar>
    </Transition>
</template>

<script>
export default {
    name: 'ProgressBar',

    props: {
        percent: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['after-leave'],

    methods: {
        afterLeave() {
            this.$emit('after-leave');
        },
    },
};
</script>
