// FdProgressBar.ts
class FdProgressBar extends HTMLElement {
    private progressBar: HTMLDivElement;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.progressBar = document.createElement('div');
        this.render();
    }

    connectedCallback(): void {
        this.updateProgress(parseInt(this.getAttribute('percent') || '0'));
    }

    static get observedAttributes(): string[] {
        return ['percent'];
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
        if (name === 'percent' && newValue) {
            this.updateProgress(parseInt(newValue));
        }
    }

    private render(): void {
        const style = document.createElement('style');
        style.textContent = `
            :host {
                display: block;
                width: 100%;
                background-color: #E6E6E6;
                border-radius: 10px;
                box-sizing: border-box;
            }
            .progress-bar {
                height: 0.8rem;
                background-color: #00c06d;
                width: 0%; /* Initial width */
                border-radius: 10px;
                transition: width 0.3s ease;
            }
        `;

        this.progressBar.className = 'progress-bar';
        this.shadowRoot!.appendChild(style);
        this.shadowRoot!.appendChild(this.progressBar);
    }

    private updateProgress(percent: number): void {
        this.progressBar.style.width = `${Math.min(Math.max(percent, 0), 100)}%`;
    }

    // Allow dynamic updates from JavaScript
    set percent(value: number) {
        this.setAttribute('percent', value.toString());
        this.updateProgress(value);
    }

    get percent(): number {
        return parseInt(this.getAttribute('percent') || '0');
    }
}

export default FdProgressBar;
