// Mutations
export const SET_COMPANIES = 'SET_COMPANIES';
export const RESTORE_COMPANY = 'RESTORE_COMPANY';
export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const SET_COMPANY_TOKENS = 'SET_COMPANY_TOKENS';
export const STORE_COMPANY_AND_PLACE = 'STORE_COMPANY_AND_PLACE';
export const SET_SELECTED_COMPANY_RECURRING_PAYMENTS = 'SET_SELECTED_COMPANY_RECURRING_PAYMENTS';
export const SET_SELECTED_COMPANY_PERIODS = 'SET_SELECTED_COMPANY_PERIODS';
export const DELETE_PLACE = 'company/DELETE_PLACE';

// Actions
export const PUSH_NEW_PLACE = 'PUSH_NEW_PLACE';
export const START_INIT_COMPANIES = 'START_INIT_COMPANIES';
export const INIT_COMPANIES = 'INIT_COMPANIES';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_HARD = 'FETCH_COMPANIES_HARD';
export const START_COMPANY_CREATION = 'START_COMPANY_CREATION';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const FETCH_COMPANY_TOKENS = 'FETCH_COMPANY_TOKENS';
export const RESTORE_COMPANY_AND_PLACE = 'RESTORE_COMPANY_AND_PLACE';
export const DELETE_COMPANY = 'DELETE_COMPANY';

// Both
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const ADD_COMPANY_TOKEN = 'ADD_COMPANY_TOKEN';
export const DELETE_COMPANY_TOKEN = 'DELETE_COMPANY_TOKEN';
