import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal', {
    state: () => ({
        showModal: false,
    }),
    actions: {
        show() {
            this.showModal = true;
        },
        hide() {
            this.showModal = false;
        },
    },
});
