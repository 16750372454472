export const DEFAULT_PAGE_STATE = {
    perPage: 25,
    totalItems: 0,
    page: 1,
};

export const COUNTRIES = {
    GERMANY: 56,
    ESTONIA: 63,
    UNITED_KINGDOM: 76,
    IRELAND: 101,
    UKRAINE: 227,
    UNITED_STATES: 230,
};

export const EMAILS = {
    GENERAL: 'info@fooddocs.eu',
    UKRAINE: 'ukraine@fooddocs.eu',
    GERMANY: 'germany@fooddocs.eu',
};

export const PHONES = {
    ESTONIA: '+372 605 7005',
    UKRAINE: '+380 98 320 2442',
    UNITED_KINGDOM: '+44 330 094 8049',
};

export const KILOGRAM_ID = 5;
export const MILLILITER_ID = 6;
export const GRAM_ID = 7;
export const LITER_ID = 4;
export const PORTION_ID = 3;

export const MILLI_UNIT_IDS = [MILLILITER_ID, GRAM_ID];
export const UNIT_IDS = [KILOGRAM_ID, LITER_ID];

// Device types
export const COLD_STORAGE_ID = 1;
export const HOT_STORAGE_ID = 2;
export const PRODUCTION_TRACEABILITY_ID = 5;
export const OTHER = 7;
export const PEST_CONTROL = 8;
export const DEVICES = {
    COLD_STORAGE_ID: 1,
    HOT_STORAGE_ID: 2,
    COMPANY_SUPPLIER: 4,
    OTHER: 7,
    PEST_CONTROL: 8,
    TASKS: 13,
    CLEANING_AND_DISINFECTION_TASKS: 9,
    SUPERVISORY_TASKS: 14,
    ANALYSES: 10,
    CALIBRATION: 11,
    MAINTENANCE: 12,
};

export const BOUGHT_IN_PRODUCTS_ID = 78;

export const MISSING_LABEL = 'MISSING';
export const MISSING_NAME_LABEL_PLACE = 'Place name -- MISSING';
export const MISSING_NAME_LABEL_COMPANY = 'Company name -- MISSING';

// Roles
export const ROLES = {
    MEMBER: 'member',
    OWNER: 'owner',
};

// File Types
export const IMAGE_ONLY = ['png', 'jpeg', 'jpg'];
export const IMAGE_AND_PDF = [...IMAGE_ONLY, 'pdf'];
export const VIDEO_ONLY = ['mp4', 'webm', 'avi', 'ogg', 'mpeg'];
export const IMAGE_AND_VIDEO = [...IMAGE_ONLY, ...VIDEO_ONLY];

export const FILE_SIZE_5MB = 5120;
export const FILE_SIZE_7MB = 7864320;

export const DEFAULT_PENDING = 700;

export const PAYMENT_REQUIRED = 402;

export const CUSTOM_INGREDIENT = 'custom_ingredient';

export const LANG_LIST = [
    {
        key: 'en',
        fullname: 'english',
        shortDisplay: 'en',
        text: 'English',
    },
    {
        key: 'et',
        fullname: 'estonian',
        shortDisplay: 'et',
        text: 'Eesti',
    },
    {
        key: 'ru',
        fullname: 'russian',
        shortDisplay: 'ru',
        text: 'Русский',
    },
    {
        key: 'uk',
        fullname: 'ukrainian',
        shortDisplay: 'ukr',
        text: 'Українська',
    },
    {
        key: 'de',
        fullname: 'german',
        shortDisplay: 'de',
        text: 'Deutsch',
    },
    {
        key: 'es',
        fullname: 'Español',
        shortDisplay: 'es',
        text: 'Español',
    },
];

export const TERM_OF_USE = {
    en: 'https://www.fooddocs.com/termsofuse',
    et: 'https://www.fooddocs.com/et/kasutustingimused',
    de: 'https://www.fooddocs.com/de/termsofuse',
    ru: 'https://www.fooddocs.com/ru/termsofuse',
    uk: 'https://www.fooddocs.com/uk/termsofuse',
    lv: 'https://www.fooddocs.com/lv/termsofuse',
};

export const PRIVACY_POLICY = {
    en: 'https://www.fooddocs.com/privacy',
    et: 'https://www.fooddocs.com/et/privaatsuspoliitika',
    de: 'https://www.fooddocs.com/de/privacy',
    ru: 'https://www.fooddocs.com/ru/privacy',
    uk: 'https://www.fooddocs.com/uk/privacy',
    lv: 'https://www.fooddocs.com/lv/privacy',
};

export const MEASUREMENT_RATIOS = {
    4: 1,
    5: 1,
    6: 1000,
    7: 1000,
    8: 1 / 3.785411784,
    9: 1 / 0.45359237,
    10: 1 / 0.0295735295625,
    11: 1 / 0.028349523125,
    13: 1 / 4.54609,
    14: 1 / 0.45359237,
    15: 1 / 0.0284130625,
    16: 1 / 0.028349523125,
};

export const APP_LINKS = {
    GOOGLE_PLAY:
        'https://play.google.com/store/apps/details?id=eu.fooddocs.operator&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    APPLE_STORE: 'https://apps.apple.com/us/app/fooddocs/id1487448481',
};

export const SUBSCRIPTION_PACKAGES = {
    TRIAL: 1,
    FAST_HACCP: 10,
    LITE_FSMS: 11,
    FULL_FSMS: 12,
};

export const PRICES = {
    FAST_HACCP_YEARLY: 588,
    LITE_FSMS_MONTHLY: 79,
    LITE_FSMS_YEARLY: 828,
    FULL_FSMS_MONTHLY: 279,
    FULL_FSMS_YEARLY: 2748,
};

export const FSP_PRODUCT_GROUP_SETUP = {
    FSP_PRODUCTS_FILLED: 14,
    FOOD_GROUPS_FILLED: 9,
    PRODUCTION_PROCESSES_FILLED: 6,
    FLOW_DIAGRAM_CREATED: 11,
};
