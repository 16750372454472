<template>
    <div class="w-100">
        <InfoBox id="setup.company.intro_text">
            <span v-html="$t('setup.company.intro_text')"></span>
        </InfoBox>

        <CompanyForm
            :value="selectedCompany"
            @submit="submit"
        />
    </div>
</template>

<script>
import { mapActions } from 'pinia';
import { mapGetters } from 'vuex';

import { STORE_PROGRESS } from '@store/modules/common/constants';
import { UPDATE_COMPANY } from '@store/modules/company/constants';
import { useLoaderStore } from '@store/pinia-utils/loader';

import InfoBox from '@components/InfoBox/InfoBox';

import { authMixin } from '@views/Auth/mixins/auth.mixin';

import CompanyForm from './CompanyForm';

export default {
    components: {
        InfoBox,
        CompanyForm,
    },

    mixins: [authMixin],

    computed: {
        ...mapGetters(['selectedCompany']),
    },

    methods: {
        ...mapActions(useLoaderStore, ['setLoading']),
        async submit({ values }) {
            this.setLoading(true);

            let response;
            const data = {
                address: values.address,
                country_id: values.country?.id,
                state_id: values.state?.id,
                name: values.name,
                preferred_language: values.preferredLanguage?.key,
                reg_code: values.regCode || null,
                vat_number: values.vatCode,
                email: values.email,
                volume_system: values.volumeSystem,
                weight_system: values.weightSystem,
                temperature_scale: values.tempScale,
                entry_created_at_hidden: values.entryCreatedAtHidden,
                is_temp_predictions_enabled: values.tempPredictionsEnabled,
                production_currency_id: values.currency?.id,
                date_format_id: values.dateFormat,
            };
            try {
                response = await this.$store.dispatch(UPDATE_COMPANY, {
                    companyId: this.selectedCompany.company.id,
                    data,
                });
            } catch (e) {
                console.error(e);
                this.$toastr.e(this.$t('error'));
            }

            if (this.$store.state.register.sessionId) {
                await this.$store.dispatch(STORE_PROGRESS, {
                    sessionId: this.$store.state.register.sessionId,
                    company: {
                        name: values.name,
                        email: values.email,
                        regCode: values.regCode,
                        varCode: values.varCode,
                        address: values.address,
                    },
                });
            }

            if (response) {
                this.$toastr.s(this.$t('success'));
            }
            this.setLoading(false);
        },
    },
};
</script>
