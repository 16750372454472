import http from '@http';
import { TaskSubtype } from '@common/types/TaskSubtype';

function state() {
    return {
        values: [],
    };
}

const mutations = {
    setValues(state, payload) {
        state.values = payload;
    },
};

const actions = {
    async getAll({ commit }): Promise<void> {
        try {
            const response = await http.get(`/api/classifiers/team-task-subtypes`).then(({ data }) => data);
            const values = response.map(TaskSubtype.fromJSON);
            commit('setValues', values);
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    getAll: (state) => state.values,
    checkInfinity: () => (val) => {
        const infinities = ['once', 'with_health_control', 'once_in_manning'];
        return infinities.includes(val);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
