import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default () => {
    let locale = Vue.prototype.$cookies.get('lang');
    if (!locale) {
        locale = localStorage.getItem('lang') || 'en';
    }

    return new VueI18n({
        locale,
        messages: {},
        silentTranslationWarn: true,
    });
};
