// Mutations
export const LOADED = 'audit/LOADED';
export const ADD_AUDIT_TEMPLATE = 'ADD_AUDIT_TEMPLATE';
export const UPDATE_AUDIT_TEMPLATE = 'UPDATE_AUDIT_TEMPLATE';
export const ADD_AUDIT_RESULT = 'ADD_AUDIT_RESULT';
export const UPDATE_AUDIT_RESULT = 'UPDATE_AUDIT_RESULT';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_RESULTS = 'SET_RESULTS';
export const UPDATE_CONFIRM_AUDIT = 'audit/UPDATE_CONFIRM_AUDIT';

// Actions
export const INIT_AUDITS = 'INIT_AUDITS';
export const RESET_AUDITS = 'RESET_AUDITS';
export const DOWNLOAD_VTA_FILE = 'DOWNLOAD_VTA_FILE';

// Both
export const DELETE_AUDIT_TEMPLATE = 'DELETE_AUDIT_TEMPLATE';
export const DELETE_AUDIT_RESULT = 'DELETE_AUDIT_RESULT';
