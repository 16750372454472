<template>
    <div class="wrapper">
        <SideNav />

        <PortalTarget
            name="sidemenu"
            class="d-none d-md-block"
        />

        <div class="d-flex flex-column w-100">
            <AppHeader
                ref="app-header"
                :class="`app-header ${$store.getters['global/showHeader'] || hideNav ? '' : 'd-none'}`"
            />

            <div
                class="d-flex flex-md-grow-1 app-content-margin-top"
                :class="{
                    'd-none': hideContent,
                    'extended-header': showBillingDaysLeftNotification,
                }"
            >
                <router-view
                    v-if="$store.getters.isCompaniesInited"
                    name="base"
                />
                <PortalTarget
                    name="sidemenu"
                    class="d-md-none"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@components/layout/Header';
import SideNav from '@components/SideNav';

export default {
    components: {
        SideNav,
        AppHeader,
    },

    data() {
        return {
            showBillingDaysLeftNotification: false,
        };
    },

    computed: {
        hideNav() {
            return this.$route.meta.hideNav;
        },
        hideContent() {
            return this.$store.getters['global/mobileHeaderOpen'] && this.$store.getters['isMobileView'];
        },
    },

    mounted() {
        this.showBillingDaysLeftNotification = this.$refs['app-header']?.showBillingDaysLeftNotification;
    },
};
</script>

<style lang="scss" scoped>
.d-flex.flex-column,
.wrapper {
    min-height: 100vh;
}

.app-content {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        width: 100vw;
        max-width: 100vw;
    }
}
</style>
