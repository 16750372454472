import { vm } from '@main';

import http from '@http';

import store from '@store';

function state() {
    return {
        data: [],
    };
}

const getters = {
    data: (state) => state.data,
};

const mutations = {
    setData(state, payload) {
        state.data = payload;
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getAll({ rootGetters, commit }, fspProductGroupId) {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/hazards`, {
                params: {
                    fsp_product_group_id: fspProductGroupId,
                },
            });
            commit('setData', data.map(Hazard.fromJSON));
        } catch (error) {
            console.error(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

interface IHazard {
    id: number;
    name: string;
    hazardHtml: string;
    level: string;
    auditName: string;
    auditId: number;
    parentId: number;
    parentStaticName: string;
}

class Hazard implements IHazard {
    id = 0;
    name = '';
    hazardHtml = '';
    level = '';
    auditName = '';
    auditId = 0;
    parentId = 0;
    parentStaticName = '';

    constructor(data: IHazard) {
        this.id = data.id;
        this.name = data.name;
        this.hazardHtml = data.hazardHtml;
        this.level = data.level;
        this.auditName = data.auditName;
        this.auditId = data.auditId;
        this.parentId = data.parentId;
        this.parentStaticName = data.parentStaticName;
    }

    static fromJSON(json: any): Hazard {
        return new Hazard({
            id: json?.id,
            name: decipherAPIString(json?.section_name), // TODO: Should transform Array to object
            hazardHtml: decipherAPIString(json?.hazard?.section_translations), // TODO: Should transform array to an object
            level: json?.level,
            auditName: json?.audit?.name,
            auditId: json?.audit?.id,
            parentId: json?.parent_id,
            parentStaticName: json?.parent_static_name,
        });
    }
}

function decipherAPIString(string) {
    // TODO: STORE temporary here
    const langs = store.getters['plan/getLanguageIds'];
    const currentLang = langs.filter((lang) => lang.iso2 === vm.$i18n.locale);
    const currentLangId = currentLang?.length && currentLang[0].id ? currentLang[0].id : 43;
    try {
        return string.filter((s) => s.language_id === currentLangId)[0].translation_text;
    } catch (e) {
        try {
            const currentLangId = langs.filter((lang) => lang.iso2 === 'en')[0].id;
            return string.filter((s) => s.language_id === currentLangId)[0].translation_text;
        } catch (e) {
            return '';
        }
    }
}
