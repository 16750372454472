import http from '@http';

function state() {
    return {
        generalInfoProfile: null,
        foodGroupProfiles: [],
        productGroupProfiles: [],
        workGroupProfiles: [],
        analysesTaskProfiles: [],
    };
}

const mutations = {
    setGeneralInfoProfile(state, payload) {
        state.generalInfoProfile = payload;
    },
    setFoodGroupProfiles(state, payload) {
        state.foodGroupProfiles = payload;
    },
    setProductGroupProfiles(state, payload) {
        state.productGroupProfiles = payload;
    },
    setWorkGroupProfiles(state, payload) {
        state.workGroupProfiles = payload;
    },
    setAnalysesTaskProfiles(state, payload) {
        state.analysesTaskProfiles = payload;
    },
};

const actions = {
    async getGeneralInfoProfile(
        { commit, rootGetters },
        { placeId, businessActivityId, extraDataIds, locationTypeId }
    ) {
        try {
            if (rootGetters['shared-access/isSharedAccess'] || !placeId) {
                return false;
            }

            const params = {
                business_activity_id: businessActivityId,
                extra_data: Array.isArray(extraDataIds) && extraDataIds.length ? extraDataIds.join() : undefined,
                location_type: locationTypeId,
            };

            const { data } = await http.get(`/api/places/${placeId}/onboarding-profiles/general-info`, {
                params,
            });
            commit('setGeneralInfoProfile', data);
        } catch (error) {
            console.error(error);
        }
    },
    async getFoodGroupProfiles({ commit, rootGetters }, placeId) {
        try {
            if (rootGetters['shared-access/isSharedAccess'] || !placeId) {
                return false;
            }

            const { data } = await http.get(`/api/places/${placeId}/onboarding-profiles/food-groups`);
            commit('setFoodGroupProfiles', data);
        } catch (error) {
            console.error(error);
        }
    },
    async getProductGroupProfiles({ commit, rootGetters }, placeId) {
        try {
            if (rootGetters['shared-access/isSharedAccess'] || !placeId) {
                return false;
            }

            const { data } = await http.get(`/api/places/${placeId}/onboarding-profiles/product-groups`);
            commit('setProductGroupProfiles', data);
        } catch (error) {
            console.error(error);
        }
    },
    async getWorkGroupProfiles({ commit, rootGetters }, placeId) {
        try {
            if (rootGetters['shared-access/isSharedAccess'] || !placeId) {
                return false;
            }

            const { data } = await http.get(`/api/places/${placeId}/onboarding-profiles/work-groups`);
            commit('setWorkGroupProfiles', data);
        } catch (error) {
            console.error(error);
        }
    },
    async getAnalysesTaskProfiles({ commit, rootGetters }, placeId) {
        try {
            if (rootGetters['shared-access/isSharedAccess'] || !placeId) {
                return false;
            }

            const { data } = await http.get(`/api/places/${placeId}/onboarding-profiles/analyses-tasks`);
            commit('setAnalysesTaskProfiles', data);
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    generalInfoProfile(state) {
        return state.generalInfoProfile;
    },
    foodGroupProfiles(state) {
        return state.foodGroupProfiles;
    },
    productGroupProfiles(state) {
        return state.productGroupProfiles;
    },
    workGroupProfiles(state) {
        return state.workGroupProfiles;
    },
    analysesTaskProfiles(state) {
        return state.analysesTaskProfiles;
    },
};

export default {
    state,
    namespaced: true,
    mutations,
    actions,
    getters,
};
