<template>
    <div
        class="order-0 order-lg-1 d-flex justify-content-center bg-warning font-size-xs px-2 border-radius align-items-center space-related-out py-2 px-3"
    >
        <span class="d-flex align-items-center">
            <i class="fd-info mr-2" />
            <span class="text-gray-700">
                {{ message }}
            </span>
        </span>
        <button
            v-if="buttonText"
            class="space-unrelated-out-l ml-2 btn btn-primary btn-black font-size-xs"
            @click="$emit('click')"
        >
            {{ buttonText }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'HeaderBanner',

    props: {
        message: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            default: null,
        },
    },

    emits: ['click'],
};
</script>

<style scoped lang="scss"></style>
