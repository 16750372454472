import http from '@http';
import { vm } from '@main';
import store from '@store';
import { GetterTree } from 'vuex';

interface IState {
    sectionIndexByStaticName: { [key: number]: number };
    sectionIndexById: { [key: number]: number };
    sections: Object[];
    requestControllers: any[];
    updateCurrentView: boolean;
    isLoading: boolean;
    isSearching: boolean;
}

function state(): IState {
    return {
        sectionIndexByStaticName: {},
        sectionIndexById: {},
        sections: [],
        requestControllers: [],
        updateCurrentView: false,
        isLoading: true,
        isSearching: false,
    };
}

const getters: GetterTree<IState, any> = {
    updateCurrentView: (state) => state.updateCurrentView,
    sectionIndexByStaticName: (state) => state.sectionIndexByStaticName,
    sectionIndexById: (state) => state.sectionIndexById,
    sections: (state) => state.sections,
    requestControllers: (state) => state.requestControllers,
    getSection: (state) => (identifier: any) => {
        const index = isNaN(identifier)
            ? state.sectionIndexByStaticName[identifier]
            : state.sectionIndexById[identifier];
        return state.sections[index];
    },
    isLoading: (state) => state.isLoading,
    isSearching: (state) => state.isSearching,
};

const mutations = {
    setIsCurrentViewUpdated(state, bool: boolean) {
        state.updateCurrentView = bool;
    },
    addSection(state, section) {
        if (!(section.id in state.sectionIndexById)) {
            state.sectionIndexById[section.id] = state.sections.length;
            state.sectionIndexByStaticName[section.staticName] = state.sections.length;
            state.sections.push(section.data);
        }
    },
    setSection(state, section) {
        state.sectionIndexById[section.id] = state.sections.length;
        state.sectionIndexByStaticName[section.staticName] = state.sections.length;
        state.sections.push(section.data);
    },
    addRequestController: (state, controller) => {
        state.requestControllers.push(controller);
    },
    setRequestControllers: (state, controllers) => {
        state.requestControllers = controllers;
    },
    setIsLoading: (state, status: boolean) => {
        state.isLoading = status;
    },
    setIsSearching: (state, status: boolean) => {
        state.isSearching = status;
    },
    clearAllData: (state) => {
        state.sectionIndexById = {};
        state.sectionIndexByStaticName = {};
        state.sections = [];
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ getters, commit }) {
            getters.requestControllers.forEach((controller) => {
                controller.abort();
            });
            commit('setRequestControllers', []);
        },
    },
    async getAllData({ getters, rootGetters, dispatch, commit }, full = false) {
        commit('setIsLoading', true);

        if (full) {
            commit('clearAllData');
        }

        let i = 3;
        const selectedPlanSections = rootGetters['plan/selectedPlanDetails']?.sections;
        const sections = selectedPlanSections ? [...selectedPlanSections] : [];
        sections.sort((a, b) => (a.on > b.on ? 1 : b.on > a.on ? -1 : 0));
        const requests: any[] = [];
        for (const section of sections) {
            let j = 1;
            const filteredChildren = section?.filtered_children;
            const fcs = filteredChildren ? [...filteredChildren] : [];
            fcs.sort((a, b) => (a.on > b.on ? 1 : b.on > a.on ? -1 : 0));
            for (const fc of fcs) {
                if (Object.keys(getters.sectionIndexById).includes(`${fc.id}`)) {
                    continue;
                }
                const index = `${i}.${j} `;
                requests.push(
                    dispatch('get', { id: fc.id }).then(({ data }) => {
                        if (data) {
                            commit('addSection', {
                                id: fc.id,
                                staticName: fc.static_name,
                                data: { ...data, index },
                            });
                        }
                    })
                );
                j++;
            }
            i++;
        }

        await Promise.all(requests);
        commit('setIsLoading', false);
    },
    async getSingle({ commit, dispatch, getters }, id) {
        const idExists = Object.keys(getters.sectionIndexById).includes(`${id}`);
        if (!idExists) {
            getters.requestControllers.forEach((controller) => {
                controller.abort();
            });
            commit('setRequestControllers', []);
        }
        const res = await dispatch('updateSingle', id);

        if (!idExists) {
            dispatch('getAllData');
        }

        return res;
    },
    async updateSingle({ dispatch, commit }, id) {
        if (!id) {
            return;
        }

        const { data } = await dispatch('get', { id: id });

        if (data) {
            commit('setSection', {
                id,
                staticName: data.sections[0].static_name,
                data: data,
            });
            return data;
        }
    },
    get({ commit, getters, rootGetters }, payload) {
        const controller = new AbortController();
        const res = http.get(
            `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/sections/${payload.id}`,
            {
                signal: controller.signal,
                params: {
                    fsp_product_group_id: payload.fspProductGroupId,
                },
            }
        );
        commit('addRequestController', controller);
        return res;
    },
    async getAllFspProductGroupSections({ commit, getters, rootGetters, dispatch }, ids) {
        return (await Promise.all(ids.map((id) => dispatch('get', id))))?.map((res: any) => res.data);
    },
    search({ getters, commit }, query) {
        if (query.length <= 0) {
            return;
        }

        commit('setIsSearching', true);
        const NUMBER_OF_MATCHES = 5;

        const exactResults: Section[] = [];
        const results: Section[] = [];
        const getLevel = (data, lvl2, index) => {
            if (exactResults.length > NUMBER_OF_MATCHES) {
                return;
            }
            const section = new Section({ ...data, lvl2, index });

            if (section.includesExactString(query)) {
                exactResults.push(section);
            } else if (section.includesEstimation(query)) {
                section.specialCase = true;
                results.push(section);
            } else if (section.includesString(query)) {
                results.push(section);
            }
            if (section.level < NUMBER_OF_MATCHES) {
                section.filteredChildren.forEach((child) => getLevel(child, lvl2, index));
            }
        };

        Object.values(getters.sectionIndexById).forEach((index) => {
            // @ts-ignore
            const section = getters.sections[index];
            getLevel(section.sections[0], section.sections[0], section.index);
        });

        commit('setIsSearching', false);

        return [
            ...exactResults.slice(0, NUMBER_OF_MATCHES),
            ...results.slice(0, exactResults.length < NUMBER_OF_MATCHES ? NUMBER_OF_MATCHES - exactResults.length : 0),
        ];
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

class Section {
    public id: number;
    public level: number;
    public lvl2: any;
    public index: string;
    public parentId: number;
    public filteredChildren: Array<{}>;
    public translations: Array<{}>;
    public subcontainers: Array<{ hazard_estimation }>;
    public specialCase?: boolean;

    constructor(data) {
        this.id = data.id;
        this.level = data.level_number;
        this.lvl2 = data.lvl2;
        this.parentId = data.parent?.id;
        this.filteredChildren = data.filtered_children;
        this.translations = data.translations;
        this.index = data.index;
        this.subcontainers = data.subcontainers;
        this.specialCase = data.specialCase ?? false;
    }

    get lvl2Title() {
        if (!this.lvl2?.translations) {
            return '';
        }

        return `${removeHTMLTag(decipherAPIString(this.lvl2.translations))}`;
    }

    get linkSlug() {
        return this.lvl2?.static_name;
    }

    get translation() {
        return removeHTMLTag(decipherAPIString(this.translations));
    }

    includesEstimation(string) {
        return this.subcontainers?.find(
            (container) =>
                container?.hazard_estimation &&
                Object.values(container.hazard_estimation)?.find(
                    (type: any) => !!vm.$t(type?.name)?.toLowerCase().includes(string.toLowerCase())
                )
        );
    }

    includesString(string) {
        return this.translation.toLowerCase().includes(string.toLowerCase());
    }

    includesExactString(string) {
        return this.translation
            .replace(/[.,;:]/g, '')
            .toLowerCase()
            .split(' ')
            .includes(string.toLowerCase());
    }
}

function removeHTMLTag(string) {
    return string.replace(/(<([^>]+)>)/gi, '');
}

function decipherAPIString(string) {
    // TODO: STORE temporary here
    const langs = store.getters['plan/getLanguageIds'];
    const currentLang = langs.filter((lang) => lang.iso2 === vm.$i18n.locale);
    const currentLangId = currentLang?.length && currentLang[0].id ? currentLang[0].id : 43;
    try {
        return string.filter((s) => s.language_id === currentLangId)[0].translation_text;
    } catch (e) {
        try {
            const currentLangId = langs.filter((lang) => lang.iso2 === 'en')[0].id;
            return string.filter((s) => s.language_id === currentLangId)[0].translation_text;
        } catch (e) {
            return '';
        }
    }
}
