import { vm } from '@main';
import json2csv from 'json2csv';

export class Factory {
    getConfig(taskName, date): { name: () => string; dateString: string; taskName: string } {
        return {
            name: () => {
                let name = this.formatName(taskName);
                if (!name.length) {
                    name = 'Activity log';
                }
                return name.toString() + date;
            },

            taskName: taskName,
            dateString: '_' + date,
        };
    }

    /**
     * The order and fields set for the CSV
     * The parser can't take translation functions directly, only translated strings.
     *
     * order:
     * date    measured    related unit    status?    fields     notes    update date    update time    reg by
     *
     * @param payload JSON
     * @param measurements activity log measurements + extra fields
     * @param verification determines what set of fields should be used depending on if verification is set or not
     * @constructor
     */
    CSVStrategy(payload: any, measurements: any, verification: boolean) {
        const date = vm.$t('kuupaev');
        const measured = vm.$t('measured');
        const notes = vm.$t('notes');
        const updated_at = vm.$t('updated_date');
        const time = vm.$t('updated_time');
        const registered_by = vm.$t('updatedByTranslation');
        const verification_date = vm.$t('verification date');
        const verification_time = vm.$t('verification time');
        const verified_by = vm.$t('verified by');
        const verification_comment = vm.$t('verification comment');

        const fields = verification
            ? [
                  date,
                  measured,
                  ...measurements,
                  notes,
                  updated_at,
                  time,
                  registered_by,
                  verification_date,
                  verification_time,
                  verified_by,
                  verification_comment,
              ]
            : [date, measured, ...measurements, notes, updated_at, time, registered_by];

        const parser = new json2csv.Parser({ fields });
        return parser.parse(payload);
    }

    CSVTicketsStrategy(payload: any) {
        const dateTranslation = vm.$t('kuupaev');
        const timeTranslation = vm.$t('time');
        const name = vm.$t('name');
        const notes = vm.$t('notes');
        const updateDateTranslation = vm.$t('updated_date');
        const updateTimeTranslation = vm.$t('updated_time');
        const registered_by = vm.$t('registered_by');
        const updatedByTranslation = vm.$t('updatedByTranslation');
        const statusTranslation = vm.$t('status');

        const fields = [
            name,
            dateTranslation,
            timeTranslation,
            registered_by,
            statusTranslation,
            notes,
            updateDateTranslation,
            updateTimeTranslation,
            updatedByTranslation,
        ];
        const parser = new json2csv.Parser({ fields });
        return parser.parse(payload);
    }

    /**
     * The order and fields set for the XLSX
     * The parser can't take translation functions directly, only translated strings.
     *
     * order:
     * date    measured    related unit    status?    extra fields    notes    update date    update time    reg by
     *
     * @param measurements activity log measurements + extra fields
     * @param verification determines what set of fields should be used depending on if verification is set or not
     */

    XLSXFields(measurements: any, verification: boolean) {
        const date = vm.$t('kuupaev');
        const measured = vm.$t('measured');
        const notes = vm.$t('notes');
        const updated_at = vm.$t('updated_date');
        const time = vm.$t('updated_time');
        const registered_by = vm.$t('updatedByTranslation');
        const verification_date = vm.$t('verification date');
        const verification_time = vm.$t('verification time');
        const verified_by = vm.$t('verified by');
        const verification_comment = vm.$t('verification comment');

        const fields = verification
            ? [
                  date,
                  measured,
                  ...measurements,
                  notes,
                  updated_at,
                  time,
                  registered_by,
                  verification_date,
                  verification_time,
                  verified_by,
                  verification_comment,
              ]
            : [date, measured, ...measurements, notes, updated_at, time, registered_by];

        const fieldValuePairs: any = [];

        fields.forEach((field) => {
            fieldValuePairs.push({
                label: field,
                value: field,
            });
        });

        return fieldValuePairs;
    }

    /**
     * File name needs to be checked for foreign characters
     * because some special characters break it so the file downloads nameless.
     * @param taskName
     */
    formatName(taskName) {
        let name = '';
        for (let i = 0; i < taskName.length; i++) {
            const letter = taskName.charAt(i);
            if (this.isAllowedCharacter(letter)) {
                name += letter;
            } else {
                name += this.replaceCharacter(letter);
            }
        }
        return name;
    }

    isAllowedCharacter(letter) {
        return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]|[a-zA-Z0-9]+/gu.test(letter);
    }

    replaceCharacter(letter) {
        const dict = {
            ä: 'a',
            Ä: 'A',
            à: 'a',
            À: 'A',
            â: 'a',
            Â: 'A',
            æ: 'a',
            Æ: 'A',
            ç: 'c',
            Ç: 'C',
            ë: 'e',
            Ë: 'E',
            é: 'e',
            É: 'E',
            è: 'e',
            È: 'E',
            ï: 'o',
            Ï: 'O',
            õ: 'o',
            Õ: 'O',
            ö: 'o',
            Ö: 'O',
            ô: 'o',
            Ô: 'O',
            œ: 'o',
            Œ: 'O',
            ß: 's',
            ẞ: 'S',
            ü: 'u',
            Ü: 'U',
            ù: 'u',
            Ù: 'U',
            û: 'u',
            Û: 'U',
            š: 's',
            Š: 's',
            ÿ: 'o',
            Ÿ: 'O',
            ž: 'z',
            Ž: 'Z',
            '€': 'eur',
            '£': 'pound',
        }; // eur and pound also break download
        return dict[letter] || '-';
    }

    XLSXTicketsStrategy(fields: any, taskName: string) {
        return [
            {
                sheet: taskName,
                columns:
                    fields.length > 0
                        ? Object.keys(fields[0]).map((name) => ({
                              label: name,
                              value: name,
                          }))
                        : [],
                content: fields,
            },
        ];
    }
}
