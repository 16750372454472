<template>
    <div class="container d-flex align-items-center justify-content-center">
        <CompanyForm
            class="w-50"
            @submit="submit"
        />
    </div>
</template>

<script>
import { mapActions } from 'pinia';

import { CREATE_COMPANY, SET_SELECTED_COMPANY } from '@store/modules/company/constants';
import { useLoaderStore } from '@store/pinia-utils/loader';

import CompanyForm from './CompanyForm';

export default {
    name: 'CompanyCreationView',

    components: {
        CompanyForm,
    },

    methods: {
        ...mapActions(useLoaderStore, ['setLoading']),
        async submit({ values }) {
            this.setLoading(true);

            const data = {
                name: values.name,
                address: values.address,
                country_id: values.country.id,
                email: values.email,
                preferred_language: values.preferredLanguage?.key || 'en',
                reg_code: values.regCode || null,
                vat_number: values.vatCode,
                production_currency_id: values.currency?.id,
            };

            data.sess_id = localStorage.getItem('sess_id');
            this.$store.dispatch(CREATE_COMPANY, { data }).then((res) => {
                this.$store.dispatch(SET_SELECTED_COMPANY, res.id);
                this.$toastr.s(this.$t('success'));
            });
            this.setLoading(false);
        },
    },
};
</script>
