// Mutations
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SET_BUSINESS_ACTIVITIES = 'SET_BUSINESS_ACTIVITIES';
export const SET_LOCATION_TYPES = 'SET_LOCATION_TYPES';
export const SET_CUSTOMER_GROUPS = 'SET_CUSTOMER_GROUPS';
export const SET_TIME_UNITS = 'SET_TIME_UNITS';
export const SET_PRODUCTION_UNITS = 'SET_PRODUCTION_UNITS';
export const SET_FOOD_GROUP_CATEGORIES = 'SET_FOOD_GROUP_CATEGORIES';
export const SET_PRODUCT_GROUPS = 'COMMON_SET_PRODUCT_GROUPS';
export const SET_ESUBSTANCES_LIST = 'SET_ESUBSTANCES_LIST';
export const SET_ADDITIVE_USAGES = 'SET_ADDITIVE_USAGES';
export const SET_PACKAGING_LIST = 'SET_PACKAGING_LIST';
export const SET_CLAIMS_LIST = 'SET_CLAIMS_LIST';
export const SET_IP_COUNTRY = 'SET_IP_COUNTRY';
export const SET_SHORT_PRODUCTION_UNITS = 'SET_SHORT_PRODUCTION_UNITS';
export const SET_PRICES_FOR_COUNTRY = 'SET_PRICES_FOR_COUNTRY';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PER_PAGE = 'SET_PER_PAGE';
export const SET_NEW_TIME_UNITS = 'SET_NEW_TIME_UNITS';

// Actions
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_NEW_TIME_UNITS = 'FETCH_NEW_TIME_UNITS';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const FETCH_BUSINESS_ACTIVITIES = 'FETCH_BUSINESS_ACTIVITIES';
export const FETCH_LOCATION_TYPES = 'FETCH_LOCATION_TYPES';
export const FETCH_CUSTOMER_GROUPS = 'FETCH_CUSTOMER_GROUPS';
export const FETCH_TIME_UNITS = 'FETCH_TIME_UNITS';
export const FETCH_PRODUCTION_UNITS = 'FETCH_PRODUCTION_UNITS';
export const FETCH_FOOD_GROUP_CATEGORIES = 'FETCH_FOOD_GROUP_CATEGORIES';
export const FETCH_PRODUCT_GROUPS = 'FETCH_PRODUCT_GROUPS';
export const FETCH_ESUBSTANCES_LIST = 'FETCH_ESUBSTANCES_LIST';
export const FETCH_ADDITIVE_USAGES = 'FETCH_ADDITIVE_USAGES';
export const FETCH_PACKAGING_LIST = 'FETCH_PACKAGING_LIST';
export const FETCH_CLAIMS_LIST = 'FETCH_CLAIMS_LIST';
export const FETCH_IP_COUNTRY = 'FETCH_IP_COUNTRY';
export const STORE_PROGRESS = 'STORE_PROGRESS';
export const FETCH_SHORT_PRODUCTION_UNITS = 'FETCH_SHORT_PRODUCTION_UNITS';
export const FETCH_TECHNOLOGIST_PRICE = 'FETCH_TECHNOLOGIST_PRICE';
export const LOG_ERROR = 'LOG_ERROR';
