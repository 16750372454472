// Mutations
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const REMOVE_USER_GROUP = 'REMOVE_USER_GROUP';

// Actions
export const INIT_USER_GROUPS = 'INIT_USER_GROUPS';

// Both
export const CREATE_USER_GROUP = 'CREATE_USER_GROUP';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
