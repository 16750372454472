function initState() {
    return {};
}

const getters = {
    isEntryCreatedAtShown: (state, getters, rootState, rootGetters) => {
        return !rootGetters.selectedCompany?.company?.entry_created_at_hidden;
    },
};

const mutations = {};

const actions = {};

export default {
    state: initState,
    getters,
    mutations,
    actions,
    namespaced: true,
};
