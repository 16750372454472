<template>
    <div class="row my-5 mx-auto">
        <div class="col-sm-12 col-md-6 px-5 align-self-center">
            <h2 class="mb-3">
                <slot name="title"></slot>
            </h2>
            <slot name="body-text">
                <p>
                    {{ $t(bodyText) }}
                </p>
            </slot>
            <div class="mt-3">
                <slot name="mainButton"></slot>
            </div>
            <div>
                <slot name="extraButton"></slot>
            </div>
        </div>
        <div class="d-none d-sm-none d-md-block col-6 align-self-center px-5">
            <img
                :src="pictureUrl"
                :alt="pictureAlt"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntroductionBody',

    props: {
        title: {
            type: String,
            default: '',
        },
        bodyText: {
            type: String,
            default: '',
        },
        pictureUrl: {
            type: String,
            default: '',
        },
        pictureAlt: {
            type: String,
            default: '',
        },
    },
};
</script>
