// Mutations
export const SET_INTERVAL = 'notifications/SET_INTERVAL';
export const SET_NOTIFICATIONS = 'notifications/SET_NOTIFICATIONS';
export const RESET_VISITED_STATE = 'notifications/RESET_VISITED_STATE';
export const OPEN_NOTIFICATIONS_VIEW = 'notifications/OPEN_NOTIFICATIONS_VIEW';
export const CLOSE_NOTIFICATIONS_VIEW = 'notifications/CLOSE_NOTIFICATIONS_VIEW';
export const UPDATE_NOTIFICATION_ITEM = 'notifications/UPDATE_NOTIFICATION_ITEM';
export const SET_SHOW_STATE_NOTIFICATION_DETAILS_VIEW = 'notifications/SET_SHOW_STATE_NOTIFICATION_DETAILS_VIEW';
export const SET_HIDE_STATE_NOTIFICATION_DETAILS_VIEW = 'notifications/SET_HIDE_STATE_NOTIFICATION_DETAILS_VIEW';
export const SET_LAST_SEEN_NOTIFICATION_DATE = 'notifications/SET_LAST_SEEN_NOTIFICATION_DATE';

// Actions
export const LOAD_NOTIFICATION_DETAILS = 'notifications/LOAD_NOTIFICATION_DETAILS';

// Both
export const INIT_NOTIFICATIONS = 'notifications/INIT_NOTIFICATIONS';
export const MARK_AS_VISITED = 'notifications/MARK_AS_VISITED';
