import http from '@http';

class AuditService {
    deletePhoto(responseId, photoId) {
        return http.delete(`/api/digital-audit-responses/${responseId}/photo/${photoId}`).then((res) => res.data);
    }

    getPhotoData(responseId, photoId) {
        return http.get(`/api/digital-audit-responses/${responseId}/base64-photo/${photoId}`).then((res) => res.data);
    }

    uploadFile(responseId, fileData, fileName, config) {
        return http
            .post(
                `/api/digital-audit-responses/${responseId}/upload-photo`,
                {
                    certificate_file: fileData,
                    certificate_file_name: fileName,
                },
                config
            )
            .then((res) => res.data);
    }

    confirmAuditResponse(responseId) {
        return http.put(`/api/digital-audit-results/${responseId}/confirmed`).then((res) => res.data);
    }

    unconfirmAuditResponse(responseId) {
        return http.put(`/api/digital-audit-results/${responseId}/unconfirm`).then((res) => res.data);
    }

    deleteAudit(auditId) {
        return http.delete(`/api/digital-audits/${auditId}`).then((res) => res.data);
    }

    updateAuditResult(auditId, data) {
        return http.put(`/api/digital-audit-results/${auditId}`, data).then((res) => res.data);
    }

    addAuditResult(placeId, data) {
        return http.post(`/api/digital-audit-results/place/${placeId}`, data).then((res) => res.data);
    }

    addAnswers(answers) {
        return http.post('/api/digital-audit-responses', answers).then((res) => res.data);
    }

    updateAnswers(answers) {
        return http.put('/api/digital-audit-responses', answers).then((res) => res.data);
    }

    getAuditResult(auditResultId) {
        return http.get(`/api/digital-audit-results/${auditResultId}`).then((res) => res.data);
    }

    getAuditTemplate(auditTemplateId) {
        return http.get(`/api/digital-audits/${auditTemplateId}`).then((res) => res.data);
    }

    updateAuditTemplate(auditTemplateId, data) {
        return http.put(`/api/digital-audits/${auditTemplateId}`, data).then((res) => res.data);
    }

    addAuditTemplate(companyId, data) {
        return http.post(`/api/company/${companyId}/digital-audits`, data).then((res) => res.data);
    }

    deleteQuestion(questionId) {
        return http.delete(`/api/digital-audit-questions/${questionId}`).then((res) => res.data);
    }

    updateQuestions(data) {
        return http.put('/api/digital-audit-questions', data).then((res) => res.data);
    }

    addQuestions(auditId, data) {
        return http.post(`/api/digital-audit-question/${auditId}`, data).then((res) => res.data);
    }

    getAuditResults(placeId) {
        return http.get(`/api/digital-audit-results/place/${placeId}`).then((res) => res.data);
    }

    getAuditTemplates(companyId) {
        return http.get(`/api/company/${companyId}/digital-audits`).then((res) => res.data);
    }

    deleteAuditResult(auditId) {
        return http.delete(`/api/digital-audit-results/${auditId}`);
    }
}

export default new AuditService();
