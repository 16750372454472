<template>
    <div
        class="d-flex"
        :class="{ 'justify-content-center': centered }"
    >
        <div
            class="d-flex justify-content-center align-items-center rounded-circle bg-danger-light p-1 hw-24px align-self-center"
        >
            <i class="fd-close text-danger"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        centered: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.hw-24px {
    width: 24px;
    height: 24px;
}
</style>
