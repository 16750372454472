import { vm } from '@main';
import { formatBytes } from '@services/helper.service.js';
import { configure, extend } from 'vee-validate';
import { confirmed, email, max, max_value, min, min_value, regex, required, size } from 'vee-validate/dist/rules';

export const repeatPasswordValidator = {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: () => vm.$t('password_doesnt_match'),
};

export default {
    init: () => {
        configure({
            // this will be used to generate messages.
            defaultMessage: () => {
                return vm.$t('Invalid field');
            },
        });

        extend('size', {
            ...size,
            message: (name, params) => `${vm.$t('max_file_size')} ${formatBytes(params.size)}`,
        });
        extend('required', { ...required, message: () => vm.$t('The field is required') });
        extend('email', {
            ...email,
            message: () => vm.$t('not_valid_email'),
        });
        extend('min', {
            ...min,
            message: (name, params) =>
                `${vm.$t("Field's minimum length is")} ${formatBytes(params.length).split(' ')[0]} ${vm.$t(
                    'characters'
                )}`,
        });
        extend('max', {
            ...max,
            message: (name, params) =>
                `${vm.$t("Field's maximum length is")} ${formatBytes(params.length).split(' ')[0]} ${vm.$t(
                    'characters'
                )}`,
        });
        extend('minValue', min_value);
        extend('maxValue', max_value);
        extend('regex', regex);
        extend('confirmed', confirmed);
        extend('password', repeatPasswordValidator);
    },
};

export const vatCodeRegex =
    /^(ATU[0-9]{8}|UA[0-9]{10,12}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[0-9]{8}[A-Z]|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/;

export const euCountries = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DK',
    'DE',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HU',
    'HR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PO',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
];
