import axios from 'axios';
import http from '@http';

class CompanyService {
    constructor() {
        if (CompanyService.instance) {
            return CompanyService.instance;
        }

        this.permissions = null;

        CompanyService.instance = this;
    }

    async _getCurrentPermissions() {
        if (this.permissions) {
            return this.permissions;
        }

        return axios.get('/api/permissions/current').then((res) => {
            this.permissions = res.data;

            return this.permissions;
        });
    }

    async getCompanies(hard = false) {
        if (hard) {
            return axios.get('/api/permissions/current').then((res) => {
                this.permissions = res.data;
                return this.permissions;
            });
        } else {
            return this._getCurrentPermissions().then(
                (res) => {
                    return res;
                },
                (err) => {
                    return err;
                }
            );
        }
    }

    async getCompany(id) {
        return this.getCompanies().then((res) => {
            return res.find((company) => company.company_id === id);
        });
    }

    async getPlaces() {
        return this._getCurrentPermissions().then((res) => {
            return res.place;
        });
    }

    async getPlace(id) {
        return this.getPlaces().then((res) => {
            return res.find((place) => place.id === id);
        });
    }

    getFullPlaceData(placeId) {
        return http.get(`/api/place/${placeId}`).then((res) => {
            if (res.status === 200) {
                return res.data;
            }

            return Promise.reject();
        });
    }

    delete(companyId) {
        return http.delete(`/api/company/${companyId}`);
    }

    postCompany(data) {
        return http.post('/api/company', data).then((res) => res.data);
    }

    updateCompany(companyId, data) {
        return http.put(`/api/company/${companyId}`, data);
    }

    clearCache() {
        this.permissions = null;
    }
}

export default new CompanyService();
